import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line, Pie } from "react-chartjs-2";
import { ArcElement } from "chart.js";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler // 1. Import Filler plugin
} from "chart.js";
import axios from 'axios';
import moment from 'moment';
import Grow from '@mui/material/Grow';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';
import { useAppContext } from '../../context/AppContext';
import Table from '../../components/common/Table/Table';
import AnimatedNumbers from "react-animated-numbers"
import '../../assets/styles/usage_tracker.css';
import CsvIcon from '../../assets/img/csv.png';
import {
	GridRowModes,
	DataGrid,
	GridToolbarContainer,
	GridActionsCellItem,
	GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { TextField, Toolbar, InputAdornment } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import DynamicModal from "./DynamicModal"
import { Modal, Box } from "@mui/material";
import { DateRange, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as XLSX from "xlsx";
import { DescriptionOutlined } from '@mui/icons-material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const Dashboard = ({ userCount, connectedUsers }) => {

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend,
		Filler // 1. Register Filler plugin
	);

	ChartJS.register(ArcElement);

	const [mode, setMode] = useState('weekly')

	const [trafficDates, setTrafficDates] = useState([]);
	const [trafficData, setTrafficData] = useState([]);
	const [lastLogin, setLastLogin] = useState(null);
	const [lastLoginMoment, setLastLoginMoment] = useState('');
	const [newUsers, setNewUsers] = useState(0);
	const [newUserData, setNewUserData] = useState(0);

	const [filteredDashboardUserData, setFilteredDashboardUserData] = useState([])
	const [searchText, setSearchText] = useState("");

	const [dashboardUserData, setDashboardUserData] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalData, setModalData] = useState([]);

	const [modalType, setModalType] = useState(null);

	const [userDesignation, setUserDesignation] = useState("all")
	const [allUserDesignation, setAllUserDesignation] = useState("")
	const [allClients, setAllClients] = useState("")

	const [client, setClient] = useState("")

	const [userType, setUserType] = useState("1")

	const [customDate, setCustomDate] = useState('')
	const [customDateRange, setCustomDateRange] = useState({
		startDate: "",
		endDate: "",
		key: 'selection',
	});
	const [userTrafficData, setUserTrafficData] = useState([])

	const [showDateRangePicker, setShowDateRangePicker] = useState(false);

	const [internalActive, setInternalActive] = useState(true)

	const [overAllSessionUsage, setOverallSessionUsage] = useState([])
	const [isLoader, setIsLoader] = useState(false)
	const [startDate,setStartDate] = useState("")

	const pickerRef = useRef(null);

	const navigate = useNavigate();

	const options = {
		responsive: true,
		tension: 0.3 // Set the tension (curvature) of the line
	};

	useEffect(() => {

		const { startDate, endDate } = customDateRange;

		if (startDate && endDate) {
			const timeDiff = endDate - startDate;
			const diffInDays = timeDiff / (1000 * 3600 * 24);

			if (diffInDays > 44) {
				alert("The selected date range cannot be greater than 45 days.");
				return;
			}
			const formattedStart = startDate.toLocaleDateString('en-CA');
			const formattedEnd = endDate.toLocaleDateString('en-CA');
			setDateRange(`${formattedStart}|${formattedEnd}`);
		}
	}, [customDateRange]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (pickerRef.current && !pickerRef.current.contains(event.target)) {
				setShowDateRangePicker(false);

			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);


	const SelectedRangeDiv = () => {
		let modifiedDateRange = selectedDateRange.split("|").join(" to ");
		const [startDate, endDate] = selectedDateRange.split("|");
		if (startDate === endDate) {
			modifiedDateRange = startDate;
		}
		return (
			<>
				<h4 style={{ marginBottom: 0, color: 'rgb(170 170 170)', fontSize: '1em' }}>({modifiedDateRange})</h4>
			</>
		)
	}


	const downloadUserTrafficCsv = () => {
		const csvContent = userTrafficData.map(row => row.join(",")).join("\n");
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);
		link.href = url;
		const clientData = allClients.find(item => item[0] == client);
		const clientName = clientData ? clientData[1] : null;

		const designation = allUserDesignation.find(item => item[0] == userDesignation);
		const designationName = designation ? designation[1] : null;
		link.setAttribute(
			"download",
			`${dateRange} ${userType == 1
				? `Internal_${designationName ?? "All"}`
				: `External_${clientName}`
			}_User Traffic`
		);


		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};



	const calculateDateRange = (value) => {
		const today = new Date();
		let startDate, endDate;
		switch (value) {
			case 'weekly':
				startDate = new Date(today);
				startDate.setDate(today.getDate() - 6);
				break;
			case 'dweekly':
				startDate = new Date(today);
				startDate.setDate(today.getDate() - 14);
				break;
			case 'monthly':
				startDate = new Date(today);
				startDate.setDate(today.getDate() - 29);
				break;
			case 'quarterly':
				startDate = new Date(today);
				startDate.setDate(today.getDate() - 44);
				break;
			default:
				startDate = null;
		}
		endDate = today;
		if (startDate) {
			const formattedStart = startDate.toLocaleDateString('en-CA');
			const formattedEnd = endDate.toLocaleDateString('en-CA');
			return `${formattedStart}|${formattedEnd}`;

		}
		return '';
	};

	const [dateRange, setDateRange] = useState(calculateDateRange("weekly"))
	const [selectedDateRange, setSelectedDateRange] = useState(calculateDateRange("weekly"))


	const handleModeChange = (evt) => {
		const selectedMode = evt.target.value;
		setMode(selectedMode);

		if (['weekly', 'dweekly', 'monthly', 'quarterly'].includes(selectedMode)) {
			const range = calculateDateRange(selectedMode);
			setDateRange(range);
		} else {
			setDateRange('');
		}
	};

	const handleDateRangeChange = (ranges) => {
		const { startDate, endDate } = ranges.selection;
		setCustomDateRange({ ...customDateRange, startDate, endDate });
	};


	const handleApplyDateRange = () => {
		const start = customDateRange.startDate;
		const end = customDateRange.endDate;
		const timeDiff = end - start;
		const diffInDays = timeDiff / (1000 * 3600 * 24);
		if (diffInDays > 44) {
			alert("The selected date range cannot be greater than 45 days.");
			return;
		}


		const formattedStart = start.toISOString().split('T')[0];
		const formattedEnd = end.toISOString().split('T')[0];
		setDateRange(`${formattedStart}|${formattedEnd}`);
		setShowDateRangePicker(false);
	};


	const convertDataToExcelFormat = (data) => {
		const result = data.map((userData) => {
			const row = {
				"Date Range": dateRange,
				"Days Logged In": userData.unique_login_date.map((ele) => `${ele.date} : ${ele.count}`).join("\n"),
				"Client Name": userData.clientname,
				"Full Name": userData.username,
				"Email": userData.email,
				"Count of Logins": userData.login_count,
				"Overall Time Spent (HH:MM)": userData.averageTimeSpent,
				"Page Level Time Spent(HH:MM)": userData.dashboards
					.map((dashboard) => `${dashboard.dashboard_name}: ${dashboard.avg_time_spent}`)
					.join("\n"),
				"Total Dashboard Access": userData.dashboardAccess,
				"Dashboard Accessed": userData.dashboards
					.map((dashboard) => `${dashboard.dashboard_name}: ${dashboard.dashboard_accessed}`)
					.join("\n"),
				"Status": userData.status,
				"Unique Login Dates": userData.unique_login_date.map((login_date) => `${login_date.date}`).join(",")
			};



			return row;
		});

		return result;
	};

	const exportToExcel = () => {
		const formattedData = convertDataToExcelFormat(dashboardUserData);
		const ws = XLSX.utils.json_to_sheet(formattedData);
		const wscols = [
			{ wpx: 100 },
			{ wpx: 100 },
			{ wpx: 100 },
			{ wpx: 100 },
			{ wpx: 100 },
			{ wpx: 100 },
			{ wpx: 100 },
			{ wpx: 100 }
		];

		const cellStyle = {
			alignment: {
				wrapText: true,
				vertical: "top",
				horizontal: "left"
			}
		};

		const range = XLSX.utils.decode_range(ws["!ref"]);
		for (let row = range.s.r; row <= range.e.r; row++) {
			const cellAddress = { r: row, c: 7 };
			const cell = ws[XLSX.utils.encode_cell(cellAddress)];
			if (cell) {
				cell.s = cellStyle;
			}
		}


		ws["!cols"] = wscols;

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "User Data");
		const clientData = allClients.find(item => item[0] === client);
		const clientName = clientData ? clientData[1] : null;

		const designation = allUserDesignation.find(item => item[0] == userDesignation);
		const designationName = designation ? designation[1] : null;

		let fileName = `${dateRange} ${userType == 1
			? `Internal_${designationName ?? "All"}`
			: `External_${clientName}`
			}_Dashboard_Usage`

		XLSX.writeFile(wb, `${fileName}.xlsx`);
	};

	const handlefilteredDashboardUserData = (username, fullName, created_at, user_data) => {
		let modifiedDateRange = selectedDateRange.split("|").join(" to ");
		const [startDate, endDate] = selectedDateRange.split("|");
		if (startDate === endDate) {
			modifiedDateRange = startDate;
		}
		user_data.forEach((element, index) => {
			element['full_name'] = fullName;
			element['email'] = username;
			element['created_at'] = created_at;
			element['dateRange'] = modifiedDateRange;
		});

		setModalType("dashboard")
		setModalData(user_data);
		setModalOpen(true);

	};

	const handleSearch = (event) => {
		const searchValue = event.target.value.toLowerCase();
		// console.log(searchValue)
		setSearchText((preVal) => searchValue);

		const filtered = dashboardUserData.filter((row) =>
			Object.values(row).some((value) =>
				String(value).toLowerCase().includes(searchValue)
			)
		);

		setFilteredDashboardUserData(filtered);
	};

	const getuserDesignation = async () => {
		try {
			const response = await axios.get(`/get_user_designation`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status === 200 && response.data !== undefined) {
				let data = response.data;
				setAllUserDesignation(data);
				setUserDesignation("all");
				return true; // Indicate success
			}
		} catch (err) {
			console.error("Error", err);
			if (err.response?.status === 401) {
				navigate('/login');
			}
			return false; // Indicate failure
		}
	};

	const getClients = async () => {
		try {
			const response = await axios.get(`/fetch_all_clients`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status === 200 && response.data !== undefined) {
				let data = response.data;
				setAllClients(data);
				setClient(data[0][0]);
				return true; // Indicate success
			}
		} catch (err) {
			console.error("Error", err);
			if (err.response?.status === 401) {
				navigate('/login');
			}
			return false; // Indicate failure
		}
	};

	const handleApiCalls = async () => {
		const [userDesignationSuccess, clientsSuccess] = await Promise.all([
			getuserDesignation(),
			getClients(),
		]);

		if (userDesignationSuccess && clientsSuccess) {
			getData();
		} else {
			console.error("One or both API calls failed.");
		}
	};


	const getData = async () => {
		try {
			setIsLoader(false)
			if (!dateRange.includes("|")) {
				const currentDate = new Date();
				const inputDate = new Date(dateRange);
				const startDateAcess = new Date(startDate)
				if (inputDate > currentDate) {
					alert("The date cannot be greater than the current date.");
					return;
				}
				if (inputDate < startDateAcess) {
					alert("The date cannot be less than the dashboard access start date.");
					return;
				}
			}

			const response = await axios.post(
				`/get_usage_data`,
				{
					mode: mode,
					user_type: userType,
					date_range: dateRange,
					user_designation_id: userDesignation,
					tenant_id: client,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
					},
				}
			);
			if (response.status == 200 && response.data !== undefined) {
				let data = await response.data
				setTrafficDates(Object.keys(data.user_traffic));
				setTrafficData(Object.keys(data.user_traffic).map(key => parseInt(data.user_traffic[key])))
				setUserTrafficData(data.user_traffic_data)
				setLastLogin(data.last_login)
				setStartDate(data.start_access_date || new Date())
				//   setLastLoginMoment(moment(data.last_login.date + " " + data.last_login.time, "DD-MM-YYYY HH:mm").startOf('hour').fromNow())
				setNewUsers(data.new_users)

				setOverallSessionUsage(data.avg_session)
				let finalUserData = data.user_data
					.sort((a, b) => {

						if (a.status === "Active" && b.status !== "Active") {
							return -1;
						}
						if (a.status !== "Active" && b.status === "Active") {
							return 1;
						}
						const aLastLogin = new Date(a.last_login);
						const bLastLogin = new Date(b.last_login);
						return bLastLogin - aLastLogin;
					});

				setDashboardUserData(finalUserData)
				setFilteredDashboardUserData(finalUserData)
				setNewUserData(data.new_signup)
				setSelectedDateRange(dateRange)
				setIsLoader(true)
			}
		} catch (err) {
			console.error("Error", err);
			if (err.response.status === 401) {
				navigate('/login')
			}
		}
	}

	useEffect(() => {
		handleApiCalls()
	}, [])




	const cols = [
		{ field: "username", headerName: "Username", headerClassName: "bg-grey-3", flex: 1 },
		{ field: "clientname", headerName: "Client Name", headerClassName: "bg-grey-3", flex: 1 },
		{ field: "email", headerName: "Email", headerClassName: "bg-grey-3", flex: 1.5 },
		{
			field: "status", headerName: "Status", headerClassName: "bg-grey-3", flex: 0.8,
			renderCell: (params) => {
				return (
					<>
						{params.row.status === "Active" ? (
							<div
								className="p-2 w-50 text-center"
								style={{
									backgroundColor: '#55E496',
									color: "white",
									borderRadius: 15
								}}
							>
								{params.row.status}
							</div>
						) : (
							<div
								className="p-2 w-50 text-center"
								style={{
									backgroundColor: '#E45555',
									color: "white",
									borderRadius: 15
								}}
							>
								{params.row.status}
							</div>
						)}


					</>
				)
			}

		},
		{ field: "averageTimeSpent", headerName: "Average Time Spent (HH:MM)", headerClassName: "bg-grey-3", flex: 1 },
		{ field: "lastLogin", headerName: "Last Login", headerClassName: "bg-grey-3", flex: 1 },
		{
			field: "dashboardAccess",
			headerName: "Dashboard Usage",
			headerClassName: "bg-grey-3",
			flex: 0.8,
			renderCell: (params) => {
				return (
					<div
						onClick={() => {
							handlefilteredDashboardUserData(
								params.row.email,
								params.row.username,
								params.row.created_at,
								params.row.dashboards
							);
						}}
						className="dashboard-access-button"
						title={`Dashboard Access for ${params.row.username}`}
					>
						{params.row.dashboardAccess}
					</div>

				)
			},
		}
	];





	const handleCloseModal = () => {
		setModalOpen(false);
		setModalType(null);
		setModalData([]);
	};

	const handleNewSignUp = (type) => {
		setModalData(newUserData)
		setModalType(type)
		setModalOpen(true)
	}

	const handleUserType = (event) => {

		setUserType(event.target.value)
		if (event.target.value == 0) {
			setInternalActive(false)
		}
		else {
			setInternalActive(true)
		}
	}
	const handleRole = (event) => {
		setUserDesignation(event.target.value)
	}
	const handleClient = (event) => {
		setClient(event.target.value)
	}

	return (
		<>
			{modalData && modalData.length != 0 && (
				<DynamicModal
					open={modalOpen}
					setModalOpen={setModalOpen}
					onClose={handleCloseModal}
					type={modalType}
					data={modalData}
					dateRange={dateRange}
				/>
			)}

			<Grow
				in={true}
				{...(true ? { timeout: 700 } : {})}
			>

				<div className='px-4 py-2 mt-3 white-card'>
					<div className="mt-3">
						<div className='bg-grey p-1 py-2 corner w-100 row align-items-center'>
							<div className='col-3'>
								<h3 class='dashboard-card-title'>Usage Tracker</h3>
								<p className='grey-2 small mt-1'>Monitor and analyze usage of the application across all dashboards and users.</p>
							</div>
							<div className='col-2 d-flex  flex-column justify-content-between '>
								<div >
									<label>Date</label>
								</div>
								<div className='d-flex '>
									{/* <i class="fa-regular fa-calendar mx-2"></i> */}
									<FormControl fullWidth>
  <Select
    value={mode}
    onChange={handleModeChange}
    size="small"
    style={{ backgroundColor: "#fff", width: '9em' }}
  >
    <MenuItem value="weekly">Last 7 days</MenuItem>
    <MenuItem value="dweekly">Last 15 days</MenuItem>
    <MenuItem value="monthly">Last 30 days</MenuItem>
    <MenuItem value="quarterly">Last 45 days</MenuItem>
    <MenuItem value="custom_date">Custom Date</MenuItem>
    <MenuItem value="custom_date_range">Custom Date Range</MenuItem>
  </Select>
</FormControl>

								</div>
							</div>
							{(mode == "custom_date" || mode == "custom_date_range")

								&&

								(<>
									<div className='col-2'>
										{mode === 'custom_date' && (
											<div>
												<label>Custom Date:</label>
												<input
													type="date"
													value={dateRange}
													onChange={(e) => setDateRange(e.target.value)}
													className="form-control"
													min={startDate}
													max={new Date().toISOString().split('T')[0]}
												/>
											</div>
										)}

										{mode === 'custom_date_range' && (
											<div>
												<label>
													Custom Date Range

													<div

														style={{
															padding: "8px",
															borderRadius: '10px',
															backgroundColor: "white",
															border: "1px solid lightgrey",
															cursor: "pointer",
														}}
														onClick={() => setShowDateRangePicker(!showDateRangePicker)}
													>
														{dateRange
															? dateRange.replace('|', ' to ')
															: "YYYY-MM-DD to YYYY-MM-DD"}
													</div>
												</label>
												{showDateRangePicker && (
													<>
														<div
															style={{
																position: "absolute",
																left: "50%",
																top: "20%",
																transform: "translate(-50%, -20%)",
																background: "#fff",
																padding: "20px",
																borderRadius: "8px",
																border: "1px solid #ccc",
																boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
																zIndex: 10,
																width: "auto",
															}}
														>
															{/* Close Button */}
															<button
																style={{
																	position: "absolute",
																	top: "-9px",
																	right: "-10px",
																	// background: "red",
																	border: "none",
																	fontSize: "1.5em",
																	color: "white",
																	cursor: "pointer",
																	padding: "0.5em",
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	height: '20px',
																	width: "20px",
																	borderRadius: '50%'
																}}
																onClick={() => setShowDateRangePicker(false)}
															>
																&times;
															</button>


															{/* DateRangePicker Component */}
															<div className="d-flex flex-column justify-content-center align-items-center" ref={pickerRef}>
																<DateRangePicker
																	ranges={[customDateRange]}
																	onChange={handleDateRangeChange}
																	// showSelectionPreview={false}
																	// moveRangeOnFirstSelection={false}
																	months={2}
																	direction="horizontal"
																	minDate={new Date(startDate)}
																	maxDate={new Date()}
																	staticRanges={[]}
																	inputRanges={[]}
																	style={{
																		fontSize: '12px',
																		width: '250px',
																		height: 'auto',
																	}}
																// disabledDates={[{ startDate: new Date(), endDate: new Date() }]}
																/>
															</div>
														</div>
													</>
												)}
											</div>
										)}

									</div>

								</>)}



							<div className='col-2 d-flex flex-column justify-content-between'>
								<label>User Type</label>
								<FormControl fullWidth>
									<Select
										// label="User Type"
										onChange={handleUserType}
										size="small"
										style={{ backgroundColor: "#fff", width: '9em' }}
										value={userType}
									>
										<MenuItem value="1">Internal</MenuItem>
										<MenuItem value="0">External</MenuItem>
									</Select>
								</FormControl>
							</div>
							{internalActive ? (<>
								<div className='col-2 d-flex flex-column justify-content-between '>
									<label>Designation</label>
									<FormControl fullWidth>
										<Select
											value={userDesignation}
											onChange={handleRole}
											size="small"
											style={{ backgroundColor: "#fff", width: '9em' }}
										>
											<MenuItem value="all">All</MenuItem>
											{allUserDesignation &&
												allUserDesignation.map((ele, index) => (
													<MenuItem key={index} value={ele[0]}>
														{ele[1]}
													</MenuItem>
												))}
										</Select>
									</FormControl>


								</div>
							</>) : (<>
								<div className='col-2 d-flex flex-column justify-content-between '>
									<label>Client</label>
									<FormControl fullWidth>
										<Select
											value={client}
											onChange={handleClient}
											size="small"
											style={{ backgroundColor: "#fff", width: '9em' }}
										>
											<MenuItem value="all">All</MenuItem>
											{allClients &&
												allClients.map((ele, index) => (
													<MenuItem key={index} value={ele[0]}>
														{ele[1]}
													</MenuItem>
												))}
										</Select>
									</FormControl>

								</div>

							</>)}

							<div class="col-2 d-flex flex-column justify-content-end mt-3" style={{ height: "100%" }}>
								<button onClick={getData} class="u-submit-btn">Submit</button>
							</div>

							{/* 
							<div className='col-2'>
								<button className='u-submit-btn' onClick={getData}>Submit</button>
							</div> */}
						</div>
					</div>

					<div className='mt-4 w-100 row'>
						<div className='col-7'>
							<div className='outline-card p-3 row'>
								{/* <h6 className='bold'>{mode === 'weekly' ? 'Weekly' : 'Monthly'} User Traffic</h6> */}
								<div className='d-flex justify-content-between'>
									<h6 className='bold d-flex text-orange'>User Traffic  <span><SelectedRangeDiv></SelectedRangeDiv></span></h6>
									<div
										onClick={downloadUserTrafficCsv}
										className="csv-download-button d-flex justify-content-center align-items-center"
									>
										{/* <img src={CsvIcon} alt="CSV Icon" className="csv-icon" /> */}
										<SaveAltIcon className="csv-icon" sx={{ color: 'white' }} />
									</div>

								</div>
								<div className='col-12'>
									{
										trafficData.length && trafficDates.length ?
											<Line className='line-chart-usage' options={options}
												style={{ width: '100%' }}
												data={{
													'labels': trafficDates,
													'datasets': [
														{
															label: "Number of logins",
															data: trafficData,
															borderColor: "#FF8B00",
															backgroundColor: "rgb(255, 139, 0)",
															fill: {
																target: "origin",
																above: "rgba(255, 139, 0, 0.3)"
															}
														}
													]
												}} />
											:
											null
									}
								</div>
							</div>
						</div>
						<div className='col-5'>


							<div className='col-12 row'>
								<div className='col-12'>
									<div className='outline-card p-3 w-100'>
										<h6 className='bold'><i class="fa-solid fa-user-plus mr-2"></i> New Sign Ups</h6>
										<SelectedRangeDiv></SelectedRangeDiv>
										<div
											onClick={() => {
												if (newUsers > 0) handleNewSignUp("userDetails");
											}}
											className={`text-orange bold mt-3 ${newUsers > 0 ? 'clickable' : ''}`}
											style={{
												cursor: newUsers > 0 ? "pointer" : "default", fontSize: '2em', width: "0.8em"
											}}
										>
											{newUsers}
										</div>


									</div>
								</div>
							</div>
							<div className='col-12 p-2 session-container'>
								<div className='p-2 text-orange fw-semibold'>Average time (HH:MM)</div>
								<SelectedRangeDiv></SelectedRangeDiv>
								<div className='col-12' style={{ maxHeight: '15em', overflowY: 'auto' }}>
									{overAllSessionUsage && overAllSessionUsage.length != 0 ? (
										overAllSessionUsage.map((element, index) => {
											return (
												<div key={index} className='d-flex justify-content-between p-2 fw-semibold'>
													<div>{element[0]}</div>
													<div>{element[1]}</div>
												</div>
											);
										})
									) : (<>
										<div className='w-100 d-flex justify-content-center align-item-center p-2'>
											No Dashboard Usage Data Found
										</div>
									</>)}
								</div>

							</div>
						</div>
					</div>
					{isLoader
						&&
						(<>


							{filteredDashboardUserData.length > 0 ? (
								<div className='col-12'>
									<div className="mt-1 outline-card p-3">
										<div className='d-flex justify-content-between'>
											<div className='d-flex justify-content-center align-items-center'>
												<div className='text-orange fw-semibold'>Dashboard Usage</div>
												<SelectedRangeDiv></SelectedRangeDiv>
											</div>
											<div className='d-flex justify-content-center align-items-center'>
												<div className="csv-download-button d-flex justify-content-center align-items-center" onClick={exportToExcel} >
													{/* <img src={CsvIcon} alt="CSV Icon" className="csv-icon" /> */}
													<SaveAltIcon className="csv-icon" sx={{ color: 'white' }} />
												</div>
												<TextField
													className='my-1 mx-3'
													size='small'
													variant="outlined"
													placeholder="Search..."
													value={searchText}
													onChange={handleSearch}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																{searchText && (
																	<IconButton
																		aria-label="clear search"
																		onClick={() => {
																			handleSearch({ target: { value: '' } });
																		}}
																		edge="end"
																	>
																		<CloseIcon />
																	</IconButton>
																)}
															</InputAdornment>
														),
													}}
												/>
											</div>
										</div>
										<div style={{ width: "100%" }}>

											{filteredDashboardUserData.length > 0 && (
												<DataGrid
													autoHeight={true}
													rows={filteredDashboardUserData?.map((item, index) => ({
														id: index,
														username: item.username,
														clientname: item.clientname,
														email: item.email,
														status: item.status,
														averageTimeSpent: item.averageTimeSpent,
														lastLogin: item.lastLogin == null ? "Not found" : item.lastLogin,
														dashboardAccess: item.dashboardAccess,
														dashboards: item.dashboards,
														created_at: item.created_at
													}))}
													pageSizeOptions={[5, 10, 50, 100]}
													initialState={{
														pagination: {
															paginationModel: { page: 0, pageSize: 5 },
														},
													}}
													columns={cols}
													editMode="row"
													onProcessRowUpdateError={(error) => console.log(error)}
													sx={{ borderCollapse: "collapse", border: "none" }}
												/>

											)}
										</div>
									</div>

								</div>
							) : (
								<>
									<div
										className="d-flex justify-content-center align-items-center"
										style={{
											padding: "20px",
											backgroundColor: "#f8d7da",
											color: "#721c24",
											border: "1px solid #f5c6cb",
											borderRadius: "5px",
											fontSize: "1.2em",
											fontWeight: "bold",
										}}
									>
										No Dashboard Usage Data Found for the Date :{" "}
										{selectedDateRange.includes("|")
											? selectedDateRange.replace("|", " to ")
											: selectedDateRange}
									</div>

								</>

							)}
						</>)

					}

				</div>
			</Grow>
		</>

	)
};

export default Dashboard;