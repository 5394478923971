import * as React from 'react';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import LinearStepper from '../../components/common/Stepper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grow from '@mui/material/Grow';
import axios from 'axios';

import BasicDetails from './BasicDetails';
import ClientRegions from './ClientRegions';
import ClientRegionsV2 from './ClientRegionsV2';
// import ClientProperties from './ClientProperties';
import ClientPropertiesV2 from './ClientPropertiesV2';
import FocusCompetition from './FocusCompetition';
// import Summary from './Summary';
import SummaryV2 from './SummaryV2';

import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';
import hotel from '../../assets/img/hotel.png';

const NewClient = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [showComplete, setShowComplete] = useState(false);

  const steps = ['Basic details', 'Set regions', 'Configure Properties & Rooms', 'Summary'];


  //Client fields
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [international, setInternational] = useState('0');
  const [headOffice, setHeadOffice] = useState(null);
  const [properties, setProperties] = useState({});
  const [country, setCountry] = useState({});
  const [focusComp, setFocusComp] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [otherCity, setOtherCity] = useState('');
  const [completed, setCompleted] = useState(-1);

  const [hotelName, setHotelName] = useState("");
  const [preferredOTA, setPreferredOTA] = useState(null)
  const [headOfficeCountry, setHeadOfficeCountry] = useState(null);
  const [clientType, setClientType] = useState(null)
  const [integratedPMS, setIntegratedPMS] = useState([])
  const [integratedCMS, setIntegratedCMS] = useState([])

  const [pmsOptions, setPmsOptions] = useState([])
  const [cmsOptions, setCmsOptions] = useState([])
  const [propertyTypes, setPropertyTypes] = useState([])
  const [serviceTypes, setServiceTypes] = useState([])
  const [mealPlans, setMealPlans] = useState([])
  const [roomTypes, setRoomTypes] = useState([])
  const [amenities, setAmenities] = useState([])
  const [bedTypes, setBedTypes] = useState([])



  const navigate = useNavigate();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const showToast = (message, type = 'error') => {
    toast(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      theme: "colored",
      type: toast.TYPE[type.toUpperCase()] || toast.TYPE.ERROR,
    });
  };

  const validateParityBins = (parity_bins) => {
    for (const bin in parity_bins) {
      if (!parity_bins.hasOwnProperty(bin)) continue;

      const values = parity_bins[bin];
      if (values.m == null || values.c == null) {
        showToast(`Parity bin "${bin}" has invalid values! "m" and "c" must not be null or undefined.`);
        return false;
      }
    }
    return true;
  };

  const handleNext = (index) => {
    if (activeStep === index) {
      return
    }
    if (activeStep === 0) {
      if (!name || name.length < 2) {
        showToast('Please provide a valid client name with more than 2 characters!');
        return;
      }
      if (/^[0-9]+$/.test(name)) {
        showToast('Client name cannot contain only numerical characters!');
        return;
      }
      if (/^[^a-zA-Z0-9]+$/.test(name)) {
        showToast('Client name cannot contain only special characters!');
        return;
      }
      if (!hotelName || hotelName.length < 2) {
        showToast('Please provide a valid hotel name with more than 2 characters!');
        return;
      }
      if (domain && !/^(?!.*(?:http|www))[a-zA-Z0-9]+(?:\.[a-zA-Z]{2,})+$/.test(domain)) {
        showToast('Invalid website URL entered!');
        return;
      }
      if (!preferredOTA?.value) {
        showToast("Please select the client's preferred OTA!");
        return;
      }
      // client type remaining
      if (!clientType?.value) {
        showToast("Please select the client's type!");
        return;
      }
      if (!headOfficeCountry?.value) {
        showToast("Please select the client's head office country!");
        return;
      }

      if (!headOffice?.value) {
        showToast("Please select the client's head office city!");
        return;
      }

    }

    if (activeStep === 1) {
      if (!Object.keys(properties).length) {
        showToast("Please configure the client's regions!");
        return;
      }
    }

    if (activeStep === 2) {
      for (let city in properties) {
        if (!properties[city]['properties'].length) {
          showToast(`Please configure properties for ${city} region!`);
          return;
        }
        for (let property of properties[city]['properties']) {

          if (!property?.property_type) {
            showToast(`Please enter property type for ${property.hotel_name} in ${city}!`);
            return;
          }

          if (!property?.target_customers) {
            showToast(`Please enter target customers for ${property.hotel_name} in ${city}!`);
            return;
          }

          if (!property?.established_month) {
            showToast(`Please enter established month for ${property.hotel_name} in ${city}!`);
            return;
          }

          if (!property?.established_year) {
            showToast(`Please enter established year for ${property.hotel_name} in ${city}!`);
            return;
          }

          if (!property?.observation_range || property.observation_range < 30 || property.observation_range > 367) {
            showToast(`Please enter a valid observation range for ${property.hotel_name} in ${city}!`)
            return
          }

          if (!property?.service_types?.length) {
            showToast(`Please select at least one service for ${property.hotel_name} in ${city}`);
            return;
          }

          if (property.service_types.some(service => !service.start_date || !service.end_date || new Date(service.start_date) > new Date(service.end_date))) {
            showToast(`Please ensure valid start and end dates are selected for all services in ${property.hotel_name}`);
            return;
          }

          const selectedMealPlans = property?.meal_plan || {};
          for (let plan of mealPlans) {
            const selectedPlanValue = selectedMealPlans[plan.label];
            if (selectedPlanValue !== undefined && selectedPlanValue <= 1) {
              showToast(`${plan.meal_plan_name} value must be greater than 1 for ${property.hotel_name} in ${city}!`);
              return;
            }
          }

          if (!property?.room_types?.length) {
            showToast(`Please configure at least 1 room type for ${property.hotel_name} in ${city}!`);
            return;
          }

          if (!property?.amenities?.length) {
            showToast(`Please select some amenities for ${property.hotel_name} in ${city}!`);
            return;
          }

          if (!property?.comp?.length || property.comp.length < 3) {
            showToast(`Please configure at least 3 competitions for ${property.hotel_name} in ${city}!`);
            return;
          }

          for (const competitor of property.comp) {
            if (!competitor.roomType) {
              showToast(`Please enter room type for competitions in ${property.hotel_name} in ${city}!`)
              return
            }

            if (!competitor.mealPlan) {
              showToast(`Please enter meal plan for competitions in ${property.hotel_name} in ${city}!`)
              return
            }

            if (!competitor.parity_bins || !validateParityBins(competitor.parity_bins)) {
              showToast(`Invalid parity bins configuration in competitions for ${property.hotel_name} in ${city}!`);
              return;
            }
          }
        }

      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(index);
    setSkipped(newSkipped);


    if (index == 0) {
      setCompleted(-1);
    } else {
      setCompleted(index - 1);
    }

  };

  const handleBack = (index) => {
    if (activeStep === index) {
      return
    }
    setActiveStep(index);
    if (index != 0) {
      setCompleted(index - 1)
    } else {
      setCompleted(-1)
    }

  };

  const createClient = async () => {
    const newClient = {
      "clientName": name,
      "clientDomain": domain,
      "international": parseInt(international) === 0 ? false : true,
      "headOffice": headOffice['value'] === 'Other' ? otherCity : headOffice['value'],
      "clientCities": properties,
      "integratedPMS": integratedPMS,
      "integratedCMS": integratedCMS,
      "headOfficeCountry": headOfficeCountry,
      "preferredOTA": preferredOTA,
    }

    try {
      const response = await axios.post("/create_new_client", { newClient }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        },
      });
      if (response.status === 200 && response.data !== undefined) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setShowComplete(true);
      }
    } catch (error) {
      console.error("Error", error);
      if (error.response !== undefined && error.response.status === 409) {
        toast.error(error.response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
        });
        return;
      }
      if (error.response !== undefined && error.response.status === 500) {
        toast.error(`Something went wrong!`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
        });
        return;
      }
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching pms details', error)
    }
  };

  const createClientV2 = async () => {
    const newClient = {
      "clientName": name,
      "clientDomain": domain,
      "international": parseInt(international) === 0 ? false : true,
      "headOffice": headOffice['value'] === 'Other' ? otherCity : headOffice['value'],
      "clientCities": properties,
      "integratedPMS": integratedPMS,
      "integratedCMS": integratedCMS,
      "headOfficeCountry": headOfficeCountry['value'],
      "preferredOTA": preferredOTA['value'],

    }
    console.log(newClient)
    try {
      const response = await axios.post("/create_new_client_v2", { newClient }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        },
      });
      if (response.status === 200 && response.data !== undefined) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setShowComplete(true);
        // console.log(response.data)
      }
    } catch (error) {
      console.error("Error", error);
      if (error.response !== undefined && error.response.status === 409) {
        toast.error(error.response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
        });
        return;
      }
      if (error.response !== undefined && error.response.status === 500) {
        toast.error(`Something went wrong!`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
        });
        return;
      }
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while onboarding client', error)
    }
  };


  const getPMSDetails = async () => {
    try {
      const response = await axios.get("/get_pms_details", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setPmsOptions(response.data)
      }
    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching pms details', error)
    }
  }

  const getCMSDetails = async () => {
    try {
      const response = await axios.get("/get_cms_details", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setCmsOptions(response.data)
      }
    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching cms details', error)
    }
  }

  const getPropertyTypes = async () => {
    try {
      const response = await axios.get("/get_property_type_details", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setPropertyTypes(response.data)
      }

    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching cms details', error)
    }
  }

  const getServiceTypes = async () => {
    try {
      const response = await axios.get("/get_service_types", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setServiceTypes(response.data)
      }

    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching service types', error)
    }
  }

  const getMealPlans = async () => {
    try {
      const response = await axios.get("/get_meal_plans", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setMealPlans(response.data)
      }

    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching meal plans', error)
    }
  }

  const getAmenities = async () => {
    try {
      const response = await axios.get("/get_amenities", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setAmenities(response.data)
      }
    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching amenities', error)
    }
  }

  const getRoomTypes = async () => {
    try {
      const response = await axios.get("/get_room_types", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setRoomTypes(response.data)
      }

    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching room types', error)
    }
  }

  const getBedTypes = async () => {
    try {
      const response = await axios.get("/get_bed_types", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.data) {
        setBedTypes(response.data)
      }

    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) {
        navigate("/login")
      }
      console.error('Error occurred while fetching room types', error)
    }
  }


  useEffect(() => {
    getPMSDetails()
    getCMSDetails()
    getPropertyTypes()
    getServiceTypes()
    getMealPlans()
    getAmenities()
    getRoomTypes()
    getBedTypes()
  }, [])


  return (
    <Grow
      in={true}
      {...(true ? { timeout: 700 } : {})}
    >
      <section className='p-4 m-4 white-card'>

        <div className="w-100 row mb-4 mt-2">
          <div className="col-12">
            <h4 className="dashboard-heading">Setup a New Client</h4>
            <p className="mt-2" style={{ color: '#888', fontSize: '0.9em', fontWeight: '300' }}>Onboard new clients by setting up basic details, configuring client regions and adding properties for each region.</p>
          </div>
        </div>

        {/* <LinearStepper steps={steps} skipped={skipped} activeStep={activeStep} setActiveStep={setActiveStep} isStepSkipped={isStepSkipped}/> */}
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={index <= completed ? true : false}
              sx={{
                '& .MuiStepIcon-root': {
                  color: 'inherit',
                  '&.Mui-completed': {
                    color: '#FF9637',
                  },
                  '&.Mui-active': {
                    color: '#FF9637',
                  },
                },
                '& .MuiStepLabel-label': {
                  '&.Mui-active': {
                    color: '#FF9637',
                  },
                  '&.Mui-completed': {
                    color: '#FF9637',
                  },
                },
              }}
            >
              <StepButton
                color="inherit"
                onClick={() => {
                  if (index <= activeStep) {
                    handleBack(index);
                  } else {
                    handleNext(index);
                  }
                }}
              >
                <StepLabel
                  StepIconProps={{
                    sx: {
                      color: '#FF9637',
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>


        {!showComplete ? (
          <React.Fragment>
            {
              activeStep === 0 ?
                <BasicDetails
                  name={name}
                  domain={domain}
                  headOffice={headOffice}
                  setName={setName}
                  setDomain={setDomain}
                  setHeadOffice={setHeadOffice}
                  international={international}
                  setInternational={setInternational}
                  showInput={showInput}
                  otherCity={otherCity}
                  setOtherCity={setOtherCity}
                  preferredOTA={preferredOTA}
                  setPreferredOTA={setPreferredOTA}
                  headOfficeCountry={headOfficeCountry}
                  setHeadOfficeCountry={setHeadOfficeCountry}
                  clientType={clientType}
                  setClientType={setClientType}
                  integratedPMS={integratedPMS}
                  setIntegratedPMS={setIntegratedPMS}
                  integratedCMS={integratedCMS}
                  setIntegratedCMS={setIntegratedCMS}
                  hotelName={hotelName}
                  setHotelName={setHotelName}
                  pmsOptions={pmsOptions}
                  cmsOptions={cmsOptions}
                />
                : null
            }
            {
              // activeStep === 1 ? <ClientRegions properties={properties} setProperties={setProperties} international={international} preferredOTA={preferredOTA} country={country} setCountry={setCountry} /> : null
              activeStep === 1 ? <ClientRegionsV2 properties={properties} setProperties={setProperties} international={international} preferredOTA={preferredOTA} country={country} setCountry={setCountry} pmsOptions={pmsOptions} cmsOptions={cmsOptions} /> : null
            }
            {
              activeStep === 2 ? <ClientPropertiesV2 focusComp={focusComp} setFocusComp={setFocusComp} international={international} clientProperties={properties} setClientProperties={setProperties} preferredOTA={preferredOTA} propertyTypes={propertyTypes} serviceTypes={serviceTypes} mealPlans={mealPlans} amenities={amenities} roomTypes={roomTypes} bedTypes={bedTypes} handleBack={handleBack} /> : null
            }
            {
              activeStep === 3 ? <SummaryV2 name={name} domain={domain} headOffice={headOffice} international={international} clientProperties={properties} createClient={createClientV2} showComplete={showComplete} setClientProperties={setProperties} pmsOptions={pmsOptions} cmsOptions={cmsOptions} handleBack={handleBack} /> : null
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {
                activeStep !== steps.length ?
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={() => { handleBack(activeStep - 1) }}
                    sx={{ mr: 1 }}
                    size="medium"
                    variant="outlined"
                  >
                    <KeyboardArrowLeftIcon /> BACK
                  </Button>
                  :
                  null
              }
              <Box sx={{ flex: '1 1 auto' }} />


              {
                activeStep === steps.length - 1 ?
                  null
                  :
                  <Button color="inherit" size="medium"
                    variant="outlined" onClick={() => { handleNext(activeStep + 1) }}>
                    Next <KeyboardArrowRightIcon />
                  </Button>
              }


            </Box>
          </React.Fragment>
        )
          :
          <React.Fragment>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              <img className='hotel' src={hotel} alt='Hotel' />
              <h3 className='bold'><span className='text-orange'>{name}</span> Onboarded successfully!</h3>
              <p className='text-muted light'>You can now create users for this client, assign roles and user scope through the User Management Dashboard.</p>
              <div className="mt-3 mb-5 w-100 px-5 d-flex justify-content-between align-items-center">
                <Button
                  className="w-50 mx-4"
                  onClick={() => navigate('/home')}
                  style={{ backgroundColor: 'black', color: 'white', border: 'none' }}
                  variant="contained"
                >
                  Maybe later
                </Button>
                <Button
                  className="w-50 mx-4"
                  onClick={() => navigate('/admin/user-management')}
                  style={{ backgroundColor: 'black', color: 'white', border: 'none' }}
                  variant="contained"
                >
                  Create users
                </Button>

              </div>
            </div>
          </React.Fragment>
        }


      </section>
    </Grow>
  )
};

export default NewClient;