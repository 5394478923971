import { useEffect } from 'react'
import { BrowserRouter, Routes, Route , useNavigate} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout'
import isAuthenticated, {getCookie} from '../../services/auth.js'
import { isValidRoute } from '../../Auth';
import {AppProvider} from '../../context/AppContext';
import Properties from './Properties';
import Controls from './Controls';

import '../../assets/styles/admin.css';
import '../../assets/styles/base.css';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';


const RSM = ({ match }) => {
  const dashboardId = useUserStore((state) => state.dashboardId);
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
  useEffect(() => {
    if (!dashboardId) {
      fetchDashboardId(); 
    }
  }, [dashboardId, fetchDashboardId]);
  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(5)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
          <DashboardSessionTracker dashboardId={15}> </DashboardSessionTracker>
    <BaseLayout>
      <Routes>
          <Route path="properties" element={<Properties />} />
          <Route path="controls/:propertyId" element={<Controls />} />
      </Routes>
    </BaseLayout>
    </AppProvider>
  )
};

export default RSM;