import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import cities from '../../services/cities.js';
import internationalCities from '../../services/internationalCities.js';

import mmtLogo from "../../assets/img/mmt.png";
import cleartripLogo from "../../assets/img/cleartrip.png";
import emtLogo from "../../assets/img/emt.png";
import bookingLogo from "../../assets/img/booking.png";
import agodaLogo from "../../assets/img/agoda.png";
import { Country, State, City } from "country-state-city";


const BasicDetails = ({ name, domain, headOffice, setName, setDomain, setHeadOffice, international, setInternational, showInput, otherCity, setOtherCity, preferredOTA, setPreferredOTA, headOfficeCountry, setHeadOfficeCountry, clientType, setClientType, integratedPMS, setIntegratedPMS, integratedCMS, setIntegratedCMS, hotelName, setHotelName, pmsOptions, cmsOptions }) => {

	const [countries, setCountries] = useState([])
	const [cities, setCities] = useState([]);

	const otas = [
		{ "label": "MakeMyTrip", "value": "mmt" },
		{ "label": "ClearTrip", "value": "cleartrip" },
		{ "label": "EaseMyTrip", "value": "emt" },
		{ "label": "Booking.com", "value": "booking" },
		{ "label": "Agoda", "value": "agoda" },
		{ "label": "Ixigo", "value": "ixigo" },
	]
	const otaOptions = otas.map((item) => ({
		label: item.label,
		value: item.value,
		image: item.image,
	}));

	const clientTypes = ['Hotel', 'Resort', 'Homestay']

	const clientTypeOptions = clientTypes.map((item) => ({
		label: item,
		value: item
	}))

	// const otaImages = {
	// 	mmt: mmtLogo,
	// 	cleartrip: cleartripLogo,
	// 	emt: emtLogo,
	// 	booking: bookingLogo,
	// 	agoda: agodaLogo,
	// };

	const otaImages = {
		mmt: 'https://cdn.brandfetch.io/idC6eY3m41/w/336/h/336/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
		cleartrip: 'https://cdn.brandfetch.io/idychDJkPn/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
		emt: 'https://cdn.brandfetch.io/idqXUKASvF/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
		booking: 'https://cdn.brandfetch.io/id9mEmLNcV/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
		agoda: 'https://cdn.brandfetch.io/idrJbkwvG0/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
		ixigo: 'https://cdn.brandfetch.io/idiqOCfzCa/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B'
	};

	const CustomOption = (props) => {
		const { data, innerRef, innerProps } = props;
		return (
			<div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "10px" }}>
				<img src={otaImages[data.value]} alt={data.label} style={{ width: "20px", height: "20px", marginRight: "10px", borderRadius: '50%' }} />
				{data.label}
			</div>
		);
	};


	const CustomSingleValue = (props) => {
		const { data } = props;
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				{preferredOTA.label && <img src={otaImages[data.value]} alt={data.label} style={{ width: "20px", height: "20px", marginRight: "10px", borderRadius: '50%' }} />}
				{data.label}
			</div>
		);
	};


	// useEffect(() => {
	// 	setHeadOffice({ 'label': '', 'value': '' })
	// }, [international])

	useEffect(() => {
		setCountries(Country.getAllCountries())
	}, [])

	useEffect(() => {
		if (headOfficeCountry && typeof headOfficeCountry.value !== 'undefined' && headOfficeCountry.value) {
			const cities = City.getCitiesOfCountry(headOfficeCountry.country_code);
			setCities(cities)
		}
	}, [headOfficeCountry])



	return (
		<div className='mt-5 mb-4'>
			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Client Name<span style={{ color: 'red' }}>*</span></label>
				</div>
				<div className='col-8'><input value={name} onChange={(evt) => setName(evt.target.value)} type="text" className="mt-1 form-control" placeholder="Client name" aria-label="name" /></div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Hotel Name<span style={{ color: 'red' }}>*</span></label>
				</div>
				<div className='col-8'><input value={hotelName} onChange={(evt) => setHotelName(evt.target.value)} type="text" className="mt-1 form-control" placeholder="Hotel name" aria-label="name" /></div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Domain</label>
					<p className='small grey-2 light'>Client website (optional)</p>
				</div>
				<div className='col-8'>
					<div class="input-group">
						<span class="input-group-text" id="basic-addon1">www.</span>
						<input value={domain} onChange={(evt) => setDomain(evt.target.value)} type="text" className="form-control" placeholder="Website URL" aria-label="domain" />
					</div>
				</div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Preferred OTA<span style={{ color: 'red' }}>*</span></label>
				</div>
				<div className='col-8'>
					<Select
						value={preferredOTA}
						onChange={(opt) => setPreferredOTA({ 'label': opt.label, 'value': opt.value })}
						className="w-100 mt-1"
						options={otaOptions}
						placeholder='Select preferred OTA'
						components={{
							Option: CustomOption,
							SingleValue: CustomSingleValue,
						}}

					/>

				</div>
			</div>

			{/* <div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Client Type</label>
				</div>
				<div className='col-8'>
					<select value={international} onChange={(evt) => setInternational(evt.target.value)} className="mt-1 form-control">
						<option value='0'>Domestic</option>
						<option value='1'>International</option>
						<option value='2'>Both</option>
					</select>
				</div>
			</div> */}

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Client Type<span style={{ color: 'red' }}>*</span></label>
				</div>
				<div className='col-8'>
					<Select
						value={clientType}
						onChange={(opt) => setClientType(opt)}
						className="w-100 mt-1"
						options={clientTypeOptions}
						placeholder='Select Client Type'
					/>

				</div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Integrated PMS</label>
					<p className='small grey-2 light'>Client Property Management System's (optional)</p>
				</div>
				<div className='col-8'>
					<Select
						value={integratedPMS}
						onChange={(opt) => setIntegratedPMS(opt)}
						className="w-100 mt-1"
						options={pmsOptions}
						isMulti
						placeholder='Select Property Management Systems'
					/>

				</div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Integrated CMS</label>
					<p className='small grey-2 light'>Client Channel Management System's (optional)</p>
				</div>
				<div className='col-8'>
					<Select
						value={integratedCMS}
						onChange={(opt) => setIntegratedCMS(opt)}
						className="w-100 mt-1"
						options={cmsOptions}
						isMulti
						placeholder='Select Channel Management Systems'
					/>

				</div>
			</div>

			{/* <div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Head Office</label>
					<p className='small grey-2 light'>Choose others option if city is not found.</p>
				</div>
				<div className='col-8'>
					{
						international == '0' && <Select value={headOffice} onChange={opt => setHeadOffice({ 'label': opt.value, 'value': opt.value })} className="w-100 mt-1" options={[...cities, 'Other'].map(item => ({ 'label': item, 'value': item }))} />
					}
					{
						international == '1' && <Select value={headOffice} onChange={opt => setHeadOffice({ 'label': opt.value, 'value': opt.value })} className="w-100 mt-1" options={[...Object.keys(internationalCities), 'Other'].map(item => ({ 'label': item, 'value': item }))} />
					}
					{
						international == '2' && <Select value={headOffice} onChange={opt => setHeadOffice({ 'label': opt.value, 'value': opt.value })} className="w-100 mt-1" options={[...cities, ...Object.keys(internationalCities), 'Other'].map(item => ({ 'label': item, 'value': item }))} />
					}
				</div>
			</div> */}

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Head Office Country<span style={{ color: 'red' }}>*</span></label>
				</div>
				<div className='col-8'>
					<Select
						value={headOfficeCountry}
						onChange={(opt) => setHeadOfficeCountry({ 'label': opt.label, 'value': opt.value, "country_code": opt.country_code })}
						className="w-100 mt-1"
						options={countries.map(item => ({ 'label': item.name, 'value': item.name, 'country_code': item.isoCode }))}
						placeholder='Select Country'
					/>

				</div>
			</div>

			<div className="mt-3 w-100 row">
				<div className='col-4'>
					<label className=''>Head Office City<span style={{ color: 'red' }}>*</span></label>
				</div>
				<div className='col-8'>
					<Select
						value={headOffice}
						onChange={(opt) => setHeadOffice({ 'label': opt.label, 'value': opt.value })}
						className="w-100 mt-1"
						options={cities.map(item => ({ 'label': item.name, 'value': item.name }))}
						placeholder='Select City'
					/>

				</div>
			</div>

			{
				showInput ?
					<div className="w-100 row">
						<div className='col-4'>
						</div>
						<div className='col-8'>
							<input value={otherCity} onChange={(evt) => setOtherCity(evt.target.value)} type="text" className="mt-1 form-control" placeholder="City name" aria-label="name" />
						</div>
					</div>
					:
					null
			}

		</div>
	)
};

export default BasicDetails;