import React from "react";
import { Modal, Box, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DynamicModal = ({
  open,
  onClose,
  type,
  data,
  dateRange,
  setModalOpen
}) => {
  const [filteredData, setFilteredData] = useState(data || []);
  const [searchText, setSearchText] = useState("");
  // Define configurations for each modal type
  const modalConfigs = {
    dashboard: {
      title: `Dashboard Usage Tracker of ${data[0]['full_name']} (${data[0]['email']})`,
      columns: [
        // { field: "username", headerName: "Username", flex: 1 },
        { field: "dashboard_name", headerName: "Dashboard", flex: 1 },
        { field: "dashboard_accessed", headerName: "Dashboard Accessed", flex: 1 },
        { field: "avg_time_spent", headerName: "Avg Time Spent (HH:MM)", flex: 1 },
      ],
      enableSearch: true,
    },
    userDetails: {
      title: "User Details",
      columns: [
        { field: "creationDate", headerName: "Creation Date", flex: 1 },
        { field: "fullName", headerName: "Full Name", flex: 1 },
        { field: "email", headerName: "Email", flex: 1 },
        { field: "client", headerName: "Client", flex: 1 },
        { field: "firstLogin", headerName: "First Login", flex: 1 },
        { field: "lastLogout", headerName: "Last Logout", flex: 1 },
      ],
      enableSearch: true,
    },
  };

  const config = modalConfigs[type] || {};

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filteredRows = data.filter((row) =>
      Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );
    setFilteredData(filteredRows);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSortModelChange = (model) => {
    if (type === "dashboard") {
      const firstRowUsername = filteredData[0]?.username;
      const sortedData = [...filteredData].sort((a, b) => {

        const column = model[0]?.field;
        const direction = model[0]?.sort === "asc" ? 1 : -1;

        if (column === "username") return 0;
        return a[column] > b[column] ? direction : -direction;
      });
      sortedData[0].username = firstRowUsername;
      for (let i = 1; i < sortedData.length; i++) {
        sortedData[i].username = "";
      }
      setFilteredData(sortedData);
    }
  };


  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div>
          <h4 className="text-orange">{config.title}</h4>
          <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setModalOpen(false)}
                    aria-label="close"
                    style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }}
                  >
                    <CloseIcon />
                  </IconButton>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {type != "userDetails" && (<div className="me-2">Created at {data[0].created_at}</div>)}
            <h4 style={{ marginBottom: 0, color: '#CCCCCC', fontSize: '1em' }}> Date Range : ({dateRange})</h4>
          </div>
          {config.enableSearch && (
            <div>
              <TextField
                size='small'
                label="Search"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleSearch}


              />
            </div>
          )}
        </div>
        <DataGrid
          autoHeight
          rows={filteredData.map((row, index) => ({ id: index, ...row }))}
          columns={config.columns || []}
          pageSizeOptions={[5, 10]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 6 },
            },
          }}
          sx={{
            borderCollapse: "collapse",
            border: "none",
            minHeight: '15em'
          }}
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Modal>
  );
};

export default DynamicModal;
