import { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';

import axios from 'axios';
import { fetchToken, deleteToken } from '../../../Auth'

import "../../../assets/styles/navbar.css";
import useUserStore from "../../../context/UserContext";

// import {sendUsageData} from "../SessionTracker"


function Navbar({ name, staff, clients, clientId, setClientId,onLogout }) {

	const {
		userSignOut
	} = useUserStore();

	const [dashboardName, setDashboardName] = useState('')
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const navRef = useRef();

	const navigate = useNavigate();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	useEffect(() => {
		if (window.location.href.includes('/master')) setDashboardName('PRICE COMPETITIVENESS')
		if (window.location.href.includes('/rooms')) setDashboardName('ROOM SETUP MANAGEMENT')
	}, [])

	const toggleClient = async (id) => {
		// setClientId(id)
		try {
			const response = await axios.post("/toggle_client", { client_id: id }, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				window.location.replace("/home");
			}
		} catch (err) {
			console.error("Error", err);
			if (err.response.status === 401) {
				navigate('/login')
			}
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const signOut = async () => {

		// try{
		// 	const response=await axios.post("/logout_user",{},{
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
		// 		},}
		// 	)

		// 	if (response.status == 200 && response.data !== undefined) {
		// 		window.location.reload();
		// 		localStorage.removeItem("temitope");
		// 		userSignOut()
		// 		deleteToken()
		// 		navigate("/login");
		// 		window.location.replace("/login");
				
		// 	}
		// }   catch (err) {
		// 	localStorage.removeItem("temitope");
		// 	userSignOut()
		// 	deleteToken()
		// 	navigate("/login");
		// }

	};

	return (
		<header>
			<nav className="d-flex justify-content-between w-100" ref={navRef}>
				{
					staff ?
						<Select
							size="small"
							style={{ backgroundColor: "#fff", width: '15em' }}
							value={clientId}
							onChange={(evt) => toggleClient(evt.target.value)}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							{clients.map(item => <MenuItem value={item[0]}>{item[1]}</MenuItem>)}
						</Select>
						:
						null
				}
				<div className="w-100 d-flex justify-content-center">
					<h6 className="text-white bold mb-0">{dashboardName}</h6>
				</div>
				<div className="w-10 d-flex justify-content-end align-items-center">
					<Tooltip title="">
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
						>
							<Avatar sx={{ width:35, height:35 ,fontSize:'1rem'}}>{name ? name[0] : ''}</Avatar>
						</IconButton>
					</Tooltip>
				</div>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>


			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem 
				// onClick={() => signOut()
				 onClick={onLogout}>
					<ListItemIcon>
						<LogoutIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Logout</ListItemText>
				</MenuItem>
			</Menu>
		</header>
	);
}

export default Navbar;