import { useEffect } from 'react'
import { Routes, Route , useNavigate} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import {AppProvider} from '../../context/AppContext';
import { isValidRoute } from '../../Auth';

import ManageHotels from './ManageHotels';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const HotelManagement = ({ match }) => {
  const dashboardId = useUserStore((state) => state.dashboardId);
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
  useEffect(() => {
    if (!dashboardId) {
      fetchDashboardId(); 
    }
  }, [dashboardId, fetchDashboardId]);
  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(34)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
          <DashboardSessionTracker dashboardId={34}> </DashboardSessionTracker>
      <BaseLayout>
        <Routes>
            <Route path="manage-hotels" element={<ManageHotels />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default HotelManagement;