import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoIcon from '@mui/icons-material/Info';
import Select from 'react-select';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchToken } from '../../Auth';
import { toast } from 'react-toastify';

import cities from '../../services/cities';
import cityDetails from '../../services/cityDetails';

const UserScope = () => {
	const [type, setType] = useState({ 'label': 'Internal', 'value': 1 });
	const [users, setUsers] = useState([]);
	const [showTable, setShowTable] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState("");
	const [clients, setClients] = useState([]);
	const [selectedClient, setSelectedClient] = useState(null);
	const [properties, setProperties] = useState([]);
	const [scope, setScope] = useState({});

	const [checked, setChecked] = React.useState([1]);

	const navigate = useNavigate();

	const handleToggle = (city, id) => {
		setScope(prevCityData => {
			const updatedCityData = { ...prevCityData };

			if (updatedCityData[city]) {
				const index = updatedCityData[city].indexOf(id);

				if (index !== -1) {
					// If the id is present, remove it
					updatedCityData[city].splice(index, 1);

					// If the array becomes empty, remove the city
					if (updatedCityData[city].length === 0) {
						delete updatedCityData[city];
					}
				} else {
					// If the id is not present, add it
					updatedCityData[city].push(id);
				}
			} else {
				// If the city doesn't exist, create a new entry
				updatedCityData[city] = [id];
			}

			return updatedCityData;
		});
		// if(scope.includes(value)){
		// 	setScope(scope.filter((item) => item !== value))
		// }else{
		// 	setScope([...scope, value])
		// }
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getAllUsers = async () => {
		let utype = type['value'] === 1 ? 'internal' : 'external'
		try {
			const response = await axios.get(`/get_all_users/${utype}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				let temp = []
				for (let item of response.data) {
					temp.push({ label: item[1], value: item[0] })
				}
				setUsers(temp)
			}

		} catch (err) {
			console.error("Error", err);
			if (err && err.response.status === 401) {
				navigate('/login')
			}
			if (err && err.response.status === 500) {
				toast.error('Something went wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
				return
			}
		}
	}

	const getProperties = async () => {
		try {
			const response = await axios.get(`/get_client_properties/${selectedClient['value']}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				setProperties(response.data)
			}

		} catch (err) {
			console.error("Error", err);
			if (err && err.response.status === 401) {
				navigate('/login')
			}
			if (err && err.response.status === 500) {
				toast.error('Something went wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
				return
			}
		}
	}

	const getScope = async () => {
		try {
			const response = await axios.get(`/get_user_scope/${selectedUserId}/${selectedClient['value']}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				setShowTable(true)
				setScope(response.data)
			}

		} catch (err) {
			console.error("Error", err);
			if (err && err.response.status === 401) {
				navigate('/login')
			}
			if (err && err.response.status === 500) {
				toast.error('Something went wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
				return
			}
		}
	}

	const getAssignedClients = async () => {
		try {
			const response = await axios.get(`/get_assigned_clients/${selectedUserId}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				let temp = []
				for (let item of response.data) {
					temp.push({ label: item['client_name'], value: item['client_id'] })
				}
				setClients(temp)
			}

		} catch (err) {
			console.error("Error", err);
			if (err && err.response.status === 401) {
				navigate('/login')
			}
			if (err && err.response.status === 500) {
				toast.error('Something went wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
				return
			}
		}
	}

	const updateScope = async () => {
		if (!Object.keys(scope).length) {
			toast.error('Select atleast one client region!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
				type: toast.TYPE.ERROR
			});
			return
		}
		try {
			const response = await axios.post(`/update_user_scope`, {
				client_id: selectedClient['value'],
				user_id: selectedUserId,
				scope: scope
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				toast.success('User scope updated!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.SUCCESS
				});
				return
			}

		} catch (err) {
			console.error("Error", err);
			if (err && err.response.status === 401) {
				navigate('/login')
			}
			if (err && err.response.status === 500) {
				toast.error('Something went wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
				return
			}
		}
	}

	useEffect(() => {
		setProperties([])
		setSelectedClient(null);
		setScope([])
		getAllUsers()
	}, [type]);

	useEffect(() => {
		setProperties([])
		setSelectedClient(null);
		setScope([])
		if (selectedUserId) {
			getAssignedClients()
		}
	}, [selectedUserId])

	useEffect(() => {
		if (selectedClient && selectedClient['value']) {
			getProperties()
		}
	}, [selectedClient])

	useEffect(() => {
		if (Object.keys(properties).length) {
			getScope()
		}
	}, [properties])

	return (
		<section className="p-3">
			<div className="row">
				<div className="col-12">
					<Grow
						in={true}
						{...(true ? { timeout: 700 } : {})}
					>
						<div className="admin-card bg-white p-4">
							<div className="w-100 d-flex justify-content-between align-items-center">
								<h6 className="admin-card-header text-orange">User Scope</h6>
								<IconButton onClick={handleClickOpen} aria-label="open">
									<InfoIcon />
								</IconButton>
							</div>

							<div className="admin-card-section p-3 mt-2 row">
								<div className="col-4">
									<label>User type</label>
									<Select value={type} onChange={opt => setType(opt)} className="mt-1 w-100" options={[{ 'label': 'Internal', value: 1 }, { 'label': 'External', value: 0 }]} />
								</div>
								<div className="col-4">
									<label>User</label>
									<Select placeholder='Select a User' onChange={opt => setSelectedUserId(parseInt(opt.value))} className="mt-1 w-100" options={users} />
								</div>
								<div className="col-4">
									<label>Clients</label>
									<Select placeholder='Select a Client' value={selectedClient} onChange={opt => setSelectedClient(opt)} className="mt-1 w-100" options={clients} />
								</div>
							</div>


							<div className="mt-4 w-100 row">
								{
									showTable ?
										<div className="col-12 px-5">
											<List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
												{
													properties && Object.keys(properties).length ?
														Object.keys(properties).map((key) => {
															const labelId = `checkbox-list-secondary-label-${key}`;
															return (
																<Grow
																	in={true}
																	{...(true ? { timeout: 700 } : {})}
																>
																	<Accordion style={{ boxShadow: 'none', borderRadius: '12px' }} className="mb-3 py-2 outline-list-item">
																		<AccordionSummary
																			expandIcon={<ExpandMoreIcon />}
																			aria-controls="panel1a-content"
																		>
																			<div className='w-100 row'>
																				<div className='col-6 d-flex align-items-center'>
																					{cityDetails[key] && cityDetails[key]['img'] ? (
																						<Avatar
																							className='mr-2'
																							alt={key}
																							src={cityDetails[key]['img']}
																						/>
																					) : null}
																					<h6 className='bold'>{key}</h6>
																				</div>
																				<div className='col-6 d-flex justify-content-end align-items-center'>
																					{
																						key in scope ?
																							scope[key].length ?
																								<><i class="text-success fa-solid fa-circle-check mx-2"></i> <span className='grey-2 bold'>{scope[key].length}/{properties[key]['properties'].length} properties assigned</span></>
																								:
																								<><i class="text-warning fa-solid fa-circle-exclamation mx-2"></i> <span className='grey-2 bold'>{scope[key].length}/{properties[key]['properties'].length} properties assigned</span></>
																							:
																							<><i class="text-warning fa-solid fa-circle-exclamation mx-2"></i> <span className='grey-2 bold'>0/{properties[key]['properties'].length} properties assigned</span></>
																					}
																				</div>
																			</div>

																		</AccordionSummary>
																		<AccordionDetails>
																			<div className='row d-flex align-items-center' style={{ fontSize: '1.3em' }}>
																				{
																					properties[key]['properties'].map(prop => (
																						<div className='row'>
																							<div className='col-1'>
																								<Checkbox checked={key in scope && scope[key].includes(prop['property_id'])} onChange={() => handleToggle(key, prop['property_id'])} />
																							</div>
																							<div className='col-5 d-flex align-items-center'>
																								{prop['property_name']} {prop['is_active'] ? <div className='mx-2 success-pill small bold'>ACTIVE</div> : <div className='mx-2 warning-pill small bold'>INACTIVE</div>}
																							</div>
																							<div className='col-2'>
																								<div className='grey-pill small'>
																									{Object.keys(prop['room_types']).length} room types
																								</div>
																							</div>
																							<div className='col-2'>
																								<div className='grey-pill small'>
																									{Object.values(prop['room_types']).reduce((acc, obj) => acc + obj.no_of_rooms, 0)} rooms
																								</div>
																							</div>
																						</div>
																					))
																				}
																			</div>
																		</AccordionDetails>
																	</Accordion>
																</Grow>
															)
														})
														:
														<></>
												}
											</List>

											{
												selectedClient ?
													<div className="mt-3 w-100 d-flex justify-content-center align-items-center">
														<Button onClick={() => updateScope()} variant="contained">Assign Scope</Button>
													</div>
													:
													null
											}
										</div>
										:
										<Alert severity="info">Select a user & client</Alert>
								}
							</div>
						</div>
					</Grow>
				</div>
			</div>


			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle className="dialog-title">
					What is User Scope?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Scope of a user defines what client properties the user has access to. Dashboards visible to a user
						will have data for those specific properties only. A user can have the same "user role" but different
						"user scopes".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}><span className="bold">Got it</span></Button>
				</DialogActions>
			</Dialog>
		</section>
	)
}


export default UserScope;