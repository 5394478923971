import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Line, Pie } from "react-chartjs-2";
import { ArcElement } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // 1. Import Filler plugin
} from "chart.js";
import axios from 'axios';
import moment from 'moment';
import Grow from '@mui/material/Grow';
import { toast } from 'react-toastify';
import {fetchToken} from '../../Auth';
import { useAppContext } from '../../context/AppContext';
import Table from '../../components/common/Table/Table';
import AnimatedNumbers from "react-animated-numbers"


const Dashboard = ({userCount, connectedUsers}) => {

	ChartJS.register(
	  CategoryScale,
	  LinearScale,
	  PointElement,
	  LineElement,
	  Title,
	  Tooltip,
	  Legend,
	  Filler // 1. Register Filler plugin
	);

	ChartJS.register(ArcElement);

	const [mode, setMode] = useState('weekly')

	const [trafficDates, setTrafficDates] = useState([]);
	const [trafficData, setTrafficData] = useState([]);
	const [lastLogin, setLastLogin] = useState(null);
	const [lastLoginMoment, setLastLoginMoment] = useState('');
	const [newUsers, setNewUsers] = useState(0);
	const [dashboards, setDashboards] = useState([])
	const [dashboardsData, setDashboardsData] = useState([]);
	// const [count, setCount] = useState(0);

	const { count, users, increment, decrement } = useAppContext();

	// useEffect(() => {
	// 	setCount(userCount)
	// }, [userCount])

	const navigate = useNavigate();

	const options = {
	  responsive: true,
	  tension: 0.3 // Set the tension (curvature) of the line
	};

	const getData = async () => {
		try {
	        const response = await axios.get(`/get_usage_data/${mode}`, {
	          headers: {
	            'Content-Type': 'application/json',
	            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
	          },
	        });
	        if(response.status == 200 && response.data !== undefined){
	          setTrafficDates(Object.keys(response.data.user_traffic));
	          setTrafficData(Object.keys(response.data.user_traffic).map(key => parseInt(response.data.user_traffic[key])))

	          setLastLogin(response.data.last_login)
	          setLastLoginMoment(moment(response.data.last_login.date + " " + response.data.last_login.time, "DD-MM-YYYY HH:mm").startOf('hour').fromNow())
	          setNewUsers(response.data.new_users)

	          setDashboards(Object.keys(response.data.dashboard_usage))
	          setDashboardsData(Object.keys(response.data.dashboard_usage).map(key => response.data.dashboard_usage[key]))
	        }
	    } catch (err) {
	      console.error("Error", err);
	      if(err.response.status === 401){
	        navigate('/login')
	      }
	    }
	}

	useEffect(() => {
		if(mode) getData();
	}, [mode])

	return(
		<Grow
	      in={true}
	      {...(true ? { timeout: 700 } : {})}
	    >
			<div className='px-4 py-2 mt-3 white-card'>
					<div className="mt-3">
						<div className='bg-grey p-1 py-2 corner w-100 row'>
							<div className='col-10'>
								<h3 class='dashboard-card-title'>Usage Tracker</h3>
								<p className='grey-2 small mt-1'>Monitor and analyze usage of the application across all dashboards and users.</p>
							</div>
							<div className='col-2 d-flex justify-content-between align-items-center'>
								<i class="fa-regular fa-calendar mx-2"></i>
								<select onChange={(evt) => setMode(evt.target.value)} className='form-control'>
									<option value='weekly' selected>Last 7 days</option>
									<option value='monthly'>Last 30 days</option>
									<option value='quarterly'>Last 90 days</option>
								</select>
							</div>
						</div>
					</div>

					<div className='mt-4 w-100 row'>
						<div className='col-8'>
							<div className='outline-card p-3 row'>
								<h6 className='bold'>{mode === 'weekly' ? 'Weekly' : 'Monthly'} User Traffic</h6>
								<div className='col-12'>
									{
										trafficData.length && trafficDates.length ?
										<Line className='' options={options} data={{
											'labels': trafficDates,
											'datasets': [
											    {
											      label: "Number of logins",
											      data: trafficData,
											      borderColor: "#FF8B00",
											      backgroundColor: "rgb(255, 139, 0)",
											      fill: {
											        target: "origin",
											        above: "rgba(255, 139, 0, 0.3)"
											      }
											    }
											]
										}} />
									    :
									    null
									}
							    </div>
							</div>
						</div>
						<div className='col-4 row'>
							{
								lastLogin ?
								<div className='outline-card p-3 w-100'>
									<h6 className='bold'><i className="fa-solid fa-right-to-bracket mr-2"></i> Recent Login</h6>
									{lastLoginMoment !== "Invalid date" ? (<>
										<p className='mt-3 text-orange mb-1'>{lastLogin['username']}</p>
										<p className='grey-2 small'>{lastLoginMoment}</p>
									</>
									):
									(
									<>
									No data
									</>)}
				
								</div>
								:
								null
							}
							<div className='col-8'>
								<div className='outline-card p-3 w-100'>
									<h6 className='bold'><i class="fa-solid fa-user-plus mr-2"></i> New Sign Ups</h6>
									<h2 className='text-orange bold mt-3'>{newUsers}</h2><span className='small grey-2'>{mode === 'weekly'  ? 'in last 7 days': (mode === 'monthly'  ? 'in last 30 days'  : 'in last 90 days') }</span>
								</div>
							</div>
						</div>
					</div>

					{/* <div className='mt-4 w-100 row'>
						<div className='col-4'>
							<div className='p-3 outline-card'>
								<h6 className='bold'>Dashboards Usage</h6>
								<p className='small grey-2'>{mode === 'weekly' ? 'last 7 days' : mode === 'monthly' ? 'last 30 days' : 'last 90 days'}</p>
								<Pie className='' options={options} data={{
									'labels': dashboards,
									'datasets': [
									    {
									      data: dashboardsData,
									      backgroundColor: [ "#455a64","#4b6c81", "#517e9e", "#5891bc", "#5ea3d9", "#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99"],
	      								hoverBackgroundColor: [ "#455a64","#4b6c81", "#517e9e", "#5891bc", "#5ea3d9", "#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99"]
									    }
									]
								}} />
							</div>
						</div>
						<div className='col-8'>
							<div className='p-3 outline-card'>
								<div className='w-100 row'>
									<div className='col-9'>
										<h6 className='bold'><span className='text-success small'><i class="fa-solid fa-circle mr-2"></i></span> Current Live Users</h6>
										<p className='small grey-2'>Users currently accessing Zetta</p>
									</div>
									<div className='col-3 d-flex justify-content-end align-items-center'>
										<h1 className='bold'>
											<span className='text-orange'>
												{/* <AnimatedNumbers */}
									   {/*      includeComma */}
									   {/*      className='text-orange mr-4' */}
									   {/*      transitions={(index) => ({ */}
									   {/*        type: "spring", */}
									   {/*        duration: index + 0.3, */}
									   {/*      })} */}
									   {/*      animateToNumber={count} */}
									   {/*    /> */}
												{/* {count}
											</span> 
											
										</h1>
										<span className='small-3 mx-2 grey-2 bold'>users</span>
									</div>
								</div>
								<div className='mt-2'>
									<Table 
										disableSelect={true} 
										cols={[
											{ field: 'id', headerName: 'Sr No.', flex: 1},
											{ field: 'ip', headerName: 'Username', flex: 1},
										]} 
										rows={users.map((item, idx) => ({id: idx+1, ip: item}))} />
								</div>
							</div>
						</div>
					</div> */}

			</div>
		</Grow>
	)
};

export default Dashboard;