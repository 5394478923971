import * as React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationDialog = ({ open, title, message, onConfirm, onCancel }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                style: {
                    position: 'fixed',
                    top: '5%',
                    margin: 0,
                    maxWidth: '400px',
                    width: '90%',
                    borderRadius: '12px',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                }
            }}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
        >
            <DialogTitle sx={{
                padding: '16px 24px',
                backgroundColor: '#f8f9fa',
                borderBottom: '1px solid #e9ecef'
            }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            color: '#2d3436'
                        }}
                    >
                        {title}
                    </Typography>
                    <IconButton
                        onClick={onCancel}
                        size="small"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.04)'
                            }
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '1.2rem' }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{
                padding: '24px',
                backgroundColor: '#ffffff'
            }}>
                <Typography sx={{
                    color: '#4a4a4a',
                    fontSize: '0.95rem',
                    lineHeight: 1.5
                }}>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions sx={{
                padding: '16px 24px',
                backgroundColor: '#ffffff',
                borderTop: '1px solid #e9ecef'
            }}>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        padding: '6px 16px',
                        minWidth: '85px',
                        backgroundColor: '#ff6666', // Medium red
                        '&:hover': {
                            backgroundColor: '#ff4d4d', // Darker red on hover
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        padding: '6px 16px',
                        minWidth: '85px',
                        backgroundColor: '#66cc66', // Medium green
                        '&:hover': {
                            backgroundColor: '#4dba4d', // Darker green on hover
                        },
                    }}
                >
                    Confirm
                </Button>

            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;