import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { fetchToken } from "../../../Auth";
import { toast } from "react-toastify";
import { deslugify } from "../../../services/helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { openUrlInIncognito, getRoomURL, formatDate } from "../../../services/helper"

const RateParity = ({
  tenantId,
  propertyId,
  roomType,
  mealPlan,
  ota,
  date,
  masterData,
  hotelDetails,
}) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const sites = {
    agoda: "Agoda",
    booking: "Booking",
    emt: "EaseMyTrip",
    mmt: "MakeMyTrip",
    ixigo: "Ixigo",
    cleartrip: "ClearTrip",
    ids: "IDS Next",
  };
  const otaImages = {
    mmt: 'https://cdn.brandfetch.io/idC6eY3m41/w/336/h/336/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
    cleartrip: 'https://cdn.brandfetch.io/idychDJkPn/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
    emt: 'https://cdn.brandfetch.io/idqXUKASvF/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
    booking: 'https://cdn.brandfetch.io/id9mEmLNcV/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
    agoda: 'https://cdn.brandfetch.io/idrJbkwvG0/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
    ixigo: 'https://cdn.brandfetch.io/idiqOCfzCa/w/400/h/400/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B',
    ids: 'https://cdn.brandfetch.io/idgjV-R0Kq/w/1281/h/1281/theme/dark/icon.jpeg?c=1dxbfHSJFAPEGdCLU4o5B'
  };

  const fetchRateParity = async () => {
    try {
      const response = await axios.post(
        `/fetch_rate_parity`,
        {
          tenant_id: tenantId,
          property_id: propertyId,
          date: date,
          room_type: roomType,
          meal_plan: mealPlan,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        const formattedRows = response.data.map((row, index) => ({
          id: index + 1,
          ota: sites[row.ota],
          roomType: deslugify(row.roomType),
          mealPlan: row.mealPlan,
          fare: `₹ ${parseInt(row.fare).toLocaleString("en-IN")}`,
          discount: row.discount ? `₹ ${parseInt(row.discount).toLocaleString("en-IN")}` : '-',
          lastScraped: row.lastScraped,
          link: row.link,
        }));
        setRows(formattedRows);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };


  // const generateParityRows = (showSelfRoomType = false, showCompetition = false) => {
  //   let rowData = []
  //   let rowId = 1;

  //   if (masterData?.['price_info']) {
  //     Object.entries(masterData['price_info']).forEach(([key, value]) => {
  //       let dataDict = {
  //         "id": rowId++,
  //         "ota": key,
  //         "propertyName": hotelDetails?.[propertyId]?.['property_name'] ? hotelDetails[propertyId]['property_name'] : "",
  //         "roomType": masterData?.['room_type'] ? masterData['room_type'] : "",
  //         "mealPlan": masterData?.['meal_plan'] ? masterData['meal_plan'] : "",
  //         "tariff": value && 1 in value ? Math.round(value[1]) : "-",
  //         "totalTariff": value && value.length ? Math.round(value[1] + value[2]) : "-",
  //         // "discount": value && 3 in value ? Math.round(value[3]) : "-",
  //         "lastScraped": masterData?.['last_updtm']?.[key] ? masterData['last_updtm'][key] : "-",
  //         "link": getRoomURL(key, propertyId, date, hotelDetails),
  //         "isAnchor": true,
  //         "isSelf": true,
  //         "isSoldOut": masterData?.['is_sold_out'] && key in masterData['is_sold_out'] ? masterData['is_sold_out'][key] : null
  //       }
  //       rowData.push(dataDict)
  //     })
  //   }

  //   if (showSelfRoomType && masterData?.['self']) {
  //     Object.entries(masterData['self']).forEach(([key, value]) => {
  //       Object.entries(value).forEach(([rt_mp, roomData]) => {
  //         let dataDict = {
  //           "id": rowId++,
  //           "ota": key,
  //           "propertyName": hotelDetails?.[propertyId]?.['property_name'] ? hotelDetails[propertyId]['property_name'] : "",
  //           "roomType": roomData?.['room_type'] ? roomData['room_type'] : "",
  //           "mealPlan": roomData?.['meal_plan'] ? roomData['meal_plan'] : "",
  //           "tariff": roomData?.['price_info'] && 1 in roomData['price_info'] ? Math.round(roomData['price_info'][1]) : "-",
  //           // "discount": roomData?.['price_info'] && 3 in roomData['price_info'] ? Math.round(roomData['price_info'][3]) : "-",
  //           "totalTariff": roomData?.["price_info"] && roomData["price_info"].length ? Math.round(parseFloat(Math.round(roomData['price_info'][1])) + parseFloat(Math.round(roomData['price_info'][2]))) : "-",
  //           "lastScraped": roomData?.['last_updtm'] ? roomData['last_updtm'] : "-",
  //           "link": getRoomURL(key, propertyId, date, hotelDetails),
  //           "isAnchor": false,
  //           "isSelf": true,
  //           "isSoldOut": "is_sold_out" in roomData ? roomData['is_sold_out'] : null
  //         }
  //         rowData.push(dataDict)
  //       })
  //     })
  //   }

  //   if (showCompetition && masterData?.['comp']) {
  //     Object.entries(masterData['comp']).forEach(([key, value]) => {
  //       Object.entries(value).forEach(([comp_id, roomData]) => {
  //         let dataDict = {
  //           "id": rowId++,
  //           "ota": key,
  //           "propertyName": hotelDetails?.[comp_id]?.['property_name'] ? hotelDetails[comp_id]['property_name'] : "",
  //           "roomType": roomData?.['room_type'] ? roomData['room_type'] : "",
  //           "mealPlan": roomData?.['meal_plan'] ? roomData['meal_plan'] : "",
  //           "tariff": roomData?.['price_info'] && 1 in roomData['price_info'] ? Math.round(roomData['price_info'][1]) : "-",
  //           // "discount": roomData?.['price_info'] && 3 in roomData['price_info'] ? Math.round(roomData['price_info'][3]) : "-",
  //           "totalTariff": roomData?.['price_info'] && roomData['price_info'].length ? Math.round(parseFloat(Math.round(roomData['price_info'][1])) + parseFloat(Math.round(roomData['price_info'][2]))) : "-",
  //           "lastScraped": roomData?.['last_updtm'] ? roomData['last_updtm'] : "-",
  //           "link": getRoomURL(key, comp_id, date, hotelDetails),
  //           "isAnchor": false,
  //           "isSelf": false,
  //           "isSoldOut": "is_sold_out" in roomData ? roomData['is_sold_out'] : null
  //         }
  //         rowData.push(dataDict)
  //       })
  //     })
  //   }


  //   setRows(rowData)

  // }

  const generateParityRows = (showSelfRoomType = false, showCompetition = false) => {
    let rowData = []
    let rowId = 1;

    if (masterData?.['price_info']) {
      Object.entries(masterData['price_info']).forEach(([key, value]) => {
        const isSoldOut = masterData?.['is_sold_out'] && key in masterData['is_sold_out'] ? masterData['is_sold_out'][key] : null;
        let dataDict = {
          "id": rowId++,
          "ota": key,
          "propertyName": hotelDetails?.[propertyId]?.['property_name'] ? hotelDetails[propertyId]['property_name'] : "",
          "roomType": masterData?.['room_type'] ? masterData['room_type'] : "",
          "mealPlan": masterData?.['meal_plan'] ? masterData['meal_plan'] : "",
          "tariff": isSoldOut === true ? "-" : (value && 1 in value ? Math.round(value[1]) : "-"),
          "totalTariff": isSoldOut === true ? "-" : (value && value.length ? Math.round(value[1] + value[2]) : "-"),
          "lastScraped": masterData?.['last_updtm']?.[key] ? masterData['last_updtm'][key] : "-",
          "link": getRoomURL(key, propertyId, date, hotelDetails),
          "isAnchor": true,
          "isSelf": true,
          "isSoldOut": isSoldOut
        }
        rowData.push(dataDict)
      })
    }

    if (showSelfRoomType && masterData?.['self']) {
      Object.entries(masterData['self']).forEach(([key, value]) => {
        Object.entries(value).forEach(([rt_mp, roomData]) => {
          const isSoldOut = "is_sold_out" in roomData ? roomData['is_sold_out'] : null;
          let dataDict = {
            "id": rowId++,
            "ota": key,
            "propertyName": hotelDetails?.[propertyId]?.['property_name'] ? hotelDetails[propertyId]['property_name'] : "",
            "roomType": roomData?.['room_type'] ? roomData['room_type'] : "",
            "mealPlan": roomData?.['meal_plan'] ? roomData['meal_plan'] : "",
            "tariff": isSoldOut === true ? "-" : (roomData?.['price_info'] && 1 in roomData['price_info'] ? Math.round(roomData['price_info'][1]) : "-"),
            "totalTariff": isSoldOut === true ? "-" : (roomData?.["price_info"] && roomData["price_info"].length ? Math.round(parseFloat(Math.round(roomData['price_info'][1])) + parseFloat(Math.round(roomData['price_info'][2]))) : "-"),
            "lastScraped": roomData?.['last_updtm'] ? roomData['last_updtm'] : "-",
            "link": getRoomURL(key, propertyId, date, hotelDetails),
            "isAnchor": false,
            "isSelf": true,
            "isSoldOut": isSoldOut
          }
          rowData.push(dataDict)
        })
      })
    }

    if (showCompetition && masterData?.['comp']) {
      Object.entries(masterData['comp']).forEach(([key, value]) => {
        Object.entries(value).forEach(([comp_id, roomData]) => {
          const isSoldOut = "is_sold_out" in roomData ? roomData['is_sold_out'] : null;
          let dataDict = {
            "id": rowId++,
            "ota": key,
            "propertyName": hotelDetails?.[comp_id]?.['property_name'] ? hotelDetails[comp_id]['property_name'] : "",
            "roomType": roomData?.['room_type'] ? roomData['room_type'] : "",
            "mealPlan": roomData?.['meal_plan'] ? roomData['meal_plan'] : "",
            "tariff": isSoldOut === true ? "-" : (roomData?.['price_info'] && 1 in roomData['price_info'] ? Math.round(roomData['price_info'][1]) : "-"),
            "totalTariff": isSoldOut === true ? "-" : (roomData?.['price_info'] && roomData['price_info'].length ? Math.round(parseFloat(Math.round(roomData['price_info'][1])) + parseFloat(Math.round(roomData['price_info'][2]))) : "-"),
            "lastScraped": roomData?.['last_updtm'] ? roomData['last_updtm'] : "-",
            "link": getRoomURL(key, comp_id, date, hotelDetails),
            "isAnchor": false,
            "isSelf": false,
            "isSoldOut": isSoldOut
          }
          rowData.push(dataDict)
        })
      })
    }

    setRows(rowData)
  }
  useEffect(() => {
    generateParityRows();
  }, [date, masterData]);


  const columns = [
    // { field: "propertyName", headerName: "Property Name", flex: 1 },
    {
      field: "ota", headerName: "OTA", flex: 1,
      renderCell: (params) => {
        if (otaImages?.[params.row.ota]) {
          return (
            <div className="d-flex align-items-center">
              <img src={otaImages[params.row.ota]} alt="" style={{ width: "20px", height: "20px", marginRight: "10px", borderRadius: '50%' }} />
              <p className="m-0">{sites?.[params.row.ota] ? sites[params.row.ota] : params.row.ota}</p>
            </div>
          );
        }
        return (
          <div className="d-flex align-items-center">
            <p className="m-0">{sites?.[params.row.ota] ? sites[params.row.ota] : params.row.ota}</p>
          </div>
        )

      },

    },
    { field: "roomType", headerName: "Room Type", flex: 1 },
    { field: "mealPlan", headerName: "Meal Plan", flex: 1 },
    {
      field: "tariff", headerName: "Tariff", flex: 1,
      renderCell: (params) =>
        params.row.tariff === "-"
          ? "-"
          : `₹ ${parseInt(params.row.tariff).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
    },
    // {
    //   field: "discount", headerName: "Discount", flex: 1,

    //   renderCell: (params) =>
    //     params.row.discount === "-"
    //       ? "-"
    //       : `₹ ${parseInt(params.row.discount).toLocaleString("en-IN")}`,

    //   sortComparator: (v1, v2) => {
    //     if (v1 === "-" && v2 === "-") return 0;
    //     if (v1 === "-") return -1;
    //     if (v2 === "-") return 1;
    //     return parseInt(v1) - parseInt(v2);
    //   },
    // },
    {
      field: "totalTariff", headerName: "Total Tariff", flex: 1,

      renderCell: (params) =>
        params.row.totalTariff === "-"
          ? "-"
          : `₹ ${parseInt(params.row.totalTariff).toLocaleString("en-IN")}`,

      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
    },
    {
      field: "isSoldOut", headerName: "Status", flex: 1,

      renderCell: (params) =>
        params.row.isSoldOut === null
          ? "-"
          : params.row.isSoldOut ? "Sold Out" : "Available",


    },

    {
      field: "lastScraped", headerName: "Refresh Date", flex: 1,
      renderCell: (params) => {
        const [date, time_tag] = formatDate(params.row.lastScraped)?.split("@") || ["-", ""]
        return (

          <div title={`${date}at${time_tag}`}>
            <p className='mb-0'>{date}</p>
            <small className='text-muted small'>at {time_tag}</small>
          </div>
        );
      },
    },
    {
      field: "link",
      headerName: "View on OTA",
      type: "actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value ? (

          <OpenInNewIcon onClick={() => { openUrlInIncognito(params.value) }} sx={{ color: "#0d6efd", cursor: "pointer" }} />

        ) : (
          <OpenInNewIcon sx={{ color: "grey" }} />
        ),
    },
  ];

  return (
    <>
      <div className="rate-parity-table-container">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5]}
          disableSelectionOnClick
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      </div>
    </>
  );
};

export default RateParity;
