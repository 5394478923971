import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { fetchToken } from "../../../Auth";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { toast } from "react-toastify";
import { openUrlInIncognito, getRoomURL, formatDate } from "../../../services/helper"
const FocusComp = ({
  tenantId,
  propertyId,
  roomType,
  mealPlan,
  ota,
  selfDataSource,
  date,
  baseFare,
  masterData,
  hotelDetails,
  mealPlanNames
}) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selfRow, setSelfRow] = useState({});

  const getNonFCData = async (selfData, fcRows, compIds) => {
    try {
      const response = await axios.post(
        `/fetch_non_fc_comp`,
        {
          propertyId: propertyId,
          roomType: roomType,
          mealPlan: mealPlan,
          ota: ota,
          date: date,
          compIds: compIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setSelfRow(selfData);
      const formattedRows = [...fcRows, ...response.data].map((res, index) => ({
        ...res,
        id: index + 1,
      }));

      setRows(formattedRows);
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        `/fetch_fc_details/${tenantId}/${propertyId}/${roomType}/${mealPlan}/${ota}/${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );

      // getNonFCData(
      //   response.data.self_data,
      //   response.data.comp_data,
      //   response.data.comp_ids
      // );
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };


  const getHotelName = (property_id) => {
    let hotelName = null
    try {
      if (hotelDetails?.[property_id]?.['property_name']) {
        return hotelDetails[property_id]['property_name']
      }
    } catch (error) {
      console.error("Error in getting hotel name", error)
    }
    return hotelName
  }



  const columns = [
    // {
    //   sortable: false,
    //   headerName: "",
    //   width: 15,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.is_equ ? (
    //           <Tooltip title="Lowest available room">
    //             <AutoFixHighIcon
    //               style={{ color: "orange", fontSize: "1.5em" }}
    //             />
    //           </Tooltip>
    //         ) : null}
    //       </>
    //     );
    //   },
    // },
    {
      field: `${ota}_name`,
      headerName: "Hotel Name",
      flex: 2.5,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column w-100">
            <div>Hotel Name</div>
            <hr style={{ margin: 0 }} />
            <div
              title={getHotelName(propertyId)}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {/* {selfRow[`${ota}_name`]} */}
              {getHotelName(propertyId)}
            </div>
          </div>
        </>
      ),
      renderCell: (params) => (
        <div
          title={params.row[`${ota}_name`]}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%"  // Important for cell content
          }}
        >
          {params.row[`${ota}_name`]}
        </div>
      )
    },
    {
      field: "comp_mapped_room_type",
      headerName: "Room Type",
      flex: 1,
      renderHeader: () => (
        <>
          <div
            title={masterData.room_type ? masterData.room_type : "-"}
            className="d-flex flex-column"
          >
            <div>Room Type</div>
            <hr style={{ margin: 0 }} />
            <div className={masterData.room_type ? masterData.room_type : ""}>
              {masterData.room_type}
            </div>
          </div>
        </>
      ),
    },
    {
      field: "comp_mapped_meal_plan",
      headerName: "Meal Plan",
      flex: 1,
      sortable: true,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column" title={mealPlanNames && masterData && masterData.mealPlan && mealPlanNames?.[masterData.meal_plan] && mealPlanNames[masterData.meal_plan]}>
            <div>Meal Plan</div>
            <hr style={{ margin: 0 }} />
            <div>{masterData.meal_plan}</div>
          </div>
        </>
      ),
      renderCell: (params) => {
        return (
          <span title={params.row.comp_mapped_meal_plan_name}>
            {params.row.comp_mapped_meal_plan}
          </span>
        );
      },

    },
    {
      field: "base_fare",
      headerName: "Fare",
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        params.row.base_fare === "-"
          ? "-"
          : `₹ ${parseInt(params.row.base_fare).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Tariff</div>
            <hr style={{ margin: 0 }} />

            <div
              title={
                !(masterData?.["is_sold_out"]?.[selfDataSource]) && masterData?.["price_info"]?.[selfDataSource] && masterData["price_info"][selfDataSource].length ? `₹ ${Math.round(masterData["price_info"][selfDataSource][1]).toLocaleString("en-IN")}` : "-"
              }
            >
              {
                !(masterData?.["is_sold_out"]?.[selfDataSource]) && masterData?.["price_info"]?.[selfDataSource] && masterData["price_info"][selfDataSource].length ? `₹ ${Math.round(masterData["price_info"][selfDataSource][1]).toLocaleString("en-IN")}` : "-"
              }
            </div>
          </div>
        </>
      ),
    },
    {
      field: "base_fare_with_fees",
      headerName: "Total Fare",
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        params.row.base_fare_with_fees === "-"
          ? "-"
          : `₹ ${parseInt(params.row.base_fare_with_fees).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Total Tariff</div>
            <hr style={{ margin: 0 }} />

            <div
              title={
                !(masterData?.["is_sold_out"]?.[selfDataSource]) && masterData?.["price_info"]?.[selfDataSource] && masterData["price_info"][selfDataSource].length ? `₹ ${Math.round(parseFloat(masterData["price_info"][selfDataSource][1]) + parseFloat(masterData["price_info"][selfDataSource][2])).toLocaleString("en-IN")}` : "-"
              }
            >
              {
                !(masterData?.["is_sold_out"]?.[selfDataSource]) && masterData?.["price_info"]?.[selfDataSource] && masterData["price_info"][selfDataSource].length ? `₹ ${Math.round(parseFloat(masterData["price_info"][selfDataSource][1]) + parseFloat(masterData["price_info"][selfDataSource][2])).toLocaleString("en-IN")}` : "-"
              }
            </div>
          </div>
        </>
      ),
    },
    {
      field: "parity_price",
      headerName: "Parity Fare",
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        params.row.parity_price === "-"
          ? "-"
          : `₹ ${parseInt(params.row.parity_price).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Parity Tariff</div>
            <hr style={{ margin: 0 }} />

            <div
              title={
                masterData.parity_price
                  ? masterData.parity_price === "-"
                    ? "-"
                    : `₹ ${parseInt(masterData.parity_price).toLocaleString("en-IN")}`
                  : "-"
              }
            >
              {masterData.parity_price
                ? masterData.parity_price === "-"
                  ? "-"
                  : `₹ ${parseInt(masterData.parity_price).toLocaleString("en-IN")}`
                : "-"}
            </div>
          </div>
        </>
      ),
    },
    // {
    //   field: "discount",
    //   headerName: "Discount",
    //   sortable: false,
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params) =>
    //     params.row.discount === "-"
    //       ? "-"
    //       : `₹ ${parseInt(params.row.discount).toLocaleString("en-IN")}`,
    //   sortComparator: (v1, v2) => {
    //     if (v1 === "-" && v2 === "-") return 0;
    //     if (v1 === "-") return -1;
    //     if (v2 === "-") return 1;
    //     return parseInt(v1) - parseInt(v2);
    //   },
    //   renderHeader: () => (
    //     <>
    //       <div className="d-flex flex-column">
    //         <div>Discount</div>
    //         <hr style={{ margin: 0 }} />
    //         <div title={
    //           masterData?.["price_info"]?.[ota] ? `₹ ${Math.round(parseFloat(masterData["price_info"][ota][3])).toLocaleString("en-IN")}` : "-"
    //         }
    //         >
    //           {
    //             masterData?.["price_info"]?.[ota] ? `₹ ${Math.round(parseFloat(masterData["price_info"][ota][3])).toLocaleString("en-IN")}` : "-"
    //           }
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      field: "is_sold_out",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Status</div>
            <hr style={{ margin: 0 }} />
            <div
              title={
                masterData?.["is_sold_out"] && selfDataSource in masterData['is_sold_out'] ? masterData['is_sold_out'][selfDataSource] ? "Sold Out" : "Available" : "-"
              }
            >
              {
                masterData?.["is_sold_out"] && selfDataSource in masterData['is_sold_out'] ? masterData['is_sold_out'][selfDataSource] ? "Sold Out" : "Available" : "-"
              }
            </div>
          </div>
        </>
      ),
    },
    {
      field: "scrape_date",
      headerName: "Refresh Date",
      flex: 1.2,
      renderHeader: () => {
        const [date, time_tag] = masterData.last_updtm && selfDataSource in masterData.last_updtm ?
          formatDate(masterData.last_updtm[selfDataSource])?.split("@") || ["-", ""] :
          ["-", ""];

        return (
          <>
            <div className="d-flex flex-column">
              <div>Refresh Date</div>
              <hr style={{ margin: 0 }} />
              {date && time_tag ?
                (<div title={`${date} at ${time_tag}`}>
                  <p className='m-0' style={{ "height": "1rem" }}>{date}</p>
                  <small className='text-muted small'>{time_tag && `at ${time_tag}`}</small>
                </div>) : (<div>-</div>)
              }

            </div>
          </>
        )
      },
      renderCell: (params) => {
        const [date, time_tag] = formatDate(params.row.scrape_date)?.split("@") || ["-", ""]
        return (

          <div title={`${date}at${time_tag}`}>
            <p className='mb-0'>{date}</p>
            <small className='text-muted small'>at {time_tag}</small>
          </div>
        );
      },
    },
    {
      field: "is_fc",
      type: "actions",
      headerName: "Is Critical FC",
      flex: 1,
      cellClassName: "is_fc",
      getActions: ({ row }) => {
        return [
          <Switch
            checked={row.is_fc}
            className={"is_self" in row && row.is_self ? "d-none" : ""}
            size="small"
            disabled
          />,
        ];
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Is Focused</div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-center">-</div>
          </div>
        </>
      ),
    },
    {
      field: "room_url",
      type: "actions",
      headerName: "View on OTA",
      flex: 1,
      cellClassName: "room_url",
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>View On OTA</div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-center mt-3">
              {getRoomURL(ota, propertyId, date, hotelDetails) ? (

                <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(getRoomURL(ota, propertyId, date, hotelDetails)) }} />

              ) : (
                <OpenInNewIcon sx={{ color: "grey" }} />
              )}
            </div>
          </div>
        </>
      ),
      renderCell: (params) =>
        params.value ? (
          <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(params.value) }} />
        ) : (
          <OpenInNewIcon sx={{ color: "grey" }} />
        ),
    },
  ];

  useEffect(() => {
    if (ota && date && tenantId && propertyId && roomType && mealPlan) {
      // getData();
    }
  }, [ota, date, tenantId, propertyId, roomType, mealPlan]);

  const generateCompRows = (comp) => {
    let dataList = []
    Object.entries(comp).forEach(([key, value]) => {
      try {
        const data = {
          id: key,
          [ota + "_name"]: getHotelName(key),
          comp_mapped_room_type: value?.['room_type'] ? value['room_type'] : "-",
          comp_mapped_meal_plan: value?.['meal_plan'] ? value['meal_plan'] : "-",
          comp_mapped_meal_plan_name: value?.['meal_plan'] ? mealPlanNames?.[value['meal_plan']] ? mealPlanNames[value['meal_plan']] : "-" : "-",
          base_fare: value?.['price_info'] && 1 in value['price_info'] ? Math.round(value['price_info'][1]) : "-",
          base_fare_with_fees: value?.['price_info'] && value['price_info'].length ? (parseFloat(value['price_info'][1]) + parseFloat(value['price_info'][2])) : "-",
          discount: value?.['price_info'] ? Math.round(parseFloat(value['price_info'][3])).toLocaleString("en-IN") : "-",
          is_sold_out: "is_sold_out" in value ? value['is_sold_out'] ? "Sold Out" : "Available" : "-",
          scrape_date: value?.['last_updtm'] ? value.last_updtm : null,
          is_fc: hotelDetails?.[key]?.['is_focused'] ? hotelDetails[key]['is_focused'] ? true : false : false,
          is_self: false,
          is_equ: false, //! this will be handled when the equivalent room logic is finalized
          room_url: getRoomURL(ota, key, date, hotelDetails),
          parity_price: value?.['parity_price'] ? Math.round(value['parity_price']) : "-"
        }
        dataList.push(data)
      } catch (error) {
        console.error("Error in iteration of generateCompRows", error)
      }
    });

    return dataList
  }

  return (
    <div className="focus-comp-table-container">
      <DataGrid
        rows={
          masterData?.['comp']?.[ota] ? generateCompRows(masterData['comp'][ota]) : []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        disableColumnMenu
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
        }
      />
    </div>
  );
};

export default FocusComp;
