import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { useAppContext } from '../../../context/AppContext';
import {fetchToken,deleteToken} from '../../../Auth';
import axios from 'axios';

import { useSessionTracker } from "../../../context/SessionTrackerContext";
import useUserStore from "../../../context/UserContext";

const BaseLayout = ({children, background}) => {
  const navigate = useNavigate()
  const { sendAllUsageData } = useSessionTracker(); 
  const {
		userSignOut
	} = useUserStore();


  const [user, setUser] = useState({});
  const [staff, setStaff] = useState(false);
  const [assignedClients, setAssignedClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);


  const { currUser, setCurrUser, setCount, setUsers, setConfig } = useAppContext();

  const getConfig = async () => {
      try{
          const response = await axios.get("/fetch_configurations", {
              headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
              },
          });
          if(response.status == 200 && response.data !== undefined){
              setConfig(response.data);
          }
      } catch (err) {
        console.error("Error", err);
      }
  }

  const handleLogout = async () => {
    // Trigger all session trackers
    await sendAllUsageData();

    // Proceed with logout logic
    console.log("Logging out...");
    localStorage.removeItem("zetta_access_token");
    				userSignOut()
				deleteToken()
		// 		window.location.replace("/login");

    navigate("/login");
  };

  useEffect(() => {
    getConfig();
  }, [])

//   useEffect(() => {
        
//     var cookie = `token=${fetchToken('zetta_access_token')}`
//     var conn = "ws://" + '43.205.96.154:8000' + `/connect_user?${cookie}`
//     var ws = new WebSocket(conn);

//     ws.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       if (data.type === "users_count") {
//         setCount(data.count)
//         setUsers(data.users)
//         // setLiveUserCount(data.count);
//         // setConnectedUsers(data.users)
//       }
//     };

//     return () => {
//       ws.close();
//     };
// }, [])

useEffect(() => {
  if(user){
    setCurrUser(user)
  }
}, [user])

  // useEffect(() => {
  //   if(selectedClientId){
  //     navigate('/home')
  //   }
  // }, [selectedClientId])

  return(
    <>
      <Sidebar user={user} setUser={setUser} setStaff={setStaff} setAssignedClients={setAssignedClients} setSelectedClientId={setSelectedClientId}/>
      <section class="home-section">
        <Navbar onLogout={handleLogout} name={currUser['first_name']} staff={staff} clients={assignedClients} clientId={selectedClientId} setClientId={setSelectedClientId}/>
        {/* <Container>
        </Container> */}
        {children}

        <ToastContainer />
      </section>
    </>
  );
};

export default BaseLayout;