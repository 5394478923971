import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Rating from "@mui/material/Rating";
import axios from "axios";
import { toast } from "react-toastify";
import { fetchToken } from "../../Auth";
import Map from "../../components/common/Map/Map";
import PriceHistoryChart from "./PriceHistoryChart"
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Chip from "@mui/material/Chip";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const FocusCompetition = ({
    focusComp,
    setFocusComp,
    selectedHotel,
    clientProperties,
    setClientProperties,
    preferredOTA,
    propertyIndex,
    selectedCity,
    mealPlans,
    roomTypes,
    hotelName,
}) => {

    const navigate = useNavigate();

    const showToast = (message, type = "error") => {
        toast(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE[type.toUpperCase()] || toast.TYPE.ERROR,
        });
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "#fff",
            borderColor: "#9e9e9e",
            minHeight: "38px",
            height: "auto",
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            maxHeight: "30px",
            overflowY: "auto",
            padding: "0 6px",
        }),
        input: (provided, state) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "30px",
        }),
    };

    const [hotels, setHotels] = useState([]);
    const [radius, setRadius] = useState(5);
    const [selectedComps, setSelectedComps] = useState([]);
    const [hotelSettings, setHotelSettings] = useState({});

    const distances = [
        { value: 5, label: "5km" },
        { value: 10, label: "10km" },
        { value: 15, label: "15km" },
        { value: 20, label: "20km" },
        { value: 25, label: "25km" },
        { value: 30, label: "30km" },
    ];
    const timeWindows = [
        { label: "0-3 Days", key: "0-3" },
        { label: "4-8 Days", key: "4-8" },
        { label: "9-15 Days", key: "9-15" },
        { label: "16-30 Days", key: "16-30" },
        { label: "30+ Days", key: "31-365" },
    ];

    function valuetext(value) {
        return `${value}km`;
    }

    const getHotels = async () => {
        try {
            const response = await axios.get(
                // `/get_recommended_fc/${preferredOTA.value}/${selectedHotel["value"]}/${selectedHotel["city"]}/${selectedHotel["latitude"]}/${selectedHotel["longitude"]}/${radius}`,
                `/get_recommended_fc_v3/${preferredOTA.value}/${selectedHotel["value"]}/${selectedHotel["city"]}/${selectedHotel["latitude"]}/${selectedHotel["longitude"]}/${radius}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                    },
                }
            );
            if (response.status === 200 && response.data !== undefined) {
                setHotels(response.data);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate("/login");
            }
        }
    };

    useEffect(() => {
        if (selectedHotel && selectedHotel['latitude'] !== undefined && selectedHotel['longitude'] !== undefined && radius) {
            // console.log("here")
            getHotels();
        }
    }, [radius]);

    const handleSelectedCompChange = () => {
        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];

            const selectedHotelsWithData = selectedComps
                .map((selectedComp) => {
                    const hotelData = hotels.find(
                        (hotel) => hotel.hotel_id === selectedComp.value
                    );
                    if (!hotelData) return null;

                    const existingComp = cityProperties[propertyIndex].comp?.find(
                        comp => comp.hotel_id === hotelData.hotel_id
                    );

                    return {
                        hotel_id: hotelData.hotel_id,
                        hotel_name: hotelData.hotel_name,
                        star: hotelData.star,
                        rating: hotelData.rating,
                        latitude: hotelData.latitude,
                        longitude: hotelData.longitude,
                        roomType: existingComp?.roomType || null,
                        mealPlan: existingComp?.mealPlan || null,
                        parity_bins: existingComp?.parity_bins || {
                            "0-3": { m: 1, c: 0 },
                            "4-8": { m: 1, c: 0 },
                            "9-15": { m: 1, c: 0 },
                            "16-30": { m: 1, c: 0 },
                            "31-365": { m: 1, c: 0 }
                        },
                        property_id: hotelData.property_id,
                    };
                })
                .filter(Boolean);

            cityProperties[propertyIndex] = {
                ...cityProperties[propertyIndex],
                comp: selectedHotelsWithData,
            };

            updatedData[selectedCity.value] = {
                ...updatedData[selectedCity.value],
                properties: cityProperties,
            };
            return updatedData;
        });
    };

    useEffect(() => {
        if (selectedCity?.value && propertyIndex !== undefined && hotels && selectedComps.length) {
            // console.log("calling handleSelectedCompChange")
            handleSelectedCompChange();
        }
    }, [selectedComps]);

    useEffect(() => {
        if (
            selectedCity?.value &&
            clientProperties[selectedCity.value]?.properties[propertyIndex]?.comp &&
            hotels.length > 0  // Add this check
        ) {
            const existingComps =
                clientProperties[selectedCity.value].properties[propertyIndex].comp;
            setSelectedComps(
                existingComps.map((comp) => ({
                    label: comp.hotel_name,
                    value: comp.hotel_id,
                }))
            );

            const initialSettings = {};
            existingComps.forEach((comp) => {
                initialSettings[comp.hotel_id] = {
                    roomType: comp.roomType || null,
                    mealPlan: comp.mealPlan || null,
                    parity_bins: comp.parity_bins || {
                        "0-3": { m: 1, c: 0 },
                        "4-8": { m: 1, c: 0 },
                        "9-15": { m: 1, c: 0 },
                        "16-30": { m: 1, c: 0 },
                        "31-365": { m: 1, c: 0 }
                    }
                };
            });
            setHotelSettings(initialSettings);
        }
    }, [hotels]);

    const handleRemoveHotel = (hotelId) => {
        setSelectedComps((prev) => prev.filter((comp) => comp.value !== hotelId));
        setHotelSettings((prev) => {
            const newSettings = { ...prev };
            delete newSettings[hotelId];
            return newSettings;
        });

        setClientProperties(prev => {
            const newProperties = { ...prev };
            const cityProperties = newProperties[selectedCity.value].properties;

            // Filter out the hotel from the comp array
            cityProperties[propertyIndex].comp = cityProperties[propertyIndex].comp.filter(
                hotel => hotel.hotel_id !== hotelId
            );

            return newProperties;
        });
    };

    const handleSettingChange = (hotelId, field, value) => {
        setClientProperties(prev => {
            const newProperties = { ...prev };
            const hotel = newProperties[selectedCity.value].properties[propertyIndex].comp
                .find(h => h.hotel_id === hotelId);

            if (hotel) {
                hotel[field] = value;
            }

            return newProperties;
        });
    };

    const handleParityBinChange = (hotelId, timeWindow, field, value) => {
        let parsedValue = value === '' || value === null ? null : parseFloat(value);

        if (parsedValue !== null) {
            if (field === 'm') {
                parsedValue = Math.max(0.25, Math.min(5, parsedValue));
            } else if (field === 'c') {
                parsedValue = Math.max(-5000, Math.min(5000, parsedValue));
            }
        }

        setClientProperties(prev => {
            const newProperties = { ...prev };
            const hotel = newProperties[selectedCity.value].properties[propertyIndex].comp
                .find(h => h.hotel_id === hotelId);

            if (hotel) {
                if (!hotel.parity_bins) {
                    hotel.parity_bins = {};
                }
                if (!hotel.parity_bins[timeWindow]) {
                    hotel.parity_bins[timeWindow] = {};
                }
                hotel.parity_bins[timeWindow][field] = parsedValue;
            }

            return newProperties;
        });
    };


    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Earth's radius in km
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return (R * c).toFixed(1);
    };
    function getRatingColor(rating) {
        if (rating >= 4.0 && rating <= 5.0) {
            return "#4CAF50"; // Green
        } else if (rating >= 3.0 && rating <= 3.9) {
            return "#FFEB3B"; // Yellow
        } else if (rating >= 2.0 && rating <= 2.9) {
            return "#FF9800"; // Orange
        } else if (rating >= 1.0 && rating <= 1.9) {
            return "#F44336"; // Red
        } else if (rating >= 0.0 && rating <= 0.9) {
            return "#D32F2F"; // Dark Red
        }
        return "#000000"; // Default to black if rating is invalid
    }

    return (
        <div className="mt-4 mb-4">
            <h5 className="bold text-orange">Focus Competitions</h5>
            <Box sx={{ width: "100%" }}>
                <div className="row">
                    <div className="col-8"></div>
                    <div className="col-4">
                        <Slider
                            aria-label="Always visible"
                            defaultValue={5}
                            getAriaValueText={valuetext}
                            step={5}
                            min={5}
                            max={30}
                            marks={distances}
                            valueLabelDisplay="on"
                            onChange={(evt) => setRadius(evt.target.value)}
                        />
                    </div>
                </div>

                <Map
                    radius={radius}
                    hotels={hotels}
                    lat={parseFloat(selectedHotel["latitude"])}
                    long={parseFloat(selectedHotel["longitude"])}
                    hotelName={selectedHotel['hotel_name']}
                    clientProperties={clientProperties}
                    propertyIndex={propertyIndex}
                    selectedCity={selectedCity}
                />

                <div className="mt-4 row">
                    <div className="col-4">
                        <label htmlFor="">Add Competition</label>
                    </div>
                    <div className="col-8">
                        <MultiSelect
                            labelledBy="Choose Competition Hotels"
                            placeholder="Choose Competition Hotels"
                            options={hotels.map((hotel) => ({
                                label: hotel.hotel_name,
                                value: hotel.hotel_id,
                            }))}
                            value={selectedComps}
                            onChange={(selectedOptions) => {
                                if (selectedOptions.length <= 10) {
                                    setSelectedComps(selectedOptions);
                                } else {
                                    showToast(`You can only select up to ${10} hotels.`);
                                }
                            }}
                            className="multi-select"
                            closeMenuOnSelect={false}
                            isMulti
                            hasSelectAll={false}
                            overrideStrings={{
                                selectSomeItems: "Choose Competition Hotels",
                            }}
                        />
                    </div>
                </div>
                <div className="mt-4 row">
                    {selectedCity?.value &&
                        clientProperties[selectedCity.value]?.properties[propertyIndex]
                            ?.comp &&
                        clientProperties[selectedCity.value].properties[
                            propertyIndex
                        ].comp.map((hotel) => (
                            <div
                                className="col-3 bordered mx-3 my-2"
                                style={{ position: "relative" }}
                                key={hotel.hotel_id}
                            >
                                <div
                                    className="row mt-3"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <span title={hotel.hotel_name} className="w-60 bold" style={{
                                        maxWidth: "200px", // Adjust the width as needed
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "inline-block",
                                    }}>{hotel.hotel_name}</span>
                                    {hotel.rating && (
                                        <Chip
                                            size="small"
                                            icon={<StarIcon sx={{ fontSize: 16, fill: "#fff" }} />}
                                            label={hotel.rating}
                                            style={{
                                                backgroundColor: getRatingColor(hotel.rating),
                                                color: "#fff",
                                            }}
                                            className="w-20"
                                        />
                                    )}

                                    <CloseIcon
                                        className="close-icon w-20"
                                        sx={{
                                            color: "black",
                                            position: "absolute",
                                            top: "15px",
                                            right: "0px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleRemoveHotel(hotel.hotel_id)}
                                    />
                                </div>
                                <div className="row mt-3">
                                    <Rating
                                        name={`rating-${hotel.hotel_id}`}
                                        value={hotel.star}
                                        readOnly
                                    />
                                </div>
                                <div className="row mt-2">
                                    {selectedHotel?.latitude &&
                                        selectedHotel?.longitude &&
                                        hotel?.latitude &&
                                        hotel?.longitude &&
                                        hotelName && (
                                            <span>
                                                {calculateDistance(
                                                    selectedHotel.latitude,
                                                    selectedHotel.longitude,
                                                    hotel.latitude,
                                                    hotel.longitude
                                                )}{" "}
                                                Km from {hotelName}
                                            </span>
                                        )}
                                </div>
                                <div className="row mt-2">
                                    <hr />
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="" className="mt-2">
                                            Base Room{" "}
                                            <Tooltip title="Lowest priced room" placement="top">
                                                <InfoIcon size="small" />
                                            </Tooltip>
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <Select
                                            placeholder="Select Base Room"
                                            options={roomTypes}
                                            // value={roomTypes.find(
                                            //     (rt) => rt.value === hotel.roomType
                                            // )}
                                            value={hotel.roomType || null}
                                            onChange={(selected) =>
                                                handleSettingChange(
                                                    hotel.hotel_id,
                                                    "roomType",
                                                    selected
                                                )
                                            }
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-2 mb-3">
                                    <div className="col-4">
                                        <label htmlFor="" className="mt-2">
                                            Meal Plan
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <Select
                                            placeholder="Select Meal Plan"
                                            options={mealPlans}
                                            // value={mealPlans.find(
                                            //     (mp) => mp.value === hotel.mealPlan
                                            // )}
                                            value={hotel.mealPlan || null}
                                            onChange={(selected) =>
                                                handleSettingChange(
                                                    hotel.hotel_id,
                                                    "mealPlan",
                                                    selected
                                                )
                                            }
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                {selectedCity?.value &&
                    clientProperties[selectedCity.value]?.properties[propertyIndex]?.comp?.length > 0 && (
                        <div className="mt-3">
                            <p className='bold mt-3 text-orange'>Competition Multiplier & Offset
                                <Tooltip title="The offset can range from -5000 to 5000 and the multiplier from 0.25 to 5.">
                                    <IconButton aria-label="info about offset and multiplier">
                                        <InfoIcon style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                            </p>

                            <table className="w-100 border-collapse border">
                                <thead>
                                    <tr>
                                        <th className="p-3 col-2 border">Hotels</th>
                                        {timeWindows.map(window => (
                                            <th key={window.key} className="p-3 col-2 border text-center">
                                                {window.label}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientProperties[selectedCity.value].properties[propertyIndex].comp.map(hotel => (
                                        <tr key={hotel.hotel_id}>
                                            <td className="p-3 border bold">{hotel.hotel_name}</td>
                                            {timeWindows.map(window => (
                                                <td key={`${hotel.hotel_id}-${window.key}`} className="p-3 border">
                                                    <div className="d-flex align-items-center px-2 my-2">
                                                        <span className="mr-2 col-6">Multiplier</span>
                                                        <input
                                                            type="number"
                                                            className="w-40 h-6 px-1 border rounded text-sm"
                                                            value={hotel.parity_bins?.[window.key]?.m ?? null}

                                                            onChange={(e) => handleParityBinChange(hotel.hotel_id, window.key, 'm', e.target.value)}
                                                            min={0.25}
                                                            max={5}
                                                            step={0.01}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center px-2">
                                                        <span className="mr-2 col-6">Offset</span>
                                                        <input
                                                            type="number"
                                                            className="w-40 h-6 px-1 border rounded text-sm"
                                                            value={hotel.parity_bins?.[window.key]?.c ?? null}
                                                            onChange={(e) => handleParityBinChange(hotel.hotel_id, window.key, 'c', e.target.value)}
                                                            min={-5000}
                                                            max={5000}
                                                            step={100}
                                                        />
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                {selectedCity?.value &&
                    clientProperties[selectedCity.value]?.properties[propertyIndex]?.comp?.length > 0 && (
                        <>
                            <p className='bold mt-3 text-orange'>Competition Price History</p>
                            <PriceHistoryChart
                                hotels={hotels}
                                selectedComps={selectedComps}
                            />
                        </>
                    )}

            </Box>
        </div>
    );
};

export default FocusCompetition;
