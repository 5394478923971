import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import Table from '../../components/common/Table/Table';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth';
import auth from '../../assets/img/auth.png';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const Mappings = () => {
    const dashboardId = useUserStore((state) => state.dashboardId);
    const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
    useEffect(() => {
      if (!dashboardId) {
        fetchDashboardId(); 
      }
    }, [dashboardId, fetchDashboardId]);

    const [mappings, setMappings] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState('')
    const [password, setPassword] = useState('');
    const [cols, setCols] = useState([
        { field: 'id', headerName: "Hotel ID", headerClassName: 'bg-grey-3', flex: 1,
        renderCell: (params) => (
            <span className='text-orange bold'>{params.row.id}</span>
        ) },
        { field: 'emt_id', headerName: "EaseMyTrip", headerClassName: 'bg-grey-3', flex: 1,
        renderCell: (params) => (
            <div>
                <p className='mb-0'>{params.row.emt_name}</p>
                <p className='small text-muted mb-0'>{params.row.emt_id}</p>
            </div>
        ) },
        { field: 'agoda_id', headerName: "Agoda", headerClassName: 'bg-grey-3', flex: 1,
        renderCell: (params) => (
            <div>
                <p className='mb-0'>{params.row.agoda_name}</p>
                <p className='small text-muted mb-0'>{params.row.agoda_id}</p>
            </div>
        ) },
        { field: 'booking_id', headerName: "Booking.com", headerClassName: 'bg-grey-3', flex: 1,
        renderCell: (params) => (
            <div>
                <p className='mb-0'>{params.row.booking_name}</p>
                <p className='small text-muted mb-0'>{params.row.booking_id}</p>
            </div>
        ) },
        { field: 'delete', headerName: "Delete", headerClassName: 'bg-grey-3', flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
            <IconButton onClick={() => handleDelete(`${params.row.emt_id}#${params.row.agoda_id}#${params.row.booking_id}`)} size="small" aria-label="delete">
                <DeleteIcon />
            </IconButton>
        )},
    ])

    const navigate = useNavigate();

    const handleDelete = (id) => {
        setSelectedForDelete(id)
        setOpen(true)
    }

    const deleteMapping = async () => {
        let emt_id = (selectedForDelete.split('#'))[0]
        let agoda_id = (selectedForDelete.split('#'))[1]
        let booking_id = (selectedForDelete.split('#'))[2]
        try {
            const response = await axios.post("/delete_hotel_mapping", {password, emt_id, agoda_id, booking_id}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200) {
                setMappings(mappings.filter(item => item.emt_id !== emt_id && item.agoda_id !== agoda_id && item.booking_id !== booking_id))
                setOpen2(false);
                setSelectedForDelete('')
                toast.success('Mapping deleted successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.SUCCESS
                });
            }
        } catch (error) {
            console.error("Error", error);
            if (error.response.status === 401) {
                setOpen2(false);
                toast.error('Invalid credentials!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                });
            }
        }   
    }

    const getHotelMappings = async () => {
        try {
            const response = await axios.get("/get_hotel_mappings", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status == 200) {
                setMappings(response.data)
            }
        } catch (error) {
            console.error("Error", error);
            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        getHotelMappings()
    }, [])


    return (
        <>
            <DashboardSessionTracker dashboardId={33}> </DashboardSessionTracker>
            <Grow
                in={true}
                {...(true ? { timeout: 500 } : {})}
            >
                <div className='px-4 py-2 mt-3 white-card'>
                    <div className="mt-3">
                        <div className='bg-grey p-1 py-2 corner w-100 row'>
                            <div className='col-9'>
                                <h3 className='dashboard-card-title'>Hotel Mappings</h3>
                                <p className='grey-2 small mt-1'>View hotels mapped across all OTAs</p>
                            </div>
                            <div className='col-3 d-flex justify-content-between align-items-center'>
                                <Button variant='contained' size='large' onClick={() => navigate('/hotel-mappings/dashboard')}>Map new hotel</Button>
                            </div>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <Table disableSelect={true} cols={cols} rows={mappings.map(item => ({
                            'id': item['hotel_id'],
                            'emt_id': item['emt_id'],
                            'emt_name': item['emt_name'],
                            'agoda_id': item['agoda_id'],
                            'agoda_name': item['agoda_name'],
                            'booking_id': item['booking_id'],
                            'booking_name': item['booking_name'],
                            'delete': ""
                        }))} />
                    </div>
                </div>
            </Grow>

            <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="are-you-sure"
        aria-describedby="hotel-mapping-delete"
      >
        <DialogTitle id="alert-dialog-title">
          <h5 className='bold'>Are you sure you want to delete this mapping?</h5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Mapping once deleted, cannot be retrieved back
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
            setOpen2(true)
          }} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="are-you-sure"
        aria-describedby="hotel-mapping-delete"
      >
        <DialogContent>
        <div className='w-100 row d-flex justify-content-between align-items-center'>
              <div className='col-5 d-flex justify-content-center align-items-center'>
                <img style={{height: '10em'}} src={auth}/>
              </div>
              <div className='col-7'>
                <h5 className='bold mt-3'>Authentication required</h5>
                <p className='text-muted small mt-1'>Authentication is required in order to perform this action. Please enter your credentials below.</p>
                <input type='password' className='w-100 mt-2 form-control' placeholder='Enter your password' onChange={(evt) => setPassword(evt.target.value) }/>
              </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteMapping()} variant="contained" autoFocus>
            Delete mapping
          </Button>
        </DialogActions>
      </Dialog>
        </>
    )
}

export default Mappings;