import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from 'react-select';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import { fetchToken } from '../../Auth'
import { validateEmail, validateName } from '../../services/validators';

import '../../assets/styles/base.css';

import cities from '../../services/cities';
import cityDetails from '../../services/cityDetails';
import internationalCities from '../../services/internationalCities';

function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate and buffer variants.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
};

const customStyles = {
	menu: (provided) => ({
		...provided,
		maxHeight: '120px', // Set the maximum height for the dropdown menu
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: '120px', // Set the maximum height for the dropdown menu
	}),
	control: (provided, state) => ({
		...provided,
		'&:hover': {
			border: '1.25px solid #556',
		}
	}),
};

const NewUser = ({ roles, clients, users, setUsers,designation }) => {

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [userRole, setUserRole] = useState(null);
	const [userType, setUserType] = useState('');
	const [userDesignation,setUserDesignation] = useState('');
	const [selectedClient, setSelectedClient] = useState(null);
	const [selectedClients, setSelectedClients] = useState([]);
	const [scope, setScope] = useState({});
	const [scopeClientId, setScopeClientId] = useState("");
	const [regions, setRegions] = useState(null);

	//Data checks
	const [emailValid, setEmailValid] = useState(true);
	const [firstNameValid, setFirstNameValid] = useState(true);
	const [lastNameValid, setLastNameValid] = useState(true);

	//Progress
	const [progress1, setProgress1] = useState(0);
	const [progress2, setProgress2] = useState(0);
	const [progress3, setProgress3] = useState(0);
	const [progress4, setProgress4] = useState(0);
	const [progress5, setProgress5] = useState(0);
	const [progress6, setProgress6] = useState(0);

	const navigate = useNavigate();

	const rolesOptions = []
	for (let item of roles) {
		rolesOptions.push({ label: item['name'], value: item['id'] })
	}

	const designationOptions = []
	for (let item of designation) {
		designationOptions.push({ label: item['name'], value: item['id'] })
	}

	const createUser = async () => {
		if (!firstName || !firstNameValid) {
			setFirstNameValid(false)
			toast.error('Enter a valid first name!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!lastName || !lastNameValid) {
			setLastNameValid(false)
			toast.error('Enter a valid last name!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!email || !emailValid) {
			setEmailValid(false)
			toast.error('Enter a valid email address!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (userType === 'internal' && !selectedClients.length) {
			toast.error('Assign atleast one client for an internal user!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (userType === 'external' && !Object.keys(selectedClient).length) {
			toast.error('Select a client for an external user!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!userRole) {
			toast.error('Assign a user role for this user!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!userRole) {
			toast.error('Assign a user designation for this user!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!Object.keys(scope).length) {
			toast.error('Scope not configured for the user!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		try {
			const response = await axios.post(`/create_new_user`,
				{
					firstName,
					lastName,
					email,
					userRole,
					userType,
					selectedClient: selectedClient ? selectedClient['value'] : '',
					selectedClients,
					scope,
					userDesignation
				},
				{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
					},
				});
			if (response.status == 200 && response.data !== undefined) {
				setUsers([...users, { label: response.data[0], value: response.data[1] }])
				toast.success('User created successfully!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.SUCCESS
				});
			}

		} catch (err) {
			console.error("Error", err);
			if (err.response !== undefined && err.response.status === 401) {
				navigate('/login')
			}
			if (err.response !== undefined && err.response.status === 400) {
				toast.error(`Email address ${email} already exists!`, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
			}
		}
	}

	const getProperties = async (id) => {
		try {
			const response = await axios.get(`/get_client_properties/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
				},
			});
			if (response.status == 200 && response.data !== undefined) {
				setRegions(response.data)
			}

		} catch (err) {
			console.error("Error", err);
			if (err && err.response.status === 401) {
				navigate('/login')
			}
			if (err && err.response.status === 500) {
				toast.error('Something went wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					theme: "colored",
					type: toast.TYPE.ERROR
				});
				return
			}
		}
	}

	const handleToggle = (city, id) => {
		if (userType === 'internal') {
			setScope(prevState => {
				const updatedState = { ...prevState };
				let clientId = scopeClientId;

				if (updatedState[clientId]) {
					if (updatedState[clientId][city]) {
						const index = updatedState[clientId][city].indexOf(id);

						if (index !== -1) {
							// If the id is present, remove it
							updatedState[clientId][city].splice(index, 1);

							// If the array becomes empty, remove the city
							if (updatedState[clientId][city].length === 0) {
								delete updatedState[clientId][city];

								// If all cities are removed, remove the clientId
								if (Object.keys(updatedState[clientId]).length === 0) {
									delete updatedState[clientId];
								}
							}
						} else {
							// If the id is not present, add it
							updatedState[clientId][city].push(id);
						}
					} else {
						// If the city doesn't exist, create a new entry
						updatedState[clientId][city] = [id];
					}
				} else {
					// If the clientId doesn't exist, create a new entry
					updatedState[clientId] = { [city]: [id] };
				}

				return updatedState;
			});

		}
		else if (userType === 'external') {
			setScope(prevCityData => {
				const updatedCityData = { ...prevCityData };

				if (updatedCityData[city]) {
					const index = updatedCityData[city].indexOf(id);

					if (index !== -1) {
						// If the id is present, remove it
						updatedCityData[city].splice(index, 1);

						// If the array becomes empty, remove the city
						if (updatedCityData[city].length === 0) {
							delete updatedCityData[city];
						}
					} else {
						// If the id is not present, add it
						updatedCityData[city].push(id);
					}
				} else {
					// If the city doesn't exist, create a new entry
					updatedCityData[city] = [id];
				}

				return updatedCityData;
			});
		}

	};


	useEffect(() => {
		if (email && !validateEmail(email)) {
			setEmailValid(false)
		} else {
			setEmailValid(true)
		}
	}, [email])

	useEffect(() => {
		if (firstName && !validateName(firstName)) {
			setFirstNameValid(false)
		} else {
			setFirstNameValid(true)
		}
	}, [firstName])

	useEffect(() => {
		if (lastName && !validateName(lastName)) {
			setLastNameValid(false)
		} else {
			setLastNameValid(true)
		}
	}, [lastName])

	useEffect(() => {
		setSelectedClient(null)
		setSelectedClients([])
		if (userType) {
			setProgress2(100)
		} else {
			setProgress2(0)
		}

		setScope({});
	}, [userType])

	useEffect(() => {
		if (selectedClient && typeof selectedClient['value'] !== 'undefined') {
			setScopeClientId(selectedClient['value'])
		}
	}, [selectedClient])

	useEffect(() => {
		setScopeClientId("");
	}, [selectedClients])

	useEffect(() => {
		if (userType) {
			setSelectedClient(null)
			setSelectedClients([])
			setScopeClientId("");
			setScope({})
			setRegions(null)
		}
	}, [userType])


	useEffect(() => {
		let value = 0
		if (firstName && validateName(firstName)) {
			value += 33
		}
		if (lastName && validateName(lastName)) {
			value += 33
		}
		if (email && validateEmail(email)) {
			value += 34
		}
		setProgress1(value)
	}, [firstName, lastName, email])

	useEffect(() => {
		if (userType === 'external' && selectedClient && Object.keys(selectedClient).length && selectedClient['value']) {
			setProgress3(100)
		}
		else if (userType === 'internal' && selectedClients && selectedClients.length) {
			setProgress3(100)
		} else {
			setProgress3(0)
		}
	}, [selectedClients, selectedClient]);

	useEffect(() => {
		if (userRole) {
			setProgress4(100)
		} else {
			setProgress4(0)
		}
	}, [userRole])

	useEffect(() => {
		if (userDesignation) {
			setProgress6(100)
		} else {
			setProgress6(0)
		}
	}, [userDesignation])

	const scopeAssigned = () => {
		if (userType === 'external') {
			for (let key in scope) {
				if (scope[key].length) {
					return true
				}
			}
			return false
		}
		else if (userType === 'internal') {
			for (let client in scope) {
				for (let key in scope[client]) {
					if (scope[client][key].length) {
						return true
					}
				}
			}
			return false
		}
	}

	useEffect(() => {
		if (userType) {
			if (scopeAssigned()) {
				setProgress5(100)
			} else {
				setProgress5(0)
			}
		}
	}, [scope])


	useEffect(() => {
		if (scopeClientId) {
			getProperties(scopeClientId)
		}
	}, [scopeClientId])

	return (
		<section className="p-3">
			<div>
				<Grow in={true}>
					<Accordion defaultExpanded={true} className="mb-3 py-2 accordion-item">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<div className='w-100 row'>
								<div className='col-10'>
									<h6 className='bold text-orange'><i class="fa-solid fa-user mx-2 grey"></i> Profile Details</h6>
								</div>
								<div className='col-2'>
									<Box sx={{ width: '100%' }}>
										<LinearProgressWithLabel value={progress1} />
									</Box>
								</div>
							</div>
						</AccordionSummary>
						<AccordionDetails>

							<div className="row">
								<div className="col-6">
									<TextField error={!firstNameValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setFirstName(evt.target.value)} className="w-100" id="first-name" label="First Name" variant="outlined" />
									{!firstNameValid ? <small className='mx-2 text-danger'>Invalid first name</small> : null}
								</div>
								<div className="col-6">
									<TextField error={!lastNameValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setLastName(evt.target.value)} className="w-100" id="last-name" label="Last Name" variant="outlined" />
									{!lastNameValid ? <small className='mx-2 text-danger'>Invalid last name</small> : null}
								</div>
							</div>
							<div className="mt-4 row">
								<p class='small text-muted'></p>
							</div>
							<div className="row">
								<div className="col-6">
									<div className='d-flex justify-content-between align-items-center'>
										<TextField error={!emailValid ? true : false} style={{ background: '#fff' }} size="small" onChange={(evt) => setEmail(evt.target.value)} className="w-100" id="email" label="Email ID" variant="outlined" />
										<Tooltip title="Make sure personal Email addresses are not entered and only the organisation's Email ID is used. This Email ID will be used as the username for logging in and all emailers, reports etc. are sent to this address.">
											<IconButton>
												<InfoIcon />
											</IconButton>
										</Tooltip>
									</div>
									{!emailValid ? <small className='mx-2 text-danger'>Invalid email</small> : null}
								</div>
							</div>

						</AccordionDetails>
					</Accordion>
				</Grow>

				<Grow
					in={true}
					{...(true ? { timeout: 500 } : {})}
				>
					<Accordion className="mb-3 py-2 accordion-item">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2a-content"
							id="panel2a-header"
						>
							<div className='w-100 row'>
								<div className='col-9'>
									<h6 className='bold text-orange'><i class="fa-solid fa-lock mx-2 grey"></i> User Type</h6>
								</div>
								<div className='col-3 d-flex align-items-center'>
									<div className='warning-pill mx-2 p-1'><span className='small'>Required</span></div>
									<Box sx={{ width: '100%' }}>
										<LinearProgressWithLabel value={progress2} />
									</Box>
								</div>
							</div>

						</AccordionSummary>
						<AccordionDetails>
							<div className='d-flex flex-row justify-content-between align-items-center w-40 row'>
								<div className="checkbox-wrapper-16">
									<label className="mx-3 checkbox-wrapper">
										<input value='internal' onClick={(evt) => setUserType(userType === 'internal' ? '' : 'internal')} checked={userType === 'internal' ? 'checked' : ''} type="checkbox" className="checkbox-input" />
										<span className="checkbox-tile">
											<span className="checkbox-icon">

											</span>
											<span className="checkbox-label">Internal</span>
										</span>
									</label>
									<label className="mx-3 checkbox-wrapper">
										<input value='external' onClick={(evt) => setUserType(userType === 'external' ? '' : 'external')} checked={userType === 'external' ? 'checked' : ''} type="checkbox" className="checkbox-input" />
										<span className="checkbox-tile">
											<span className="checkbox-icon">

											</span>
											<span className="checkbox-label">External</span>
										</span>
									</label>
									<Tooltip title="Set user type as 'Internal' for Sciative users and 'External' for external client users. An internal user can be assigned multiple clients while an external user can be assigned a single client only.
		        		User type once set, cannot be changed later.">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</Grow>


				<Grow
					in={true}
					{...(true ? { timeout: 1000 } : {})}
				>
					<Accordion className="mb-3 py-2 accordion-item">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel3a-content"
							id="panel3a-header"
						>
							<div className='w-100 row'>
								<div className='col-10'>
									<h6 className='bold text-orange'><i class="fa-solid fa-hotel mx-2 grey"></i> Assign Clients</h6>
								</div>
								<div className='col-2'>
									<Box sx={{ width: '100%' }}>
										<LinearProgressWithLabel value={progress3} />
									</Box>
								</div>
							</div>

						</AccordionSummary>
						<AccordionDetails>
							<div className='row'>
								{userType === 'external' ?
									<div className="col-6">
										<Select styles={customStyles} placeholder="Select a client" value={selectedClient} onChange={opt => setSelectedClient(opt)} options={clients.filter(opt => opt.value !== 1 && opt.label !== 'Internal')} className="w-100" />
									</div>
									:
									userType === 'internal' ?
										<div className="col-6">
											<Select styles={customStyles} placeholder="Select clients" isMulti value={selectedClients} onChange={opt => setSelectedClients(opt)} options={clients} closeMenuOnSelect={false} className="w-100" />
										</div>
										:
										<div className='p-2'>
											<Alert severity="info">Select a user type in the section above to assign clients</Alert>
										</div>
								}
								<div className='col-2'>
									<Tooltip title="Multi-tenant selection will be visible to internal users in the navbar based on the clients assigned.">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</Grow>

				<Grow
					in={true}
					{...(true ? { timeout: 1500 } : {})}
				>
					<Accordion className="mb-3 py-2 accordion-item">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel4a-content"
							id="panel4a-header"
						>
							<div className='w-100 row'>
								<div className='col-10'>
									<h6 className='bold text-orange'><i class="fa-solid fa-wrench mx-2 grey"></i> User Role</h6>
								</div>
								<div className='col-2'>
									<Box sx={{ width: '100%' }}>
										<LinearProgressWithLabel value={progress4} />
									</Box>
								</div>
							</div>

						</AccordionSummary>
						<AccordionDetails>
							<div className="row">
								<div className="col-6">
									<Select placeholder="Select Role" styles={customStyles} onChange={opt => setUserRole(parseInt(opt.value))} className="w-100" options={rolesOptions} />
								</div>
								<div className='col-2'>
									<Tooltip title="Assign a user role to set what dashboards a user can have access to and set his/her privileges (reader/maker/checker). Note that user roles created are general and not client specific">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</Grow>
				<Grow
					in={true}
					{...(true ? { timeout: 1500 } : {})}
				>
					<Accordion className="mb-3 py-2 accordion-item">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel4a-content"
							id="panel4a-header"
						>
							<div className='w-100 row'>
								<div className='col-10'>
									<h6 className='bold text-orange'><i class="fa-solid fa-wrench mx-2 grey"></i> User Designation</h6>
								</div>
								<div className='col-2'>
									<Box sx={{ width: '100%' }}>
										<LinearProgressWithLabel value={progress6} />
									</Box>
								</div>
							</div>

						</AccordionSummary>
						<AccordionDetails>
							<div className="row">
								<div className="col-6">
									<Select placeholder="Select Designation" styles={customStyles} onChange={opt => setUserDesignation(parseInt(opt.value))} className="w-100" options={designationOptions} />
								</div>
								<div className='col-2'>
									<Tooltip title="Assign a user Designation to set what dashboards a user can have access to and set his/her privileges (reader/maker/checker). Note that user roles created are general and not client specific">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</Grow>

				<Grow
					in={true}
					{...(true ? { timeout: 2000 } : {})}
				>
					<Accordion className="mb-3 py-2 accordion-item">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel5a-content"
							id="panel5a-header"
						>
							<div className='w-100 row'>
								<div className='col-10'>
									<h6 className='bold text-orange'><i class="fa-solid fa-eye mx-2 grey"></i> User Scope</h6>
								</div>
								<div className='col-2'>
									<Box sx={{ width: '100%' }}>
										<LinearProgressWithLabel value={progress5} />
									</Box>
								</div>
							</div>

						</AccordionSummary>
						<AccordionDetails>
							<div className="row">
								{
									userType === 'internal' ?
										<div className='col-6 d-flex'>
											<select value={scopeClientId} className='form-control' onChange={(evt) => setScopeClientId(evt.target.value)}>
												<option selected hidden>Select a client</option>
												{
													selectedClients && selectedClients.filter(item => parseInt(item.value) !== 1).map(item => <option value={item.value}>{item.label}</option>)
												}
											</select>
											<Tooltip className='mx-2' title="Configure which data the user has access to in the dashboards visible to him. Assign specific client regions to a user. Data for only those regions will be visible in the dashboards.">
												<IconButton>
													<InfoIcon />
												</IconButton>
											</Tooltip>
										</div>
										:
										userType === 'external' ?
											<div className='d-flex align-items-end'>
												<h6>Regions for selected client</h6>
												<Tooltip className='mx-2' title="Configure which data the user has access to in the dashboards visible to him. Assign specific client regions to a user. Data for only those regions will be visible in the dashboards.">
													<IconButton>
														<InfoIcon />
													</IconButton>
												</Tooltip>
											</div>
											:
											<div className='p-2'>
												<Alert severity="info">Select a user type in the section above to assign user scope</Alert>
											</div>
								}
								{
									(userType === 'internal' || userType === 'external') && scopeClientId ?
										<div className='col-10 mt-3'>
											<List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
												{
													regions && Object.keys(regions).length ?
														Object.keys(regions).map((key) => {
															const labelId = `checkbox-list-secondary-label-${key}`;
															return (
																<>
																	<Accordion style={{ boxShadow: 'none', borderRadius: '12px' }} className="mb-3 py-2 outline-list-item">
																		<AccordionSummary
																			expandIcon={<ExpandMoreIcon />}
																			aria-controls="panel1a-content"
																		>
																			<div className='w-100 row'>
																				<div className='col-6 d-flex align-items-center'>
																					<Avatar
																						className='mr-2'
																						alt={key}
																						src={cityDetails[key]?.img ? cityDetails[key].img : null}
																					/>

																					<h6 className='bold'>{key}</h6>
																				</div>
																				<div className='col-6 d-flex justify-content-end align-items-center'>
																					{
																						userType === 'internal' ?
																							scopeClientId in scope && key in scope[scopeClientId] ?
																								scope[scopeClientId][key].length ?
																									<><i class="text-success fa-solid fa-circle-check mx-2"></i> <span className='grey-2 bold small'>{scope[scopeClientId][key].length}/{regions[key]['properties'].length} properties assigned</span></>
																									:
																									<><i class="text-warning fa-solid fa-circle-exclamation mx-2"></i> <span className='grey-2 bold small'>{scope[scopeClientId][key].length}/{regions[key]['properties'].length} properties assigned</span></>
																								:
																								<><i class="text-warning fa-solid fa-circle-exclamation mx-2"></i> <span className='grey-2 bold small'>0/{regions[key]['properties'].length} properties assigned</span></>
																							:
																							userType === 'external' ?
																								key in scope ?
																									scope[key].length ?
																										<><i class="text-success fa-solid fa-circle-check mx-2"></i> <span className='grey-2 bold small'>{scope[key].length}/{regions[key]['properties'].length} properties assigned</span></>
																										:
																										<><i class="text-warning fa-solid fa-circle-exclamation mx-2"></i> <span className='grey-2 bold small'>{scope[key].length}/{regions[key]['properties'].length} properties assigned</span></>
																									:
																									<><i class="text-warning fa-solid fa-circle-exclamation mx-2"></i> <span className='grey-2 bold small'>0/{regions[key]['properties'].length} properties assigned</span></>
																								:
																								null
																					}


																				</div>
																			</div>
																		</AccordionSummary>
																		<AccordionDetails>
																			<div className='row d-flex align-items-center'>
																				{
																					regions[key]['properties'].map(prop => (
																						<div className='row'>
																							<div className='col-1'>
																								<Checkbox checked={userType === 'internal' ? scopeClientId in scope && key in scope[scopeClientId] && scope[scopeClientId][key].includes(prop['property_id']) : key in scope && scope[key].includes(prop['property_id'])} onChange={() => handleToggle(key, prop['property_id'])} />
																							</div>
																							<div className='col-5 d-flex align-items-center'>
																								{prop['property_name']} {prop['is_active'] ? <div className='mx-2 success-pill small bold'>ACTIVE</div> : <div className='mx-2 warning-pill small bold'>INACTIVE</div>}
																							</div>
																							<div className='col-2'>
																								<div className='grey-pill small'>
																									{Object.keys(prop['room_types']).length} room types
																								</div>
																							</div>
																							<div className='col-2'>
																								<div className='grey-pill small'>
																									{Object.values(prop['room_types']).reduce((acc, obj) => acc + obj.no_of_rooms, 0)} rooms
																								</div>
																							</div>
																						</div>
																					))
																				}
																			</div>
																		</AccordionDetails>
																	</Accordion>
																	{/* <ListItem */}
																	{/* className="mb-2 w-100" */}
																	{/*   key={key} */}
																	{/*   secondaryAction={ */}
																	{/*     <Checkbox */}
																	{/*       edge="end" */}
																	{/*       onChange={handleToggle(key)} */}
																	{/*       checked={userType === 'external' && scope.includes(key) ? true : userType === 'internal' && scopeClientId in scope && scope[scopeClientId].includes(key) ? true : false} */}
																	{/*       inputProps={{ 'aria-labelledby': labelId }} */}
																	{/*     /> */}
																	{/*   } */}
																	{/*   disablePadding */}
																	{/* > */}
																	{/*   <ListItemButton> */}
																	{/*     <ListItemAvatar> */}
																	{/*       <Avatar */}
																	{/*         alt={key} */}
																	{/*         src={cityDetails[key]['img']} */}
																	{/*       /> */}
																	{/*     </ListItemAvatar> */}
																	{/*     <ListItemText id={labelId} primary={key} /> */}
																	{/*   </ListItemButton> */}
																	{/* </ListItem> */}
																	{/* <Divider /> */}
																</>
															);
														})
														:
														selectedClient && selectedClient.value ?
															<Alert severity="warning">No regions set for this client!</Alert>
															:
															null
												}
											</List>
										</div>
										:
										null
								}
							</div>
						</AccordionDetails>
					</Accordion>
				</Grow>

				<div className="w-100 mt-4 row d-flex justify-content-center align-items-center">
					<Button size='large' style={{ width: '20%' }} onClick={createUser} variant="contained">Create User</Button>
				</div>
			</div>

			{/* 			<div className="row"> */}
			{/* 				<div className="col-12"> */}
			{/* 					<div className="bg-white admin-card p-4"> */}
			{/* 						<h6 className="admin-card-header mb-3">Create New User</h6> */}
			{/*  */}
			{/* 						<div className='admin-card-section p-3'> */}
			{/* 							<Divider className="my-4" textAlign="left"><span style={{fontWeight: 'bold'}}>Basic Details</span></Divider> */}
			{/*  */}
			{/* 					 */}
			{/*  */}

			{/*  */}
			{/* 					<Divider className="my-4" textAlign="left"><span style={{fontWeight: 'bold'}}>User Type</span></Divider> */}
			{/*  */}
			{/* 					<div className="mt-3 row"> */}
			{/* 						<div className="col-6"> */}
			{/* 							<Select onChange={opt => setUserType(opt.value)} className="w-100" options={[{'label': 'Internal', value: 'internal'}, {'label': 'External', value: 'external'}]} /> */}
			{/* 						</div> */}

			{/* 					</div> */}
			{/*  */}
			{/* 					<Divider className="my-4" textAlign="left"><span style={{fontWeight: 'bold'}}>User Role</span></Divider> */}
			{/*  */}

			{/* 					<div className="mt-3 row w-100 d-flex justify-content-center align-items-center"> */}
			{/* 						<Button style={{width: '20%'}} onClick={createUser} variant="contained">Create</Button> */}
			{/* 					</div> */}
			{/* 						</div> */}
			{/* 					 */}
			{/* 					</div> */}
			{/* 					 */}
			{/* 				</div> */}
			{/* 			</div> */}
		</section>
	)
}

export default NewUser;