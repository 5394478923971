import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useContext, useCallback } from 'react';
import Select from 'react-select';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { fetchToken } from '../../Auth';
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { toast } from 'react-toastify';
import FocusCompetition from './FocusCompetition';
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ConfirmationDialog from "./ConfirmationDialog"
import { MultiSelect } from "react-multi-select-component";


const itemStyles = {
    zIndex: 5,
    cursor: 'grab'
};



const ClientPropertiesV2 = ({ clientProperties, setClientProperties, international, focusComp, setFocusComp, preferredOTA, propertyTypes, serviceTypes, mealPlans, amenities, roomTypes, bedTypes, handleBack }) => {

    const SortableItem = sortableElement(({ roomType, rtIndex }) => (
        <div className='outline-card p-3 row' style={itemStyles}>
            <div className='mt-2 col-1'>
                <DragIndicatorIcon />
            </div>
            <div className='mt-2 col-2'>
                <div className='w-100 d-flex align-items-start'>
                    <div className='outline-card-icon p-1'>
                        <i className="fa-solid fa-bed"></i>
                    </div>
                    <h6 className='bold mx-2'>{roomType.type_name}</h6>
                </div>
            </div>
            <div className='mt-2 col-2'>
                <p className='bold small'>{roomType.count} rooms</p>
            </div>
            <div className='mt-2 col-2'>
                <p className='grey small'>{roomType.sq_ft_area} sq. ft.</p>
            </div>
            <div className='mt-2 col-2'>
                <p className='success-pill small'>{roomType.bed_type}</p>
            </div>
            <div className='mt-2 col-2'>
                {/* <p className='priority small'>#{roomType.order + 1}</p> */}
                {roomType.order === 0 && <p className='success-pill small bg-orange text-white'>Base Room</p>}

            </div>
            <div className='mt-2 col-1 z-3'>
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEditRoomType(roomType);
                    }}
                    className="no-drag"
                >
                    <span className="material-symbols-outlined">edit</span>
                </IconButton>
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteRoomType(rtIndex);
                    }}
                    className="no-drag"
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
        return <div className="container">{children}</div>;
    });

    const showToast = (message, type = 'error') => {
        toast(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE[type.toUpperCase()] || toast.TYPE.ERROR,
        });
    };

    const commonSx = {
        '& .MuiStack-root': {
            paddingTop: "8px !important",
        },
        '& .MuiDateTimePickerToolbar-root': {
            paddingTop: "8px  !important",
        },
    };

    const targetCustomers = [
        { label: "Leisure", value: "leisure" },
        { label: "Business", value: "business" },
        { label: "Both", value: "both" },
    ]

    const shortMonths = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
    ]
    const months = shortMonths.map(month => {
        return { value: month.toLowerCase(), label: month };
    });

    const startYear = 1800;
    const endYear = new Date().getFullYear() + 1;


    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => {
        const year = endYear - i;
        return { label: year, value: year };
    });

    const [selectedCity, setSelectedCity] = useState(null)
    const [expandedPanel, setExpandedPanel] = useState(false);
    const [roomDetailsOpen, setRoomDetailsOpen] = useState(false);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [selectedHotelIndex, setSelectedHotelIndex] = useState(null);
    const [additionalAmenities, setAdditionalAmenities] = useState({});

    const [newRoomType, setNewRoomType] = useState({
        type_name: '',
        // meal_plan: '',
        bed_type: '',
        sq_ft_area: '',
        count: '',
        base_room: false,
        default_occupancy: '',
        max_occupancy: '',
        order: 0
    });
    const [roomTypeError, setRoomTypeError] = useState("");
    const [isFirstRoom, setIsFirstRoom] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        title: '',
        message: '',
        onConfirm: null
    });


    const deleteHotel = async (evt, rowData) => {
        evt.stopPropagation()
        // const shouldDelete = await showConfirmation("Confirm if you want to delete property details")
        const shouldDelete = await handleConfirmation(
            "Delete Property",
            "Are you sure you want to delete this property?"
        );
        if (shouldDelete === true) {
            try {
                evt.stopPropagation()
                setClientProperties((prevData) => {
                    const updatedData = { ...prevData };
                    const cityName = rowData.city;

                    if (updatedData[cityName]) {
                        updatedData[cityName].properties = updatedData[
                            cityName
                        ].properties.filter((property) => property._id !== rowData._id);
                        if (updatedData[cityName].properties.length === 0) {
                            delete updatedData[cityName];
                        }
                    }
                    return updatedData;
                });
            } catch (error) {
                console.error("Error deleting entry!", error);
            }
        } else {
            return
        }

    };

    const shouldDisable = (item) => {
        if (typeof item === "undefined" || !item) {
            return false
        }
        return true
    }

    const returnValue = (item) => {
        if (typeof item === "undefined" || item === null) {
            return null
        }
        return item
    }

    const handleAccordionChange = (panel, index) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : false);
        setSelectedHotel(isExpanded ? panel : null)
        setSelectedHotelIndex(isExpanded ? index : null)
    };

    const handleDataChange = (index, data, key) => {
        if (!selectedCity) return;

        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];

            if (key === "meal_plan") {
                // Handle meal plan updates
                cityProperties[index] = {
                    ...cityProperties[index],
                    meal_plan: {
                        ...(cityProperties[index].meal_plan || {}),
                        ...(data.value === ""
                            ? { [data.mealPlanLabel]: undefined }
                            : { [data.mealPlanLabel]: parseInt(data.value) }
                        )
                    }
                };
                cityProperties[index].meal_plan = Object.fromEntries(
                    Object.entries(cityProperties[index].meal_plan)
                        .filter(([_, value]) => value !== undefined)
                );
            } else {
                // Handle other data updates
                cityProperties[index] = {
                    ...cityProperties[index],
                    [key]: ["property_type", "target_customers", "established_month", "established_year"].includes(key)
                        ? data.value
                        : ["service_start_date", "service_end_date"].includes(key)
                            ? data.format('YYYY-MM-DD')
                            : data
                };
            }

            updatedData[selectedCity.value] = {
                ...updatedData[selectedCity.value],
                properties: cityProperties
            };

            return updatedData;
        });
    };
    const handleMealPlanChange = (index, mealPlanLabel, value) => {
        const numericValue = Math.max(0, Number(value));
        handleDataChange(index, { mealPlanLabel, value: numericValue }, "meal_plan");
    };




    const handleAmenityToggle = (index, amenityLabel) => {
        if (!selectedCity) return;

        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];

            if (!cityProperties[index].amenities) {
                cityProperties[index].amenities = [];
            }

            const currentAmenities = [...cityProperties[index].amenities];
            const amenityIndex = currentAmenities.indexOf(amenityLabel);

            if (amenityIndex === -1) {
                currentAmenities.push(amenityLabel);
            } else {
                currentAmenities.splice(amenityIndex, 1);
            }

            cityProperties[index] = {
                ...cityProperties[index],
                amenities: currentAmenities
            };

            updatedData[selectedCity.value] = {
                ...updatedData[selectedCity.value],
                properties: cityProperties
            };

            return updatedData;
        });
    };

    const isAmenitySelected = (propertyIndex, amenityLabel) => {
        if (!selectedCity ||
            !clientProperties[selectedCity.value]?.properties[propertyIndex]?.amenities) {
            return false;
        }
        return clientProperties[selectedCity.value].properties[propertyIndex].amenities.includes(amenityLabel);
    };

    const handleAdditionalAmenityChange = (index, value) => {
        setAdditionalAmenities(prev => ({
            ...prev,
            [index]: value
        }));
    };

    const handleAdditionalAmenityAdd = (propertyIndex) => {
        if (!selectedCity || !additionalAmenities[propertyIndex]?.trim()) return;
        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];

            if (!cityProperties[propertyIndex].additional_amenities) {
                cityProperties[propertyIndex].additional_amenities = [];
            }

            const amenity_list = amenities.map((amenity) => { return amenity.label })
            if (cityProperties[propertyIndex].additional_amenities.includes(additionalAmenities[propertyIndex].trim())) {
                showToast("Additional Amenity already added")
                return updatedData
            }
            if (amenity_list.includes(additionalAmenities[propertyIndex].trim())) {
                showToast("Amenity already exists in above cells")
                return updatedData
            }

            cityProperties[propertyIndex].additional_amenities = [
                ...cityProperties[propertyIndex].additional_amenities,
                additionalAmenities[propertyIndex].trim()
            ];

            updatedData[selectedCity.value] = {
                ...updatedData[selectedCity.value],
                properties: cityProperties
            };

            return updatedData;
        });

        // Clear the input for this specific property
        setAdditionalAmenities(prev => ({
            ...prev,
            [propertyIndex]: ''
        }));
    };

    const handleAdditionalAmenityRemove = (propertyIndex, amenityIndex) => {
        if (!selectedCity) return;

        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];

            const updatedAmenities = [...(cityProperties[propertyIndex].additional_amenities || [])];
            updatedAmenities.splice(amenityIndex, 1);

            cityProperties[propertyIndex] = {
                ...cityProperties[propertyIndex],
                additional_amenities: updatedAmenities
            };

            updatedData[selectedCity.value] = {
                ...updatedData[selectedCity.value],
                properties: cityProperties
            };

            return updatedData;
        });
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (!selectedCity || selectedHotelIndex === null) return;

        setClientProperties(prevData => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];
            const roomTypes = [...cityProperties[selectedHotelIndex].room_types];
            if (oldIndex > newIndex) {
                for (let i = oldIndex - 1; i >= newIndex; i--) {
                    roomTypes[i].order++;
                    roomTypes[i].base_room = false;
                }
                roomTypes[oldIndex].order = newIndex;
                roomTypes[oldIndex].base_room = (newIndex === 0);
            } else if (oldIndex < newIndex) {
                for (let i = oldIndex + 1; i <= newIndex; i--) {
                    roomTypes[i].order--;
                    roomTypes[i].base_room = false;
                }
                roomTypes[oldIndex].order = newIndex;
                roomTypes[oldIndex].base_room = (newIndex === 0);

                if (oldIndex === 0) {
                    roomTypes[0].base_room = true;
                }
            }

            const sortedRoomTypes = roomTypes.sort((a, b) => a.order - b.order);

            sortedRoomTypes.forEach((room, index) => {
                room.base_room = index === 0;
            });

            cityProperties[selectedHotelIndex].room_types = sortedRoomTypes;
            updatedData[selectedCity.value].properties = cityProperties;

            return updatedData;
        });
    };

    const handleRoomDetailModalOpen = (index, hotelName) => {
        const propertyData = clientProperties[selectedCity.value].properties[selectedHotelIndex]

        if ('room_types' in propertyData && Array.isArray(propertyData.room_types) && propertyData.room_types.length > 0) {

        } else {
            setNewRoomType({
                ...newRoomType,
                base_room: true
            })
            setIsFirstRoom(true)
        }
        setRoomDetailsOpen(true)
    }


    const handleRoomDetailsModalClose = () => {
        setRoomDetailsOpen(false)
        setNewRoomType({
            type_name: '',
            // meal_plan: '',
            bed_type: '',
            sq_ft_area: '',
            count: '',
            base_room: false,
            default_occupancy: '',
            max_occupancy: '',
            order: 0
        })
        setIsEditing(false);
        setIsFirstRoom(false);
    }

    const validateForm = (existingRoomTypes) => {
        setRoomTypeError("");

        // Check for required fields
        if (!newRoomType.type_name ||
            // !newRoomType.meal_plan ||
            !newRoomType.bed_type ||
            !newRoomType.sq_ft_area ||
            !newRoomType.count ||
            !newRoomType.default_occupancy ||
            !newRoomType.max_occupancy) {
            setRoomTypeError("Please fill in all required fields");
            return false;
        }

        // Validate occupancy
        if (parseInt(newRoomType.max_occupancy) < parseInt(newRoomType.default_occupancy)) {
            setRoomTypeError("Maximum occupancy must be greater than or equal to default occupancy");
            return false;
        }

        // if (!isEditing && existingRoomTypes.some(room =>
        //     room.type_name.toLowerCase() === newRoomType.type_name.toLowerCase() && room.meal_plan.toLowerCase() === newRoomType.meal_plan.toLowerCase())) {
        //     setRoomTypeError("This room type meal plan combination already exists");
        //     return false;
        // }

        if (!isEditing && existingRoomTypes.some(room =>
            room.type_name.toLowerCase() === newRoomType.type_name.toLowerCase())) {
            setRoomTypeError("This room type already exists");
            return false;
        }

        // Validate numeric fields
        if (newRoomType.sq_ft_area <= 0) {
            setRoomTypeError("Carpet area must be greater than 0");
            return false;
        }

        if (newRoomType.count <= 0) {
            setRoomTypeError("Number of rooms must be greater than 0");
            return false;
        }

        if (newRoomType.default_occupancy <= 0 || newRoomType.max_occupancy <= 0) {
            setRoomTypeError("Occupancy must be greater than 0");
            return false;
        }

        return true;
    };

    const handleAddRoomType = (saveAndExit = false) => {
        const propertyData = clientProperties[selectedCity.value].properties[selectedHotelIndex];
        const existingRoomTypes = propertyData.room_types || [];

        if (validateForm(existingRoomTypes)) {
            setClientProperties((prevData) => {
                const updatedData = { ...prevData };
                const cityProperties = [...updatedData[selectedCity.value].properties];

                if (!cityProperties[selectedHotelIndex].room_types) {
                    cityProperties[selectedHotelIndex].room_types = [];
                }

                const updatedRoomTypes = [...cityProperties[selectedHotelIndex].room_types];

                if (newRoomType.base_room) {
                    updatedRoomTypes.forEach((room) => {
                        room.order += 1;
                    });
                    newRoomType.order = 0;
                } else {
                    newRoomType.order = updatedRoomTypes.length;
                }
                updatedRoomTypes.push(newRoomType);

                updatedRoomTypes.sort((a, b) => a.order - b.order);

                cityProperties[selectedHotelIndex].room_types = updatedRoomTypes;

                updatedData[selectedCity.value] = {
                    ...updatedData[selectedCity.value],
                    properties: cityProperties,
                };

                return updatedData;
            });

            // Reset the newRoomType state
            setNewRoomType({
                type_name: '',
                meal_plan: '',
                bed_type: '',
                sq_ft_area: '',
                count: '',
                base_room: false,
                default_occupancy: '',
                max_occupancy: '',
                order: 0,
            });

            if (saveAndExit) {
                setRoomDetailsOpen(false);
            }
            setIsFirstRoom(false);
        }
    };

    const handleEditRoomType = (roomType) => {
        const propertyData = clientProperties[selectedCity.value].properties[selectedHotelIndex];
        const existingRoomTypes = propertyData.room_types || [];

        setIsEditing(true)
        setNewRoomType(roomType)
        setRoomDetailsOpen(true)
        if (existingRoomTypes.length === 1) {
            setIsFirstRoom(true)
        }
    }

    const handleUpdateRoomType = (saveAndExit = false) => {
        const propertyData = clientProperties[selectedCity.value].properties[selectedHotelIndex];
        const existingRoomTypes = propertyData.room_types || [];

        if (validateForm(existingRoomTypes)) {
            setClientProperties((prevData) => {
                const updatedData = { ...prevData };
                const cityProperties = [...updatedData[selectedCity.value].properties];
                const updatedRoomTypes = [...cityProperties[selectedHotelIndex].room_types];

                // Find the index of the room being edited
                const editedRoomIndex = updatedRoomTypes.findIndex(
                    room => room.type_name === newRoomType.type_name
                );

                if (editedRoomIndex === -1) return prevData;

                // Handle base room changes
                if (newRoomType.base_room && !updatedRoomTypes[editedRoomIndex].base_room) {
                    // If setting as base room, update orders and clear other base rooms
                    updatedRoomTypes.forEach((room) => {
                        if (room.base_room) room.base_room = false;
                        if (room.order <= newRoomType.order) {
                            room.order += 1;
                        }
                    });
                    newRoomType.order = 0;
                } else if (!newRoomType.base_room && updatedRoomTypes[editedRoomIndex].base_room) {
                    // If removing base room status, find next room to be base
                    const nextBaseRoom = updatedRoomTypes.reduce((min, room) =>
                        room.type_name !== newRoomType.type_name &&
                            (min === null || room.order < min.order) ? room : min
                        , null);

                    if (nextBaseRoom) {
                        nextBaseRoom.base_room = true;
                        nextBaseRoom.order = 0;

                        // Update orders for other rooms
                        updatedRoomTypes.forEach((room) => {
                            if (room.type_name !== nextBaseRoom.type_name &&
                                room.type_name !== newRoomType.type_name) {
                                if (room.order > nextBaseRoom.order) {
                                    room.order += 1;
                                }
                            }
                        });
                        newRoomType.order = updatedRoomTypes.length - 1;
                    }
                }

                // Update the room with new values
                updatedRoomTypes[editedRoomIndex] = newRoomType;

                // Sort rooms by order
                updatedRoomTypes.sort((a, b) => a.order - b.order);

                cityProperties[selectedHotelIndex].room_types = updatedRoomTypes;
                updatedData[selectedCity.value].properties = cityProperties;

                return updatedData;
            });

            if (saveAndExit) {
                setRoomDetailsOpen(false);
            }

            // Reset states
            setNewRoomType({
                type_name: '',
                meal_plan: '',
                bed_type: '',
                sq_ft_area: '',
                count: '',
                base_room: false,
                default_occupancy: '',
                max_occupancy: '',
                order: 0
            });
            setIsEditing(false);
            setIsFirstRoom(false)
        }
    };

    const handleDeleteRoomType = (index) => {
        setClientProperties(prevData => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];
            const updatedRoomTypes = [...cityProperties[selectedHotelIndex].room_types];

            const isRemovingBaseRoom = updatedRoomTypes[index].base_room;

            updatedRoomTypes.splice(index, 1);

            updatedRoomTypes.forEach((room, idx) => {
                if (room.order > index) {
                    room.order -= 1;
                }
            });

            // If we removed a base room and there are remaining rooms,
            // set the room with lowest order as the new base room
            if (isRemovingBaseRoom && updatedRoomTypes.length > 0) {
                const newBaseRoom = updatedRoomTypes.reduce((min, room) =>
                    room.order < min.order ? room : min
                    , updatedRoomTypes[0]);

                updatedRoomTypes.forEach(room => {
                    room.base_room = (room.order === newBaseRoom.order);
                });
            }
            cityProperties[selectedHotelIndex].room_types = updatedRoomTypes;
            updatedData[selectedCity.value].properties = cityProperties;

            return updatedData;
        });
    };

    useEffect(() => {
        let timeoutId;
        if (roomTypeError) {
            timeoutId = setTimeout(() => {
                setRoomTypeError("");
            }, 3000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [roomTypeError]);

    const handleCopyFromPreviousHotel = async (evt, currentIndex) => {
        evt.stopPropagation();

        // const shouldCopy = await showConfirmation("Confirm if you want to copy previous hotel details")
        const shouldCopy = await handleConfirmation(
            "Copy Configuration",
            "Confirm if you want to copy previous hotel details"
        );
        if (shouldCopy === true) {
            if (!selectedCity || currentIndex === 0) return;

            setClientProperties(prevData => {
                const updatedData = { ...prevData };
                const cityProperties = [...updatedData[selectedCity.value].properties];
                const previousHotel = cityProperties[currentIndex - 1];
                const currentHotel = cityProperties[currentIndex];

                // Copy specified properties from previous hotel
                const propertiesToCopy = {
                    property_type: previousHotel.property_type,
                    target_customers: previousHotel.target_customers,
                    established_month: previousHotel.established_month,
                    established_year: previousHotel.established_year,
                    service_types: previousHotel.service_types,
                    // service_start_date: previousHotel.service_start_date,
                    // service_end_date: previousHotel.service_end_date,
                    observation_range: previousHotel.observation_range,
                    meal_plan: previousHotel.meal_plan ? { ...previousHotel.meal_plan } : {},
                    amenities: previousHotel.amenities ? [...previousHotel.amenities] : [],
                    additional_amenities: previousHotel.additional_amenities ? [...previousHotel.additional_amenities] : [],
                    copied: true,
                };

                // Deep copy room types if they exist
                if (previousHotel.room_types) {
                    propertiesToCopy.room_types = previousHotel.room_types.map(room => ({
                        ...room,
                    }));
                }

                // Update current hotel with copied properties
                cityProperties[currentIndex] = {
                    ...currentHotel,
                    ...propertiesToCopy
                };

                updatedData[selectedCity.value].properties = cityProperties;

                // Show success message
                showToast('Successfully copied data from previous hotel', 'success');

                return updatedData;
            });
        }
        else {
            return
        }


    };

    const handleCopyFromPreviousCity = async (evt, currentCity, currentIndex) => {
        evt.stopPropagation();

        // const shouldCopy = await showConfirmation("Confirm if you want to copy previous cities hotel details")
        const shouldCopy = await handleConfirmation(
            "Copy Configuration",
            "Confirm if you want to copy previous cities hotel details"
        );
        if (shouldCopy === true) {
            // Get all cities
            const cities = Object.keys(clientProperties);
            const currentCityIndex = cities.indexOf(currentCity);

            // If this is the first city or no previous city exists, return
            if (currentCityIndex <= 0) return;

            const previousCity = cities[currentCityIndex - 1];
            const previousCityProperties = clientProperties[previousCity].properties;

            // Get the last property from previous city
            const lastPropertyFromPreviousCity = previousCityProperties[previousCityProperties.length - 1];


            setClientProperties(prevData => {
                const updatedData = { ...prevData };
                const currentCityProperties = [...updatedData[currentCity].properties];
                const currentHotel = currentCityProperties[currentIndex]

                // Properties to copy from the last property of previous city
                const propertiesToCopy = {
                    property_type: lastPropertyFromPreviousCity.property_type,
                    target_customers: lastPropertyFromPreviousCity.target_customers,
                    established_month: lastPropertyFromPreviousCity.established_month,
                    established_year: lastPropertyFromPreviousCity.established_year,
                    service_types: lastPropertyFromPreviousCity.service_types,
                    // service_start_date: lastPropertyFromPreviousCity.service_start_date,
                    // service_end_date: lastPropertyFromPreviousCity.service_end_date,
                    observation_range: lastPropertyFromPreviousCity.observation_range,
                    meal_plan: lastPropertyFromPreviousCity.meal_plan ? { ...lastPropertyFromPreviousCity.meal_plan } : {},
                    amenities: lastPropertyFromPreviousCity.amenities ? [...lastPropertyFromPreviousCity.amenities] : [],
                    additional_amenities: lastPropertyFromPreviousCity.additional_amenities ? [...lastPropertyFromPreviousCity.additional_amenities] : [],
                    room_types: lastPropertyFromPreviousCity.room_types ? lastPropertyFromPreviousCity.room_types.map(room => ({
                        ...room,
                    })) : [],
                    copied: true
                };

                // // Update all properties in current city with the copied data
                // currentCityProperties.forEach(property => {
                //     Object.assign(property, propertiesToCopy);
                // });
                // Update current hotel with copied properties
                currentCityProperties[currentIndex] = {
                    ...currentHotel,
                    ...propertiesToCopy
                };

                updatedData[currentCity].properties = currentCityProperties;

                // Show success message
                showToast('Successfully copied data from previous city', 'success');

                return updatedData;
            });
        } else {
            return
        }

    };

    const handleServiceDateChange = (index, serviceTypeValue, dateType, date) => {
        if (!selectedCity) return;

        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            const cityProperties = [...updatedData[selectedCity.value].properties];

            // Update date for specific service type
            const updatedServiceTypes = cityProperties[index].service_types.map(serviceType => {
                if (serviceType.value === serviceTypeValue) {
                    return {
                        ...serviceType,
                        [dateType]: date.format('YYYY-MM-DD')
                    };
                }
                return serviceType;
            });

            // Update the property with new service types
            cityProperties[index] = {
                ...cityProperties[index],
                service_types: updatedServiceTypes
            };

            updatedData[selectedCity.value].properties = cityProperties;
            return updatedData;
        });
    };


    const handleConfirmation = (title, message) => {
        return new Promise((resolve) => {
            setConfirmDialog({
                open: true,
                title,
                message,
                onConfirm: () => {
                    setConfirmDialog(prev => ({ ...prev, open: false }));
                    resolve(true);
                }
            });
        });
    };

    // Handle dialog closes
    const handleConfirmDialogClose = () => {
        setConfirmDialog(prev => ({ ...prev, open: false }));
    };

    useEffect(() => {
        setClientProperties((prevData) => {
            const updatedData = { ...prevData };
            if (selectedCity?.value && updatedData[selectedCity.value]) {
                updatedData[selectedCity.value].properties = updatedData[selectedCity.value].properties.map(property => ({
                    ...property,
                    status: "In Progress",
                }));
            }
            return updatedData;
        });
    }, [selectedCity]);
    // ! can add client properties to this also

    useEffect(() => {
        if (Object.keys(clientProperties).length === 0) {
            handleBack(1)
        }
    }, [clientProperties]);


    return (
        <>
            <div className='mt-4 mb-4'>
                <div className='row mt-2'>
                    <Select
                        placeholder="Select City for Configuration"
                        value={selectedCity}
                        onChange={(opt) => {
                            setSelectedCity(opt);
                        }}
                        options={Object.keys(clientProperties).map((item) => ({
                            label: item,
                            value: item
                        }))}
                        className="w-25"
                    />
                </div>

                <div className='row mt-3'>
                    {selectedCity && <h5 className='mb-2 bold text-orange'>List of Hotels in {selectedCity.value}</h5>}
                </div>

                <div className="mx-3 w-100 row mb-4 mt-2 flex justify-content-center">

                    {selectedCity && Object.keys(clientProperties).length > 0 && clientProperties[selectedCity.value].properties ? (
                        clientProperties[selectedCity.value].properties.map((item, index) => {
                            return (
                                <Accordion
                                    key={item.label}
                                    style={{
                                        borderRadius: "10px",
                                        border: "1px solid black",
                                        '&:before': {
                                            display: 'none'
                                        }
                                    }}
                                    className="mb-3 p-0"
                                    sx={{
                                        '&:before': {
                                            display: 'none'
                                        }
                                    }}
                                    expanded={expandedPanel === item.label}
                                    onChange={handleAccordionChange(item.label, index)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${item.label}-content`}
                                        id={`${item.label}-header`}
                                        className="flex items-center justify-content-center"
                                    >
                                        <div className='col-4'>
                                            <p className='m-0 bold'>{index + 1}. {item.label}</p>
                                        </div>

                                        <div className='col-1'>
                                            <p className='m-0 bold'>{returnValue(item.catagory)}</p>
                                        </div>

                                        <div className='col-2'>
                                            <Rating name="read-only" value={item.star} readOnly />
                                        </div>

                                        <div className="col-4">
                                            <div className="row">
                                                <div className="col-6">
                                                    {selectedCity && selectedCity.value !== Object.keys(clientProperties)[0] && (
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            style={{ fontSize: '0.75rem', padding: '4px 8px' }}
                                                            onClick={(evt) => { handleCopyFromPreviousCity(evt, selectedCity.value, index) }}
                                                            className="bg-orange"
                                                        >
                                                            Copy from Previous City
                                                        </Button>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    {index !== 0 &&
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            style={{ fontSize: '0.75rem', padding: '4px 8px' }}
                                                            onClick={(evt) => { handleCopyFromPreviousHotel(evt, index) }}
                                                            className="bg-orange"
                                                        >
                                                            Copy from Previous Hotel
                                                        </Button>

                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-1 ml-3'>
                                            <DeleteIcon className='text-black' onClick={(evt) => { deleteHotel(evt, item) }} />
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='mt-1 mx-2'>
                                            <p className="bold text-orange">Property Details</p>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className='' htmlFor='property-name'>Property Name</label>
                                                </div>
                                                <div className='col-8'>
                                                    <input
                                                        className="mt-1 form-control"
                                                        disabled={shouldDisable(item.hotel_name)}
                                                        value={item.hotel_name ? item.hotel_name : null}
                                                        id='property-name'
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className=''>Star Rating</label>
                                                </div>
                                                <div className='col-8'>
                                                    {/* <Rating name="simple-controlled" value={item.star} readOnly={shouldDisable(item.star)} onChange={(evt, newValue) => { handleDataChange(index, newValue, "star") }} /> */}
                                                    <Rating name="simple-controlled" value={item.star} readOnly={true} />
                                                    {/* //! onChange to be added */}
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className=''>Property Type<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='col-8'>
                                                    <Select
                                                        placeholder="Select Property Type"
                                                        options={propertyTypes}
                                                        value={propertyTypes.find(option => option.value === returnValue(item.property_type))}
                                                        onChange={(opt) => {
                                                            handleDataChange(index, opt, "property_type")
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className=''>Property Address</label>
                                                </div>
                                                <div className='col-8'>
                                                    <textarea disabled={shouldDisable(item.value)} value={returnValue(item.address)} onChange={(evt) => handleDataChange(index, evt.target.value, "address")} className='form-control' placeholder='Property Address' />
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className=''>Target Customers<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='col-8'>
                                                    <Select
                                                        placeholder="Select Target Customers"
                                                        options={targetCustomers}
                                                        value={targetCustomers.find(option => option.value === returnValue(item.target_customers))}
                                                        onChange={(opt) => {
                                                            handleDataChange(index, opt, "target_customers")
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className=''>Year of Establishment<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='col-4'>
                                                    <Select
                                                        placeholder="Select Month"
                                                        options={months}
                                                        value={months.find(option => option.value === returnValue(item.established_month))}
                                                        onChange={(opt) => {
                                                            handleDataChange(index, opt, "established_month")
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <Select
                                                        placeholder="Select Year"
                                                        options={years}
                                                        value={years.find(option => option.value === returnValue(item.established_year))}
                                                        onChange={(opt) => {
                                                            handleDataChange(index, opt, "established_year")
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label className=''>Observation Range<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='col-8'>
                                                    <input
                                                        className="mt-1 form-control"
                                                        type='number'
                                                        value={item.observation_range ? item.observation_range : null}
                                                        id='observation-range'
                                                        placeholder='Enter Observation Range'
                                                        onChange={(e) => {
                                                            handleDataChange(index, parseInt(e.target.value), "observation_range")
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>



                                            <p className="bold text-orange mt-4">Additional Details</p>

                                            <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label htmlFor="">Zetta Service Type<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='col-8'>
                                                    <Select
                                                        placeholder="Select Service Types"
                                                        options={serviceTypes}
                                                        isMulti
                                                        value={returnValue(item.service_types)}
                                                        onChange={(opt) => {
                                                            handleDataChange(index, opt, "service_types")
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {item.service_types &&
                                                item.service_types.map((serviceType) => {
                                                    return (
                                                        <div className='mt-3 row'>
                                                            <div className='col-4'>
                                                                <label htmlFor="">Service Date Range for {serviceType.label}<span style={{ color: 'red' }}>*</span></label>
                                                            </div>
                                                            <div className='col-4'>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        format="YYYY-MM-DD"
                                                                        value={returnValue(serviceType.start_date) ? dayjs(serviceType.start_date) : null}
                                                                        slotProps={{
                                                                            textField: {
                                                                                size: "small",
                                                                                style: { width: '100%' }
                                                                            }
                                                                        }}
                                                                        sx={{
                                                                            ...commonSx,
                                                                            '& .MuiInputBase-root': {
                                                                                height: '40px',
                                                                                padding: '8px 14px',
                                                                            },
                                                                        }}
                                                                        minDate={dayjs()}
                                                                        onChange={(date) => handleServiceDateChange(index, serviceType.value, 'start_date', date)}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div className='col-4'>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        format="YYYY-MM-DD"
                                                                        value={returnValue(serviceType.end_date) ? dayjs(serviceType.end_date) : null}
                                                                        slotProps={{
                                                                            textField: {
                                                                                size: "small",
                                                                                style: { width: '100%' }
                                                                            }
                                                                        }}
                                                                        sx={{
                                                                            ...commonSx,
                                                                            '& .MuiInputBase-root': {
                                                                                height: '40px',
                                                                                padding: '8px 14px',
                                                                            },
                                                                        }}
                                                                        minDate={dayjs(serviceType.start_date) || dayjs()}
                                                                        onChange={(date) => handleServiceDateChange(index, serviceType.value, 'end_date', date)}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    )
                                                })

                                            }

                                            {/* <div className='row mt-3'>
                                                <div className='col-4'>
                                                    <label htmlFor="">Service Date Range<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className='col-4'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            // label="Service Start Date"
                                                            format="YYYY-MM-DD"
                                                            value={returnValue(item.service_start_date) ? dayjs(item.service_start_date) : null}
                                                            slotProps={{
                                                                textField: {
                                                                    size: "small",
                                                                    style: { width: '100%' }
                                                                },
                                                                // actionBar: {
                                                                //     actions: ['clear'],
                                                                // },
                                                            }}
                                                            sx={{
                                                                ...commonSx,
                                                                '& .MuiInputBase-root': {
                                                                    height: '40px',
                                                                    padding: '8px 14px',
                                                                },
                                                            }}
                                                            minDate={dayjs()}
                                                            onChange={(date) => handleDataChange(index, date, "service_start_date")}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className='col-4'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            // label="Service End Date"
                                                            format="YYYY-MM-DD"
                                                            value={returnValue(item.service_end_date) ? dayjs(item.service_end_date) : null}
                                                            slotProps={{
                                                                textField: {
                                                                    size: "small",
                                                                    style: { width: '100%' }
                                                                },
                                                                // actionBar: {
                                                                //     actions: ['clear'],
                                                                // },
                                                            }}
                                                            sx={{
                                                                ...commonSx,
                                                                '& .MuiInputBase-root': {
                                                                    height: '40px',
                                                                    padding: '8px 14px',
                                                                },
                                                            }}
                                                            minDate={dayjs(item.service_start_date) || dayjs()}
                                                            onChange={(date) => handleDataChange(index, date, "service_end_date")}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div> */}

                                            <p className="bold text-orange mt-3">Feasible Meal Plans (optional)</p>

                                            {
                                                mealPlans && mealPlans.map((mealPlan) => (
                                                    mealPlan.label !== "EP" && (
                                                        <div className='row mt-3' key={mealPlan.label}>
                                                            <div className='col-4 d-flex align-items-center'>
                                                                <div
                                                                    className="col-4 d-flex justify-content-center align-items-center"
                                                                    style={{
                                                                        border: '1px solid #343a40',
                                                                        borderRadius: '50px',
                                                                        height: '30px',
                                                                        lineHeight: '50px',
                                                                        padding: '0 20px',
                                                                    }}
                                                                    title={mealPlan.meal_plan_name}
                                                                >
                                                                    {mealPlan.label}
                                                                </div>
                                                            </div>
                                                            <div className='col-8'>
                                                                <input
                                                                    type="number"
                                                                    className="mt-1 form-control"
                                                                    id={`base-fare-${mealPlan.label}`}
                                                                    value={selectedCity && clientProperties[selectedCity.value].properties[index]?.meal_plan?.[mealPlan.label] || ''}
                                                                    onChange={(e) => handleMealPlanChange(index, mealPlan.label, e.target.value)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === '-' || e.key === 'e') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    placeholder={`Enter base fare for ${mealPlan.meal_plan_name}`}
                                                                    min={0}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                ))
                                            }

                                            <div className='mt-3 row d-flex justify-content-between'>
                                                <p className="bold text-orange col-3">Room Details</p>
                                                <Button
                                                    className="bg-orange"
                                                    variant="contained"
                                                    size="small"
                                                    sx={{
                                                        width: '200px',
                                                    }}
                                                    onClick={() => { handleRoomDetailModalOpen(index, item.label) }}
                                                >
                                                    Add New Rooms
                                                </Button>
                                            </div>

                                            <div className="mt-4 p-1 mb-3">
                                                <div className='w-100 d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <p className='bold text-orange'>Room Priority Order</p>
                                                        <p className='text-muted small'>Rearrange the tiles to set the priority order of room types.</p>
                                                    </div>
                                                    <div className='mx-4'>
                                                        <Tooltip title="Set priority order for room types">
                                                            <IconButton>
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                {
                                                    item.room_types && item.room_types.length ?
                                                        <>
                                                            <Box>
                                                                <SortableContainer onSortEnd={onSortEnd} shouldCancelStart={(e) => {

                                                                    return e.target.closest('.no-drag');
                                                                }}>
                                                                    {item.room_types.map((type, index) => (
                                                                        <SortableItem key={index} index={index} rtIndex={index} roomType={type} />
                                                                    )
                                                                    )}
                                                                </SortableContainer>
                                                            </Box>
                                                        </>
                                                        :
                                                        <Alert severity="info">No room types configured</Alert>
                                                }
                                            </div>


                                            <p className='bold mt-3 text-orange'>Amenities</p>

                                            <div className="mt-3 w-100 row">
                                                {/* <div className="col-3">
                                                    <div class="form-check">
                                                        <input id="Air-conditioned" class="form-check-input" type="checkbox" value="" />
                                                        <label class="form-check-label" for="Air-conditioned">
                                                            <i class="fa-solid fa-wind mx-2"></i> Air conditioned
                                                        </label>
                                                    </div>
                                                </div> */}
                                                {amenities.map((amenity) => (
                                                    // <PillCheckbox
                                                    //     key={amenity.label}
                                                    //     label={amenity.label}
                                                    //     isChecked={true}
                                                    // // onChange={() => toggleAmenity(amenity)}
                                                    // />
                                                    <div
                                                        key={amenity.label}
                                                        className={`col-2 mx-2 my-2 p-2 pill fixed-width-pill cursor-pointer ${isAmenitySelected(index, amenity.label) ? 'bg-orange text-white' : 'bg-white text-gray-700 border border-orange'
                                                            }`}
                                                        title={amenity.label}
                                                        onClick={() => handleAmenityToggle(index, amenity.label)}
                                                        style={{ cursor: 'pointer', transition: 'all 0.2s ease-in-out' }}
                                                    >
                                                        {/* <i className={`${amenity.icon} mx-2`}></i> */}
                                                        <span class="material-symbols-outlined mx-1">
                                                            {amenity.icon}
                                                        </span>
                                                        <span>{amenity.label}</span>
                                                    </div>

                                                ))}
                                                <div className="mt-3 row">
                                                    {item.additional_amenities && item.additional_amenities.map((amenity, amenityIndex) => (
                                                        <div
                                                            key={amenityIndex}
                                                            className="col-2 mx-2 my-2 p-2 pill fixed-width-pill bg-orange text-white"
                                                            style={{ position: 'relative' }}
                                                        >
                                                            <i className="fa-solid fa-plus mx-2"></i>
                                                            {amenity}
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleAdditionalAmenityRemove(index, amenityIndex)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '8px',
                                                                    backgroundColor: 'white',
                                                                    padding: '2px'
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="small" style={{ color: '#dc3545' }} />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className='mt-3 row'>
                                                <div className='col-4'>
                                                    <label className=''>Additional Amenities</label>
                                                </div>
                                                <div className='col-6'>
                                                    <input
                                                        className="mt-1 form-control"
                                                        value={additionalAmenities[index] || ''}
                                                        onChange={(e) => handleAdditionalAmenityChange(index, e.target.value)}
                                                        placeholder='Enter Amenity'
                                                    />
                                                </div>
                                                <div className='col-2'>
                                                    <Button
                                                        variant="contained"
                                                        className="bg-orange"
                                                        onClick={() => handleAdditionalAmenityAdd(index)}
                                                        disabled={!additionalAmenities[index]?.trim()}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            </div>

                                            <div>
                                                <FocusCompetition focusComp={focusComp} setFocusComp={setFocusComp} selectedHotel={{ "value": item['value'], "city": item.city, "latitude": item.latitude, "longitude": item.longitude, "hotel_name": item.label }} clientProperties={clientProperties} setClientProperties={setClientProperties} preferredOTA={preferredOTA} propertyIndex={index} selectedCity={selectedCity} mealPlans={mealPlans} roomTypes={roomTypes} hotelName={item.label} />
                                            </div>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })
                    ) : (
                        <Alert severity="warning">No City Selected</Alert>
                    )}
                </div>


            </div>
            <Dialog
                open={roomDetailsOpen}
                onClose={handleRoomDetailsModalClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">Add New Room</Typography>
                        <IconButton
                            onClick={handleRoomDetailsModalClose}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <p className="text-orange">Hotel Name: <span className="text-black">{selectedHotel}</span></p>
                    </div>
                    <div className='row mt-3'>
                        <div className="d-flex flex-column col-12">
                            <label htmlFor="">Room Type</label>
                            <Select
                                id="room-type"
                                options={roomTypes}
                                value={roomTypes.find(option => option.label === newRoomType.type_name) || null}
                                onChange={(selected) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        type_name: selected ? selected.label : ''
                                    })
                                }
                                placeholder="Select Room Type"
                                className="basic-select mt-1"
                                classNamePrefix="select"
                                isDisabled={isEditing}
                            />

                        </div>
                    </div>

                    {/* <div className='row mt-3'>
                        <div className="d-flex flex-column col-12">
                            <label htmlFor="">Meal Plan</label>
                            <Select
                                id="room-type"
                                options={mealPlans}
                                value={mealPlans.find(option => option.label === newRoomType.meal_plan) || null}
                                onChange={(selected) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        meal_plan: selected ? selected.label : ''
                                    })
                                }
                                placeholder="Select Meal Type"
                                className="basic-select mt-1"
                                classNamePrefix="select"
                                isDisabled={isEditing}
                            />

                        </div>
                    </div> */}

                    <div className='row mt-3'>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Bed Type</label>
                            <Select
                                id="bed-type"
                                options={bedTypes}
                                value={bedTypes.find(option => option.label === newRoomType.bed_type) || null}
                                onChange={(selected) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        bed_type: selected ? selected.label : ''
                                    })
                                }
                                placeholder="Select Bed Type"
                                className="basic-select mt-1"
                                classNamePrefix="select"
                            />

                            {/* <MultiSelect
                                id="bed-type"
                                options={bedTypes}
                                // value={bedTypes.find(option => option.label === newRoomType.bed_type) || null}
                                value={bedTypes.filter(option =>
                                    newRoomType.bed_type && newRoomType.bed_type.includes(option.label)
                                )}
                                onChange={(selected) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        bed_type: selected ? selected.map(item => item.label) : []
                                    })
                                }
                                isMulti
                                placeholder="Select Bed Type"
                                className="basic-select mt-1"
                                classNamePrefix="select"
                                labelledBy="Select Bed Type"
                                overrideStrings={{
                                    selectSomeItems: "Select Bed Type",
                                }}
                            /> */}


                        </div>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Carpet Area</label>
                            <input
                                type="number"
                                className="mt-1 form-control"
                                placeholder="Carpet Area"
                                aria-label="carpet area"
                                value={newRoomType.sq_ft_area}
                                onChange={(e) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        sq_ft_area: parseFloat(e.target.value)
                                    })
                                }

                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">No. of Rooms</label>
                            <input
                                type="number"
                                className="mt-1 form-control"
                                placeholder="No. of Rooms"
                                aria-label="no of rooms"
                                value={newRoomType.count}
                                onChange={(e) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        count: parseInt(e.target.value)
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Base Room <Tooltip placement="top" title="Lowest Priced Room"><InfoIcon size="small" /></Tooltip></label>

                            <Select
                                placeholder="Select Yes or No"
                                options={[
                                    { value: true, label: "Yes" },
                                    { value: false, label: "No" },
                                ]}
                                value={{
                                    value: newRoomType.base_room,
                                    label: newRoomType.base_room === true ? "Yes" : "No"
                                }}
                                onChange={(selected) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        base_room: selected.value
                                    })
                                }
                                isDisabled={isFirstRoom}
                            />

                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Default Occupancy <Tooltip placement="top" title="Default No of Guests the room can hold"><InfoIcon size="small" /></Tooltip></label>
                            <input
                                type="number"
                                className="mt-1 form-control"
                                placeholder="Default Occupancy"
                                aria-label="default occupancy"
                                onChange={(e) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        default_occupancy: parseInt(e.target.value)
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                value={newRoomType.default_occupancy}

                            />
                        </div>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Max Occupancy <Tooltip placement="top" title="Maximum No of Guests the room can hold"><InfoIcon size="small" /></Tooltip></label>
                            <input
                                type="number"
                                className="mt-1 form-control"
                                placeholder="Max Occupancy"
                                aria-label="max occupancy"
                                value={newRoomType.max_occupancy}
                                onChange={(e) =>
                                    setNewRoomType({
                                        ...newRoomType,
                                        max_occupancy: parseInt(e.target.value)
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                            />

                        </div>
                    </div>
                    {roomTypeError && (
                        <Alert severity="error" className="mb-4 mt-4">
                            {roomTypeError}
                        </Alert>
                    )}
                    {isFirstRoom && (
                        <Alert severity="info" className="mb-4 mt-4">
                            This is the first room type. It will automatically be set as the base room.
                        </Alert>
                    )}
                    <div className='row mt-3 w-100 mb-4'>
                        {isEditing ? <>
                            <div className='col-12 d-flex justify-content-center'>
                                <Button variant="outlined" className='text-orange border-orange' onClick={() => { handleUpdateRoomType(true) }}>Save</Button>
                            </div>
                        </> :
                            <>
                                <div className='col-6 d-flex justify-content-center'>
                                    <Button variant="outlined" className='text-orange border-orange' onClick={() => { handleAddRoomType(false) }}>Save & Add New</Button>
                                </div>
                                <div className='col-6 d-flex justify-content-center '>
                                    <Button variant="contained" className='bg-orange' onClick={() => { handleAddRoomType(true) }}>Save & Close</Button>
                                </div>
                            </>
                        }
                    </div>
                </DialogContent>
            </Dialog>

            <ConfirmationDialog
                open={confirmDialog.open}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onConfirm={confirmDialog.onConfirm}
                onCancel={handleConfirmDialogClose}
            />
        </>
    )
}

export default ClientPropertiesV2