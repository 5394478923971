import React, { useEffect, useRef } from "react";
import { useSessionTracker } from "../../context/SessionTrackerContext";
import axios from "axios";
import { fetchToken } from "../../Auth";

const DashboardSessionTracker = ({ dashboardId }) => {
  const sessionStartTimeRef = useRef(null);
  const { registerTracker, unregisterTracker } = useSessionTracker();

  const sendUsageData = async () => {
    if (!sessionStartTimeRef.current) return;

    const endTime = Date.now();
    const timeSpent = (endTime - sessionStartTimeRef.current) / 60000; // Time in minutes

    try {
      await axios.post(
        `/dashboard_usage`,
        {
          dashboardId,
          timeSpent,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error sending usage data:", error);
    } finally {
      sessionStartTimeRef.current = null; // Reset session start time
    }
  };

  const startNewSession = () => {
    sessionStartTimeRef.current = Date.now(); // Set session start time
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      // Tab becomes inactive: send usage data
      sendUsageData();
    } else if (document.visibilityState === "visible") {
      // Tab becomes active: start a new session
      startNewSession();
    }
  };

  const handleBeforeUnload = (event) => {
    // Send usage data before the browser or tab is closed
    event.returnValue = ""; // Some browsers require this for confirmation dialog
  };

  useEffect(() => {
    startNewSession();

    // Register the tracker function for centralized tracking
    registerTracker(sendUsageData);

    // Add event listeners for visibility change and beforeunload
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup event listeners and send final usage data
      unregisterTracker(sendUsageData);
      sendUsageData();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return null;
};

export default DashboardSessionTracker;
