import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FocusComp from "./FocusComp";
import RateParity from "./RateParity";
import RoomDetails from "./RoomDetails";
import PriceHistoryDialogue from "./PriceHistoryDialogue";
import Bookings from "./Bookings";

import {
  format,
  addDays,
  startOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import PriceHistory from "./PriceHistory";
import Chip from "@mui/material/Chip";
import { keyframes } from "@mui/system";
import TrainIcon from "@mui/icons-material/Train";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import soldOut from "../../../assets/img/sold-out-2.png";
import rightArrow from "../../../assets/img/rightArrow.png";
import leftArrow from "../../../assets/img/leftArrow.png";
import StraightIcon from "@mui/icons-material/Straight";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "./FlagIcon";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Button from '@mui/material/Button';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../../Auth";
import InsightsIcon from '@mui/icons-material/Insights';
import soldOutImg from "../../../assets/img/sold_out_6.png";




const Calendar = ({
  today,
  ota,
  selfDataSource,
  pms,
  tenantId,
  data,
  equRoomData,
  hotelDetails,
  trainData,
  selectedMonth,
  selectedYear,
  selectedProperty,
  selectedPropertyName,
  filters,
  selectedFlagMonth,
  selectedFlagYear,
  calLength,
  startDate,
  endDate,
  filterOpen,
  mealPlanNames,
  clientDataSources,
  userRole
}) => {
  const navigate = useNavigate();

  const dialogContentRef = useRef(null);
  const cardHEIGHT = "20em"
  const [open, setOpen] = useState(false);

  const [modalDate, setModalDate] = useState("");
  const [updateTime, setUpdateTime] = useState("");
  const [modalData, setModalData] = useState({});

  const [expanded, setExpanded] = useState(false);
  const [tab, setTab] = useState("tab1")

  const [priceHistoryOpen, setPriceHistoryOpen] = useState(false);

  const [clubedRoomDetails, setClubedRoomDetails] = useState({});

  const [bookings, setBookings] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabSwitch = (event, newValue) => {
    setTab(newValue);

    // Scroll to bottom after state update and render
    requestAnimationFrame(() => {
      if (dialogContentRef.current) {
        // dialogContentRef.current.scrollTop = dialogContentRef.current.scrollHeight;
        dialogContentRef.current.scrollTop = 300;
      }
    });
  };


  const [weekDayStart, setWeekDayStart] = useState()

  function getDynamicDaysOfWeek(startDay) {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const startIndex = daysOfWeek.indexOf(startDay);

    if (startIndex === -1) {
      throw new Error("Invalid start day");
    }

    return [...daysOfWeek.slice(startIndex), ...daysOfWeek.slice(0, startIndex)];
  }


  useEffect(() => {
    const startDate = new Date(today);

    const startingDay = getDayOfWeek(startDate);
    const dynamicDaysOfWeek = getDynamicDaysOfWeek(startingDay);
    setWeekDayStart(dynamicDaysOfWeek)

  }, [today])

  let meal_plan_names = {
    "EP": "European Plan",
    "CP": "Continental Plan",
    "AP": "American Plan",
    "MAP": "Modified American Plan",
  }

  const PillShapedButton = ({ children, onClick, startIcon, classes }) => {
    return (
      <Button
        onClick={onClick}
        size="small"
        variant="contained"
        startIcon={startIcon}
        className={classes}
        sx={{
          borderRadius: '50px',
          paddingLeft: '16px',
          paddingRight: '16px',
          minWidth: 'auto',
          height: '30px',
          marginRight: '1em',
          backgroundColor: 'black',
          '&:hover': {
            backgroundColor: 'black', // Keep black on hover
          },
          '& .MuiButton-startIcon': {
            marginRight: '8px',
          },
        }}
      >
        {children}
      </Button>
    );
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];



  const [filterNames, setFilterNames] = useState([
    {
      name: "Competition Price Increase",
      val: "comp_price_inc",
      color: "rgb(255, 174, 1)",
      icon: "inc",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CP",
      flagShort: "Comp price",
    },
    {
      name: "Competition Price Decrease",
      val: "comp_price_dec",
      color: "rgb(255, 174, 1)",
      icon: "dec",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CP",
      flagShort: "Comp price",
    },
    {
      name: "OverPriced",
      flagShort: "Overpriced",
      val: "overpriced",
      color: "#f68d8e",
      icon: "inc",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "OP",
    },
    {
      name: "UnderPriced",
      flagShort: "Underpriced",
      val: "underpriced",
      color: "#f68d8e",
      icon: "dec",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "UP",
    },
  ]);

  // Get the current date and find the first Monday before or on the current date
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
  // Generate the next 365 days
  const days = Array.from({ length: calLength }, (_, i) =>
    addDays(startOfCurrentWeek, i)
  );
  const refs = useRef({});

  const dummyData = Array.from({ length: 9 }, (_, i) => `Card ${i + 1}`);

  const DayItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.7),
    textAlign: "center",
    color: "#000c",
    fontWeight: "bold",
  }));

  // const Card = styled(Box)(({ theme, bg }) => ({
  //   padding: theme.spacing(1),
  //   color: theme.palette.text.secondary,
  //   height: "15em",
  //   minHeight: "15em",
  //   maxHeight: "15em",
  //   width: "100%",
  //   border: filters.length ? "2px solid #ededed" : "1px solid #ededed",
  //   marginTop: "0.55em",
  //   borderRadius: "4px",
  //   backgroundColor: "#f8f8f8",
  //   // backgroundColor:
  //   //   bg === "yellow" ? "#F4E07A" : bg === "red" ? "#f68d8e" : "#f8f8f8",
  //   cursor: "pointer",
  // }));

  const Card = styled(Box)(({ theme, bg }) => ({
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: cardHEIGHT,        // Increased from 15em
    minHeight: cardHEIGHT,     // Increased from 15em
    maxHeight: cardHEIGHT,     // Increased from 15em
    width: "100%",
    border: filters.length ? "2px solid #ededed" : "1px solid #ededed",
    marginTop: "0.55em",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    cursor: "pointer",
  }));

  const rippleEffect = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;

  const RippleContainer = styled("div")(({ theme }) => ({
    display: "inline-flex", // Change to inline-flex
    position: "relative",
    borderRadius: "16px", // Match Chip's default borderRadius
    animation: `${rippleEffect} 1.5s infinite`,
    // padding: '2px', // Add slight padding around the Chip
  }));
  const StickyHeader = styled(Box)(({ theme }) => ({
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  }));

  function deslugify(slug) {
    return slug
      .split("-") // Split the string by hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with spaces
  }

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  useEffect(() => {
    const element = document.querySelector(
      `[data-card="${selectedMonth}-${selectedYear}"]`
    );
    const calendarContainer = document.querySelector(".calender-card");

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = calendarContainer.getBoundingClientRect();
      const offset = elementRect.top - containerRect.top;

      calendarContainer.scrollBy({
        top: offset - 1000,
        behavior: "smooth",
      });
    }
  }, [data]);



  const handlePrevNextClick = (type, modalDate) => {
    let newDate = new Date(modalDate);

    const formattedModalDate = format(newDate, "yyyy-MM-dd");
    const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
    const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");

    if (type === "prev") {
      // If moving to the previous day would go below the start date, return
      if (formattedModalDate <= formattedStartDate) {
        return;
      }
      newDate.setDate(newDate.getDate() - 1);
    } else if (type === "next") {
      // If moving to the next day would go beyond the end date, return
      if (formattedModalDate >= formattedEndDate) {
        return;
      }
      newDate.setDate(newDate.getDate() + 1);
    }

    // Update the modalDate and modalData
    const newFormattedDate = format(newDate, "yyyy-MM-dd");
    setModalDate(newFormattedDate);

    if (newFormattedDate in data) {
      setModalData(data[newFormattedDate]);
    } else {
      setModalData({});

    }
  };
  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  }
  function convertDateFormat(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  const handleTileClick = (date, updateTime) => {
    setModalDate(date);
    if (format(date, "yyyy-MM-dd") in data) {
      // if (data[format(date, "yyyy-MM-dd")]["master_doc_missing"]) {
      //   if (format(date, "yyyy-MM-dd") in equRoomData) {
      //     setModalData(equRoomData[format(date, "yyyy-MM-dd")]);
      //   }
      // } else {
      // }
      setModalData(data[format(date, "yyyy-MM-dd")]);
    } else {
      setModalData({});
    }
    setUpdateTime(updateTime);

    getBookings(tenantId, selectedProperty, date)

    setOpen(true);


  };
  const handleFilter = (flags) => {
    if (flags.length == 0 && filters.length > 0) {
      return "no-match";
    }
    if (!flags || !filters || filters.length === 0) {
      return "";
    }

    const flagSet = new Set(flags);
    const filterSet = new Set(filters);

    if (
      flagSet.size === filterSet.size &&
      [...flagSet].every((value) => filterSet.has(value))
    ) {
      return "exact-match";
    } else if (filters.some((filter) => flagSet.has(filter))) {
      return "similar-match";
    } else {
      return "no-match";
    }
  };
  // function getDayOfWeek(dateString) {
  //   const daysOfWeek = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   const date = new Date(dateString);
  //   return daysOfWeek[date.getDay()];
  // }
  function convertDateFormat(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  const fontSizeCal = {
    fontSize: "1.2em",
  };

  const getLowestPriceForRoomType = (data, anchor_room_type) => {
    const roomPrices = {};
    const roomLimit = 2
    // Collect room prices as before
    Object.entries(data).forEach(([key, value]) => {
      try {
        if (value.room_type === anchor_room_type) {
          return;
        }

        const [roomTypeId, mealPlanId] = key.split('|');

        if (value.is_sold_out) {
          return;
        }

        if (!roomPrices[roomTypeId]) {
          roomPrices[roomTypeId] = {
            room_type: value.room_type,
            prices: []
          };
        }

        roomPrices[roomTypeId]['available_room_count'] = value.available_room_count || ""

        if (value?.['price_info'] && 1 in value['price_info']) {
          roomPrices[roomTypeId].prices.push(value.price_info[1]);
        }

      } catch (error) {
        console.error("Error in iteration of getLowestPriceForRoomType", error)
      }
    });

    return Object.entries(roomPrices)
      .map(([roomTypeId, data]) => ({
        room_type: data.room_type,
        room_type_id: roomTypeId,
        lowest_price: data?.['prices'] && data['prices'].length ? Math.round(Math.min(...data.prices)) : null,
        available_room_count: data.available_room_count,
      }))
      .sort((a, b) => {
        if (a.lowest_price === null) return 1;
        if (b.lowest_price === null) return -1;

        return a.lowest_price - b.lowest_price;
      })
      .slice(0, roomLimit);
  };

  const getOverAllOccupancy = (inventory_data, anchor_total, anchor_available, date) => {
    try {
      let overall_avl = 0;
      let overall_inv = 0;
      Object.entries(inventory_data).forEach(([key, value]) => {
        if (value.available_room_count) {
          overall_avl += value.available_room_count
        }
        if (value.total_room_count) {
          overall_inv += value.total_room_count
        }
      });

      if (anchor_total !== null && anchor_available !== null) {
        overall_avl += anchor_available
        overall_inv += anchor_total
      }


      if (overall_inv) {
        let availability_percentage = Math.round((overall_avl / overall_inv) * 100)

        return `${100 - availability_percentage} %`
      }

    } catch (error) {

    }
    return "-"
  }

  const processRoomEntries = (data, date, selfDataSource) => {
    try {
      const anchorRoomType = data[format(date, "yyyy-MM-dd")]["room_type_id"];
      const anchorRoomTypeName = data[format(date, "yyyy-MM-dd")]["room_type"];

      // Get the two lowest-priced room types
      const lowestPricedRooms = getLowestPriceForRoomType(
        data[format(date, "yyyy-MM-dd")]["self"][selfDataSource],
        data[format(date, "yyyy-MM-dd")]["room_type"]
      ).map(room => ({
        roomTypeId: room.room_type_id,
        roomTypeName: room.room_type || "",
        available_room_count: room.available_room_count || ''
      }));

      const relevantRoomIds = [String(anchorRoomType), ...lowestPricedRooms.map(room => room.roomTypeId)];

      const entries = relevantRoomIds.map(roomTypeId => {
        const roomFromLowest = lowestPricedRooms.find(room => room.roomTypeId === roomTypeId);
        return {
          roomTypeId,
          availableRoomCount: roomTypeId == anchorRoomType ? data[format(date, "yyyy-MM-dd")]?.available_room_count ? data[format(date, "yyyy-MM-dd")]['available_room_count'] : "-" : roomFromLowest?.available_room_count ?? "-",
          roomTypeName: roomTypeId == anchorRoomType ? anchorRoomTypeName : roomFromLowest?.roomTypeName || "",
        };
      });

      // Sort entries to ensure anchor room is first
      entries.sort((a, b) => {
        if (a.roomTypeId === anchorRoomType) return -1;
        if (b.roomTypeId === anchorRoomType) return 1;
        return 0;
      });

      return entries.map((entry, index) => (
        <span key={entry.roomTypeId} title={entry.roomTypeName} className={index === 0 ? "bold" : null}>
          {index > 0 && " | "}
          {entry.availableRoomCount ? entry.availableRoomCount : "-"}
        </span>
      ));
    } catch (error) {
      console.error(error)
    }
  };

  const getBookings = async (tenantId, propertyId, date) => {
    try {
      const master_id = `${tenantId}$${propertyId}$${date}`

      const payload = {
        "master_id": master_id
      }
      const response = await axios.post("/fetch_bookings", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      })

      if (response.status === 200) {
        setBookings(response.data);
      }

    } catch (error) {
      console.error("Error fetching bookings", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  }

  const handleMasterModalClose = () => {
    setOpen(false);
    setExpanded("panel1");
    setTab("tab1")
    setBookings({})
  }

  const isSoldOut = (data) => {

    if (data?.['is_sold_out']?.[selfDataSource] === true && !Object.keys(data?.['equivalent_room']?.[selfDataSource]).length) {
      return true
    }
    return false


  }

  const flagActive = true;

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box className="w-100" sx={{ flexGrow: 1, width: "100%" }}>
        <Grid container spacing={1}>
          <Grid
            container
            spacing={0.6}
            // sx={{ position: "sticky", top:'7.9em', zIndex: 1 }}
            sx={{
              position: "sticky",
              top: "0em",
              zIndex: 1,
              marginBottom: "0.5em",
            }}
          >
            {daysOfWeek && daysOfWeek.map((day, index) => (

              <Grid
                style={{ backgroundColor: "#eee", ...fontSizeCal }}
                item
                xs={1.71}
                key={index}
              >
                <DayItem>{day}</DayItem>
              </Grid>
            ))}
          </Grid>
          {/* {console.log(data,'this is the data')} */}
          {daysOfWeek && daysOfWeek.map((day, index) => (
            <Grid item xs={1.71} key={day}>
              {days && days
                .filter((date) => date.getDay() === (index + 1) % 7)
                // .filter(date => format(date, 'yyyy-MM-dd') in data)
                .map((date, i) => {
                  const formattedDate = format(date, "yyyy-MM-dd");
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {formattedDate && formattedDate in data ? (<>
                        <Card
                          className={(() => {
                            const [dateYear, dateMonth] = formattedDate.split("-");
                            let finalArr = [];
                            if (
                              trainData &&
                              typeof trainData[formattedDate] !== "undefined" &&
                              "train" in trainData[formattedDate] &&
                              trainData[formattedDate]["train"].length &&
                              trainData[formattedDate]["train"].includes(
                                "DemandSpike"
                              )
                            ) {
                              finalArr.push("DemandSpike");
                            }

                            // Check if the date is in the correct year and month
                            if (
                              selectedFlagYear === null ||
                              selectedMonth === null
                            ) {
                              if (
                                formattedDate in data &&
                                "flags" in data[formattedDate] &&
                                data[formattedDate]["flags"][ota] &&
                                data[formattedDate]["flags"][ota].length > 0
                              ) {
                                return handleFilter([
                                  ...finalArr,
                                  ...data[formattedDate]["flags"][ota],
                                ]);
                              }
                              else {
                                return handleFilter([]);

                              }
                            } else if (
                              dateYear === selectedFlagYear.toString() &&
                              dateMonth ===
                              selectedFlagMonth.toString().padStart(2, "0")
                            ) {
                              // If date is in the correct year and month, proceed with the original logic
                              if (
                                formattedDate in data &&
                                "flags" in data[formattedDate] &&
                                data[formattedDate]["flags"][ota] &&
                                data[formattedDate]["flags"][ota].length > 0
                              ) {
                                return handleFilter([
                                  ...finalArr,
                                  ...data[formattedDate]["flags"][ota],
                                ]);
                              }
                            }
                            // If conditions are not met, return an empty string or a default class
                            return "";
                          })()}
                          onClick={() =>
                            format(date, "yyyy-MM-dd") in data
                              ? handleTileClick(
                                format(date, "yyyy-MM-dd"),
                                data[format(date, "yyyy-MM-dd")]["update_time"],
                                data[format(date, "yyyy-MM-dd")],
                                ota,
                                data[format(date, "yyyy-MM-dd")]["property_id"],
                                data[format(date, "yyyy-MM-dd")]["tenant_id"],
                                pms
                              )
                              : null
                          }
                          key={i}
                          style={{ margin: "7px 0" }}
                          bg={
                            format(date, "yyyy-MM-dd") in data &&
                              "flags" in data[format(date, "yyyy-MM-dd")] && typeof data[format(date, "yyyy-MM-dd")]["flags"].ota !== "undefined" &&
                              data[format(date, "yyyy-MM-dd")]["flags"][ota].length
                              ? data[format(date, "yyyy-MM-dd")]["flags"][
                                ota
                              ].includes("comp_price_inc")
                                ? "yellow"
                                : data[format(date, "yyyy-MM-dd")]["flags"][
                                  ota
                                ].includes("comp_price_dec")
                                  ? "yellow"
                                  : data[format(date, "yyyy-MM-dd")]["flags"][
                                    ota
                                  ].includes("comp_price_inc")
                                    ? "yellow"
                                    : data[format(date, "yyyy-MM-dd")]["flags"][
                                      ota
                                    ].includes("comp_price_dec")
                                      ? "yellow"
                                      : data[format(date, "yyyy-MM-dd")]["flags"][
                                        ota
                                      ].includes("underpriced")
                                        ? "red"
                                        : data[format(date, "yyyy-MM-dd")]["flags"][
                                          ota
                                        ].includes("overpriced")
                                          ? "red"
                                          : ""
                              : ""
                          }
                        >
                          {/* <Tooltip title={data[format(date, "yyyy-MM-dd")]["flags"][ota]} style={{ postion: 'absoulte', top: '50%' }} arrow> */}
                          <div
                            data-card={format(date, "M-yyyy")}
                            className="d-flex flex-column justify-content-between"
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div style={{ width: "8em", paddingRight: "0" }}>
                                {isSameDate(date, new Date(today)) ? (
                                  <RippleContainer>
                                    <Chip
                                      label={format(date, "dd/MM/yyyy")}
                                      size="small"
                                      sx={{
                                        fontSize: "0.8em",
                                        fontWeight: "500",
                                        // backgroundColor: "#FF8B00",
                                        backgroundColor: "black",

                                        color: "white",
                                      }}
                                    />
                                  </RippleContainer>
                                ) : (
                                  <>
                                    <div>
                                      <Chip
                                        label={format(date, "dd/MM/yyyy")}
                                        size="small"
                                        sx={{
                                          fontSize: "0.8em",
                                          fontWeight: "300",
                                          backgroundColor: "#29335c",
                                          color: "white",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="d-flex flex-end flex-gap gap-1">
                                <div>
                                  {/* {format(date, "yyyy-MM-dd") in equRoomData &&
                                    "is_equ_room" in
                                    equRoomData[format(date, "yyyy-MM-dd")] &&
                                    equRoomData[format(date, "yyyy-MM-dd")][
                                    "is_equ_room"
                                    ] ? (
                                    <Tooltip
                                      title={`${equRoomData[format(date, "yyyy-MM-dd")][
                                        "room_type"
                                      ]
                                        } | ${equRoomData[format(date, "yyyy-MM-dd")][
                                        "meal_plan"
                                        ]
                                        }`}
                                    >
                                      <AutoFixHighIcon
                                        style={{
                                          color: "orange",
                                          fontSize: "1.5em",
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null} */}

                                  {
                                    format(date, "yyyy-MM-dd") in data &&
                                    data[format(date, "yyyy-MM-dd")] &&
                                    isSoldOut(data[format(date, "yyyy-MM-dd")]) &&
                                    <Tooltip title="Hotel Sold Out">
                                      <img src={soldOutImg} alt="" style={{ height: '3em', width: '3em' }} />
                                    </Tooltip>
                                  }


                                </div>

                                {format(date, "yyyy-MM-dd") in data &&
                                  "flags" in data[format(date, "yyyy-MM-dd")] && !isSoldOut(data[format(date, "yyyy-MM-dd")]) &&
                                  Array.isArray(
                                    data[format(date, "yyyy-MM-dd")]["flags"][ota]
                                  ) &&
                                  data[format(date, "yyyy-MM-dd")]["flags"][ota]
                                    .length > 0 &&
                                  data[format(date, "yyyy-MM-dd")]["flags"][
                                    ota
                                  ].map((item, index) => {
                                    return (
                                      <FlagIcon
                                        key={index}
                                        flag={item}
                                        filter={filterNames}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="mt-2">
                            <div style={{ ...fontSizeCal }}>
                              <>
                                <p className="mb-0" title="Overall Occupancy">
                                  O:{" "}
                                  <span className="bold">
                                    {
                                      typeof data[format(date, "yyyy-MM-dd")] !== "undefined"
                                        &&
                                        data[format(date, "yyyy-MM-dd")]?.['self']?.[selfDataSource]
                                        ? getOverAllOccupancy(data[format(date, "yyyy-MM-dd")]['self'][selfDataSource], "total_room_count" in data[format(date, "yyyy-MM-dd")] ? data[format(date, "yyyy-MM-dd")]['total_room_count'] : null, "available_room_count" in data[format(date, "yyyy-MM-dd")] ? data[format(date, "yyyy-MM-dd")]['available_room_count'] : null, format(date, "yyyy-MM-dd")) : "-"
                                    }
                                  </span>
                                </p>
                                <p className="mb-0" title="Tariff">
                                  T:{" "}
                                  <span className="bold" title={typeof data[format(date, "yyyy-MM-dd")] !== "undefined" && data[format(date, "yyyy-MM-dd")]?.['room_type'] && data[format(date, "yyyy-MM-dd")]['room_type']}>
                                    ₹{" "}
                                    {
                                      typeof data[format(date, "yyyy-MM-dd")] !== "undefined"
                                        &&
                                        !(data[format(date, "yyyy-MM-dd")]?.["is_sold_out"]?.[selfDataSource]) && data[format(date, "yyyy-MM-dd")]?.["price_info"]?.[selfDataSource]?.length > 1 && 1 in data[format(date, "yyyy-MM-dd")]["price_info"][selfDataSource] ? Math.round(data[format(date, "yyyy-MM-dd")]["price_info"][selfDataSource][1]).toLocaleString("en-IN")
                                        : "-"
                                    }
                                  </span>
                                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                    {typeof data[format(date, "yyyy-MM-dd")] !== "undefined" &&
                                      data[format(date, "yyyy-MM-dd")]?.["self"]?.[selfDataSource] &&
                                      getLowestPriceForRoomType(data[format(date, "yyyy-MM-dd")]["self"][selfDataSource], data[format(date, "yyyy-MM-dd")]['room_type']).map((priceData) => (
                                        <span title={priceData.room_type}> | {priceData.lowest_price !== null ? Math.round(priceData.lowest_price).toLocaleString("en-IN") : "-"}</span>
                                      ))}
                                  </span>
                                </p>
                                <p className="mb-0 overflow-hidden text-ellipsis whitespace-nowrap" title="Room Type Availability">
                                  A:{" "}
                                  <span className="w-100">
                                    {
                                      typeof data[format(date, "yyyy-MM-dd")] !== "undefined"
                                        &&
                                        data[format(date, "yyyy-MM-dd")]?.["self"]?.[selfDataSource]
                                        ?
                                        processRoomEntries(data, date, selfDataSource) : "-"
                                    }

                                  </span>
                                </p>
                              </>
                            </div>
                            <hr className="my-1" />
                            <div className="row">
                              <div className="col-10">
                                <small className="mb-0 text-muted">
                                  REC. TARIFF
                                </small>
                                <p className="mb-0 bold">
                                  RT:
                                </p>
                              </div>
                            </div>
                            <hr className="my-1" />
                            <div className="row">
                              <div className="col-10">
                                <small className="mb-0 text-muted">
                                  COMPETITION TARIFF
                                </small>
                                <p className="mb-0">
                                  {/* LCPF:{" "} */}

                                  {format(date, "yyyy-MM-dd") in data &&
                                    "comp_price_summary" in data[format(date, "yyyy-MM-dd")] &&
                                    ota in
                                    data[format(date, "yyyy-MM-dd")]["comp_price_summary"] ? (
                                    <span className="bold">
                                      ₹{" "}
                                      {(() => {
                                        const formattedDate = format(date, "yyyy-MM-dd");
                                        const priceFromData = data?.[formattedDate]?.["comp_price_summary"]?.[ota]
                                          ? data[formattedDate]['comp_price_summary'][ota]
                                          : [];

                                        //! Do not remove the below comments
                                        // Check if min price from data is an empty string or not available
                                        // if (
                                        //   minPriceFromData === "" &&
                                        //   format(date, "yyyy-MM-dd") in
                                        //   equRoomData &&
                                        //   "comp_price" in
                                        //   equRoomData[format(date, "yyyy-MM-dd")]
                                        // ) {
                                        //   // Fetch minimum price from equRoomData
                                        //   const equRoomPrices = Object.values(
                                        //     equRoomData[format(date, "yyyy-MM-dd")][
                                        //     "comp_price"
                                        //     ][ota]
                                        //   )
                                        //     .filter((item) => item.base_fare != 0)
                                        //     .map((item) => item.base_fare);

                                        //   const minPriceFromEquRoom =
                                        //     equRoomPrices.length
                                        //       ? Math.min(...equRoomPrices)
                                        //       : "";

                                        //   // If min price from equRoomData is available, return it; otherwise, show '-'
                                        //   return minPriceFromEquRoom !== ""
                                        //     ? minPriceFromEquRoom.toLocaleString(
                                        //       "en-IN"
                                        //     )
                                        //     : "-";
                                        // }

                                        // const minCompPrice = (0 in priceFromData && priceFromData[0]) ? priceFromData[0] : "-"
                                        // const medianCompPrice = (1 in priceFromData && priceFromData[1]) ? priceFromData[1] : "-"
                                        // const maxCompPrice = (2 in priceFromData && priceFromData[2]) ? priceFromData[2] : "-"

                                        const minCompPrice = (0 in priceFromData && priceFromData[0])
                                          ? Math.round(Number(priceFromData[0])).toLocaleString("en-IN")
                                          : "-"
                                        const medianCompPrice = (1 in priceFromData && priceFromData[1])
                                          ? Math.round(Number(priceFromData[1])).toLocaleString("en-IN")
                                          : "-"
                                        const maxCompPrice = (2 in priceFromData && priceFromData[2])
                                          ? Math.round(Number(priceFromData[2])).toLocaleString("en-IN")
                                          : "-"

                                        return (
                                          <span>
                                            <span title="Minimum Competitor Tariff">
                                              {minCompPrice}
                                            </span>{" "}
                                            |{" "}
                                            <span title="Median Competitor Tariff">
                                              {medianCompPrice}
                                            </span>{" "}
                                            |{" "}
                                            <span title="Maximum Competitor Tariff">
                                              {maxCompPrice}
                                            </span>
                                          </span>
                                        )


                                      })()}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                              <div className="col-4 d-flex justify-content-center align-items-center">
                                {trainData &&
                                  format(date, "yyyy-MM-dd") in trainData &&
                                  "train" in trainData[format(date, "yyyy-MM-dd")] &&
                                  trainData[format(date, "yyyy-MM-dd")]["train"]
                                    .length &&
                                  trainData[format(date, "yyyy-MM-dd")][
                                    "train"
                                  ].includes("DemandSpike") ? (
                                  <Tooltip
                                    title={`${trainData[format(date, "yyyy-MM-dd")][
                                      "avl_seats"
                                    ]
                                      } / ${trainData[format(date, "yyyy-MM-dd")][
                                      "total_seats"
                                      ]
                                      }`}
                                  >
                                    <TrainIcon
                                      style={{ color: "black", fontSize: "1.5em" }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {/* </Tooltip> */}
                        </Card>
                      </>) :
                        <div
                          style={{
                            height: cardHEIGHT,
                            minHeight: cardHEIGHT,
                            maxHeight: cardHEIGHT,
                            width: "100%",
                            margin: "7px 0"
                          }}
                        >
                        </div>

                      }

                    </div>
                  )
                })}
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={open}
          fullWidth={true}
          maxWidth="lg"
          onClose={() => {
            handleMasterModalClose()
          }}
          PaperProps={{
            style: {
              width: "95%",
              margin: 0,
              maxWidth: "100%",
              overflow: 'hidden'
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ position: "relative" }}>


            <DialogTitle style={{ paddingBottom: 0, height: "7em" }}>
              <div className="w-100 d-flex flex-column">
                {/* div for first row */}
                <div className="w-100 d-flex justify-content-between align-items-center">

                  <div className="d-flex flex-column col-6">
                    {/* <p className="fw-bold m-0 fs-8">Hotel Details</p> */}
                    <p className="m-0 fs-9">
                      <span className="fw-bold d-flex align-items-center">
                        {/* {selectedPropertyName} {modalData?.master_doc_missing === true && <AutoFixHighIcon sx={{ height: "20px", color: "orange" }} />} */}
                        {selectedPropertyName}
                      </span>
                    </p>
                    <p className="m-0 fs-8">
                      Check in - {convertDateFormat(modalDate)},{" "}
                      {getDayOfWeek(modalDate)}
                    </p>
                    <p className="m-0 fs-8">
                      Base Room: {modalData?.room_type}
                    </p>
                    <p className="m-0 fs-8">
                      Base Meal Plan: {modalData?.meal_plan_name} ({modalData?.meal_plan})
                    </p>
                  </div>
                  {/* 
                  {clubedRoomDetails && (
                    <div className="d-flex col-5 justify-content-end mr-3 w-30">
                      <div className="title-card font-weight-bold mb-2">
                        <div className="card-item-title">
                          A/T : {clubedRoomDetails.avl_inventory}/
                          {clubedRoomDetails.total_inventory}
                        </div>
                        <div className="card-item-title">
                          Curr Occ{" "}
                          {isNaN(clubedRoomDetails.curr_occ)
                            ? ": -"
                            : ` : ${clubedRoomDetails.curr_occ}%`}
                        </div>
                      </div>
                    </div>

                  )} */}

                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      handleMasterModalClose()
                    }}
                    aria-label="close"
                    style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }}
                  >
                    <CloseIcon />
                  </IconButton>



                </div>
                {/* div for first row */}
                <div className="w-100 d-flex justify-content-between align-items-start mt-2">
                  <div className="d-flex justify-content-start col-4">
                    {/* <Chip
                      variant="outlined"
                      label={"View Prev Date"}
                      onClick={() => {
                        handlePrevNextClick("prev", modalDate);
                      }}
                      size="small"
                      className="me-2"
                      disabled={(new Date(modalDate)) < new Date(startDate)}
                    />
                    <Chip
                      variant="outlined"
                      label={"View Next Date"}
                      onClick={() => {
                        handlePrevNextClick("next", modalDate);
                      }}
                      size="small"
                      disabled={(new Date(modalDate)) > new Date(endDate)}
                    /> */}
                  </div>
                  <div className="underprice d-flex justify-content-center col-4">

                    {open &&
                      filterNames.map((filter) => {
                        if (
                          modalData && modalData.flags &&
                          ota in modalData.flags &&
                          modalData.flags[ota]?.includes(filter.val)
                        ) {
                          return (
                            <Chip
                              key={filter.val}
                              label={filter.flagShort}
                              sx={{
                                background: filter.background,
                                color: "white",
                                "& .MuiChip-deleteIcon": {
                                  color: "white",
                                },
                                fontSize: "0.5em",
                              }}
                              className={"me-2"}
                              deleteIcon={
                                filter.icon === "inc" ? (
                                  <NorthIcon
                                    sx={{
                                      pointerEvents: "none",
                                      height: "0.7em",
                                      width: "0.7em",
                                    }}
                                  />
                                ) : (
                                  <SouthIcon
                                    sx={{
                                      pointerEvents: "none",
                                      height: "0.7em",
                                      width: "0.7em",
                                    }}
                                  />
                                )
                              }
                              onDelete={() => { }}
                            />
                          );
                        }
                        return null;
                      })}

                  </div>
                  <div className="d-flex justify-content-end col-3">
                    <PillShapedButton onClick={() => { setPriceHistoryOpen(true) }} startIcon={<InsightsIcon />} classes={""}>Tariff History</PillShapedButton>
                    {/* <PillShapedButton onClick={() => { setPriceHistoryOpen(true) }} startIcon={<InsightsIcon />} classes={"ml-2"}>Tariff History</PillShapedButton> */}
                  </div>
                </div>
              </div>
            </DialogTitle>
            <hr />
            <DialogContent ref={dialogContentRef} sx={{ marginBottom: "1em", paddingTop: "0", height: '60vh', overflowY: 'scroll', overflowX: 'hidden' }}>
              <DialogContentText id="alert-dialog-description">
                <div>
                  <>
                    {modalData ? (
                      <>
                        <RoomDetails
                          tenantId={tenantId}
                          propertyId={selectedProperty}
                          ota={ota}
                          pms={pms}
                          date={modalDate}
                          modalData={modalData}
                          setClubedRoomDetails={setClubedRoomDetails}
                          selfDataSource={selfDataSource}
                        />
                      </>
                    ) : null}
                  </>

                  <Tabs
                    value={tab}
                    onChange={handleTabSwitch}
                    aria-label="feature tabs"
                    sx={{
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                      '& .MuiTab-root': {
                        color: '#6b7280',
                        borderBottom: '2px solid #FF9637',
                        '&:hover': {
                          backgroundColor: '#fff7ed',
                        },
                        '&.Mui-selected': {
                          color: '#ffffff',
                          backgroundColor: '#FF9637',
                          borderBottom: '2px solid #FF9637',
                        },
                        fontSize: '0.875rem',
                        minHeight: '48px',
                        transition: 'all 0.2s ease-in-out',
                      },
                    }}
                  >
                    <Tab
                      label="Focus Competition"
                      {...a11yProps(0)}
                      value="tab1"
                    />
                    <Tab
                      label="Rate Parity"
                      {...a11yProps(1)}
                      value="tab2"
                    />
                    <Tab
                      label="Bookings"
                      {...a11yProps(1)}
                      value="tab3"
                    />
                  </Tabs>


                  <div className="mt-3">
                    {
                      tab === "tab1" && modalData && modalDate ? (
                        <>
                          <FocusComp
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type}
                            mealPlan={modalData.meal_plan}
                            baseFare={modalData.base_fare}
                            ota={ota}
                            selfDataSource={selfDataSource}
                            date={modalDate}
                            masterData={modalData}
                            hotelDetails={hotelDetails}
                            mealPlanNames={mealPlanNames}
                            userRole={userRole}
                          />
                        </>
                      ) : null

                    }
                    {
                      tab === 'tab2' && modalDate && modalData ? (
                        <>
                          <RateParity
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type}
                            mealPlan={modalData.meal_plan}
                            ota={ota}
                            date={modalDate}
                            masterData={modalData}
                            hotelDetails={hotelDetails}
                            clientDataSources={clientDataSources}
                            userRole={userRole}
                          />
                        </>
                      ) : null
                    }
                    {
                      tab === "tab3" && modalDate && modalData ? (
                        <>
                          <Bookings
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            ota={ota}
                            date={modalDate}
                            masterData={modalData}
                            bookings={bookings}
                          />
                        </>
                      ) : null
                    }
                  </div>

                  {/* <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className="mb-3"
                    style={{ background: "#eaeaea" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        minHeight: "100% !important",
                        height: "3em !important",
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        <h6 className="bold">Focus Competition</h6>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      {modalData && modalDate ? (
                        <>
                          <FocusComp
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type}
                            mealPlan={modalData.meal_plan}
                            baseFare={modalData.base_fare}
                            ota={ota}
                            selfDataSource={selfDataSource}
                            date={modalDate}
                            masterData={modalData}
                            hotelDetails={hotelDetails}
                            mealPlanNames={mealPlanNames}
                          />
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    style={{ background: "#eaeaea" }}
                    className="mb-3"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      sx={{
                        minHeight: "100% !important",
                        height: "3em !important",
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        <h6 className="bold">Rate Parity</h6>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      {modalDate ? (
                        <>
                          <RateParity
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type}
                            mealPlan={modalData.meal_plan}
                            ota={ota}
                            date={modalDate}
                            masterData={modalData}
                            hotelDetails={hotelDetails}
                          />
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion> */}

                  {/* <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    style={{ background: "#eaeaea" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      sx={{
                        minHeight: "100% !important",
                        height: "3em !important",
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        <h6 className="bold">Price History</h6>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      {modalDate ? (
                        <>
                          <PriceHistory
                            tenantId={tenantId}
                            propertyId={selectedProperty}
                            roomType={modalData.room_type_id}
                            mealPlan={modalData.meal_plan}
                            ota={ota}
                            date={modalDate}
                          />
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion> */}
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </Box>
      <PriceHistoryDialogue
        open={priceHistoryOpen}
        setOpen={setPriceHistoryOpen}
        tenantId={tenantId}
        propertyId={selectedProperty}
        roomType={modalData?.room_type}
        mealPlan={modalData?.meal_plan}
        ota={ota}
        selfDataSource={selfDataSource}
        date={modalDate}
      />
    </>
  );
};

export default Calendar;
