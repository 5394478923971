import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const FlagsSummary = ({
  rows,
  flagCols,
  handleFlagClick,
  selectedFlagIndex,
  selectedFlagNameMain,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Range</StyledTableCell>
          {flagCols.map((col, index) => {
            const label = col.label;
            const labelPrefix = col.summaryLabel;

            const dateRange = col.label.match(/\((.*?)\)/)?.[1] || '';

            return (
              <StyledTableCell align="right" title={label} >
                {labelPrefix}
              </StyledTableCell>
            );
          })}


        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow key={row.name}>
            <StyledTableCell component="th" scope="row">
              {row.name}
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(1, row.name, row.d1);
                }}
                className={`${row.d1 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 1 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d1}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(2, row.name, row.d2);
                }}
                className={`${row.d2 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 2 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d2}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(3, row.name, row.d3);
                }}
                className={`${row.d3 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 3 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d3}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(4, row.name, row.d4);
                }}
                className={`${row.d4 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 4 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d4}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(5, row.name, row.d5);
                }}
                className={`${row.d5 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 5 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d5}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(6, row.name, row.d6);
                }}
                className={`${row.d6 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 6 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d6}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(7, row.name, row.d7);
                }}
                className={`${row.d7 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 7 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d7}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(8, row.name, row.d8);
                }}
                className={`${row.d8 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 8 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d8}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(9, row.name, row.d9);
                }}
                className={`${row.d9 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 9 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d9}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(10, row.name, row.d10);
                }}
                className={`${row.d10 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 10 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d10}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(11, row.name, row.d11);
                }}
                className={`${row.d11 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 11 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d11}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(12, row.name, row.d12);
                }}
                className={`${row.d12 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 12 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d12}
              </span>
            </StyledTableCell>
            <StyledTableCell align="center">
              <span
                onClick={() => {
                  handleFlagClick(13, row.name, row.d13);
                }}
                className={`${row.d13 ? "bold text-danger hover-underline" : ""
                  } ${selectedFlagIndex === 13 &&
                  selectedFlagNameMain === row.name &&
                  "underline"
                  }`}
              >
                {row.d13}
              </span>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FlagsSummary;
