import { useEffect } from 'react'
import { BrowserRouter, Routes, Route , useNavigate} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import {AppProvider} from '../../context/AppContext';
import isAuthenticated, {getCookie} from '../../services/auth.js'
import { isValidRoute } from '../../Auth';

import Dashboard from './Dashboard';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const UsageTracker = ({ match, userCount, connectedUsers }) => {
  const dashboardId = useUserStore((state) => state.dashboardId);
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
  useEffect(() => {
    if (!dashboardId) {
      fetchDashboardId(); 
    }
  }, [dashboardId, fetchDashboardId]);

  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(2)
    if(!res['valid']){
      navigate(res['redirect'])
    }
  }
  
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
    <DashboardSessionTracker dashboardId={32}> </DashboardSessionTracker>
      <BaseLayout>
        <Routes>
            <Route path="dashboard" element={<Dashboard connectedUsers={connectedUsers} userCount={userCount}/>} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default UsageTracker;