import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import anime from 'animejs';

import markerImg from '../../../assets/img/hotel_marker.png';
import selectedMarkerImg from '../../../assets/img/marker.png';
import '../../../assets/styles/base.css';
import Alert from '@mui/material/Alert';

mapboxgl.accessToken = 'pk.eyJ1IjoiYXJjaGl0cmUxMiIsImEiOiJja2lpbDRtZW8wNzhtMnFxdW05bG9zNWl0In0.dIvw__ebeoXjRZszI3qeyQ'

const FCMap = ({ radius, hotels, lat, long, hotelName, clientProperties, propertyIndex, selectedCity }) => {

  const [mapError, setMapError] = useState(false);
  const mapContainer = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ closeButton: false }));

  // Determine zoom level based on radius
  const calculateZoom = (radius) => {
    if (radius > 0 && radius <= 5) return 13;
    if (radius > 5 && radius <= 10) return 12.5;
    if (radius > 10 && radius <= 15) return 12;
    if (radius > 15 && radius <= 20) return 11.5;
    if (radius > 20 && radius <= 25) return 11;
    if (radius > 25 && radius <= 30) return 10.5;
    return 13; // Default zoom
  };

  const zoom = calculateZoom(parseInt(radius));

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c).toFixed(1);
  };

  useEffect(() => {

    if (!lat || !long || isNaN(lat) || isNaN(long)) {
      setMapError(true);
      return;
    }

    try {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [long, lat],
        zoom: zoom,
      });

      // Add main location marker
      new mapboxgl.Marker().setLngLat([long, lat]).addTo(map);

      // Create GeoJSON data for hotels
      const geoData = {
        type: 'FeatureCollection',
        features: hotels
          .filter((hotel) => !isNaN(hotel.latitude) && !isNaN(hotel.longitude))
          .map((hotel) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(hotel.longitude), parseFloat(hotel.latitude)],
            },
            properties: {
              id: hotel._id,
              title: hotel.hotel_name,
              latitude: hotel.latitude,
              longitude: hotel.longitude,
              star: hotel.star,
            },
          })),
      };

      // console.log('GeoData:', geoData);

      map.on('load', () => {
        map.loadImage(markerImg, (error, image) => {
          if (error) {
            console.error('Error loading marker image:', error);
            return;
          }
          map.addImage('custom-marker', image);

          map.addSource('points', {
            type: 'geojson',
            data: geoData,
            cluster: false,
          });

          map.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'points',
            layout: {
              'icon-image': 'custom-marker',
              'icon-size': 0.05,
            },
          });

          // Click event for markers
          map.on('click', 'points', (e) => {
            if (!e.features || e.features.length === 0) {
              // console.error('No features found at clicked location');
              return;
            }
            const coordinates = e.features[0].geometry.coordinates;

            // Fly to the clicked marker
            map.flyTo({
              center: coordinates,
              zoom: 15,
              essential: true,
            });
          });


          // Tooltip functionality
          map.on('mouseover', 'points', (e) => {
            map.getCanvas().style.cursor = 'pointer';
            const coordinates = e.features[0].geometry.coordinates.slice();
            const title = e.features[0].properties.title;
            const compLat = e.features[0].properties.latitude;
            const compLong = e.features[0].properties.longitude;
            const distance = calculateDistance(lat, long, compLat, compLong);
            const star = parseFloat(e.features[0].properties.star) || 0;

            // Create star rating display
            const fullStars = Math.floor(star);
            const hasHalfStar = star % 1 >= 0.5;
            let starsHTML = '';

            // Generate full stars
            for (let i = 0; i < fullStars; i++) {
              starsHTML += '<span style="color: #FFD700;">★</span>';
            }

            // Add half star if needed
            if (hasHalfStar) {
              starsHTML += '<span style="color: #FFD700;">★</span>';
            }

            // Add empty stars to complete 5 stars
            const emptyStars = 5 - Math.ceil(star);
            for (let i = 0; i < emptyStars; i++) {
              starsHTML += '<span style="color: #D3D3D3;">★</span>';
            }

            popupRef.current
              .setLngLat(coordinates)
              .setHTML(`
                <div style="font-family: Arial, sans-serif; padding: 5px;">
                  <p style="margin: 0; font-weight: bold; font-size: 14px;">${title}</p>
                  <div style="margin: 4px 0; font-size: 16px;">
                    ${starsHTML}
                    <span style="font-size: 12px; color: #666; margin-left: 4px;">${star.toFixed(1)}</span>
                  </div>
                  <p style="margin: 0; font-size: 12px; color: #666;">
                    ${distance} Kms from ${hotelName}
                  </p>
                </div>
              `)
              .addTo(map);
          });

          map.on('mouseleave', 'points', () => {
            map.getCanvas().style.cursor = '';
            popupRef.current.remove();
          });
        });
      });

      // Clean up the map on component unmount
      return () => map.remove();
    } catch (error) {
      console.error('Error initializing map:', error);
      setMapError(true);
    }

  }, [radius, lat, long, hotels]);

  return (
    <div className="mt-2">
      {mapError ? (
        <Alert severity="warning" className="mb-4">
          Unable to load the map. Please check your coordinates and try again.
        </Alert>
      ) : (
        <div
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '12px',
          }}
          ref={mapContainer}
          className="mapContainer"
        />
      )}
    </div>
  );
};

// this component changes the color of the pin based on the selected hotels
// const FCMap = ({ radius, hotels, lat, long, hotelName, clientProperties, propertyIndex, selectedCity }) => {
//   const compList = clientProperties[selectedCity.value].properties[propertyIndex]['comp'] || [];
//   const mapContainer = useRef(null);
//   const popupRef = useRef(new mapboxgl.Popup({ closeButton: false }));

//   const calculateZoom = (radius) => {
//     if (radius > 0 && radius <= 5) return 13;
//     if (radius > 5 && radius <= 10) return 12.5;
//     if (radius > 10 && radius <= 15) return 12;
//     if (radius > 15 && radius <= 20) return 11.5;
//     if (radius > 20 && radius <= 25) return 11;
//     if (radius > 25 && radius <= 30) return 10.5;
//     return 13;
//   };

//   const zoom = calculateZoom(parseInt(radius));

//   const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 6371;
//     const dLat = ((lat2 - lat1) * Math.PI) / 180;
//     const dLon = ((lon2 - lon1) * Math.PI) / 180;
//     const a =
//       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//       Math.cos((lat1 * Math.PI) / 180) *
//       Math.cos((lat2 * Math.PI) / 180) *
//       Math.sin(dLon / 2) *
//       Math.sin(dLon / 2);
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     return (R * c).toFixed(1);
//   };

//   useEffect(() => {
//     const map = new mapboxgl.Map({
//       container: mapContainer.current,
//       style: 'mapbox://styles/mapbox/streets-v11',
//       center: [long, lat],
//       zoom: zoom,
//     });

//     // Add main location marker
//     new mapboxgl.Marker().setLngLat([long, lat]).addTo(map);

//     // Create GeoJSON data for hotels
//     const geoData = {
//       type: 'FeatureCollection',
//       features: hotels
//         .filter((hotel) => !isNaN(hotel.latitude) && !isNaN(hotel.longitude))
//         .map((hotel) => ({
//           type: 'Feature',
//           geometry: {
//             type: 'Point',
//             coordinates: [parseFloat(hotel.longitude), parseFloat(hotel.latitude)],
//           },
//           properties: {
//             id: hotel._id,
//             title: hotel.hotel_name,
//             latitude: hotel.latitude,
//             longitude: hotel.longitude,
//             star: hotel.star,
//             isInComp: compList.some(comp => comp.hotel_name === hotel.hotel_name)
//           },
//         })),
//     };

//     map.on('load', () => {
//       // Load both marker images
//       Promise.all([
//         new Promise((resolve, reject) => {
//           map.loadImage(markerImg, (error, image) => {
//             if (error) reject(error);
//             map.addImage('regular-marker', image);
//             resolve();
//           });
//         }),
//         new Promise((resolve, reject) => {
//           map.loadImage(selectedMarkerImg, (error, image) => {
//             if (error) reject(error);
//             map.addImage('selected-marker', image);
//             resolve();
//           });
//         })
//       ]).then(() => {
//         map.addSource('points', {
//           type: 'geojson',
//           data: geoData,
//           cluster: false,
//         });

//         map.addLayer({
//           id: 'points',
//           type: 'symbol',
//           source: 'points',
//           layout: {
//             'icon-image': [
//               'case',
//               ['get', 'isInComp'],
//               'selected-marker',
//               'regular-marker'
//             ],
//             'icon-size': 0.05,
//           },
//         });

//         // Click event for markers
//         map.on('click', 'points', (e) => {
//           if (!e.features || e.features.length === 0) return;
//           const coordinates = e.features[0].geometry.coordinates;

//           map.flyTo({
//             center: coordinates,
//             zoom: 15,
//             essential: true,
//           });
//         });

//         // Tooltip functionality
//         map.on('mouseover', 'points', (e) => {
//           map.getCanvas().style.cursor = 'pointer';
//           const coordinates = e.features[0].geometry.coordinates.slice();
//           const title = e.features[0].properties.title;
//           const compLat = e.features[0].properties.latitude;
//           const compLong = e.features[0].properties.longitude;
//           const distance = calculateDistance(lat, long, compLat, compLong);
//           const star = parseFloat(e.features[0].properties.star) || 0;
//           const isInComp = e.features[0].properties.isInComp;

//           const fullStars = Math.floor(star);
//           const hasHalfStar = star % 1 >= 0.5;
//           let starsHTML = '';

//           for (let i = 0; i < fullStars; i++) {
//             starsHTML += '<span style="color: #FFD700;">★</span>';
//           }

//           if (hasHalfStar) {
//             starsHTML += '<span style="color: #FFD700;">★</span>';
//           }

//           const emptyStars = 5 - Math.ceil(star);
//           for (let i = 0; i < emptyStars; i++) {
//             starsHTML += '<span style="color: #D3D3D3;">★</span>';
//           }

//           popupRef.current
//             .setLngLat(coordinates)
//             .setHTML(`
//               <div style="font-family: Arial, sans-serif; padding: 5px;">
//                 <p style="margin: 0; font-weight: bold; font-size: 14px;">
//                   ${title} ${isInComp ? '(Comp Hotel)' : ''}
//                 </p>
//                 <div style="margin: 4px 0; font-size: 16px;">
//                   ${starsHTML}
//                   <span style="font-size: 12px; color: #666; margin-left: 4px;">${star.toFixed(1)}</span>
//                 </div>
//                 <p style="margin: 0; font-size: 12px; color: #666;">
//                   ${distance} Kms from ${hotelName}
//                 </p>
//               </div>
//             `)
//             .addTo(map);
//         });

//         map.on('mouseleave', 'points', () => {
//           map.getCanvas().style.cursor = '';
//           popupRef.current.remove();
//         });
//       }).catch(error => {
//         console.error('Error loading marker images:', error);
//       });
//     });

//     return () => map.remove();
//   }, [radius, lat, long, hotels, compList]);

//   return (
//     <div
//       style={{
//         width: '100%',
//         height: '400px',
//         borderRadius: '12px',
//       }}
//       ref={mapContainer}
//       className="mt-2 mapContainer"
//     />
//   );
// };

export default FCMap;