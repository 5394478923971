import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PriceHistoryChart = ({ hotels, selectedComps }) => {
    const chartOptions = useMemo(() => {
        try {
            const seriesData = selectedComps.map(comp => {
                const hotel = hotels.find(h => h.hotel_id === comp.value);

                if (!hotel || !hotel['price_history']) return null;

                // Initialize weekday prices
                const weekdayPrices = {
                    0: [], // Sunday
                    1: [], // Monday
                    2: [], // Tuesday
                    3: [], // Wednesday
                    4: [], // Thursday
                    5: [], // Friday
                    6: []  // Saturday
                };

                if (Array.isArray(hotel['price_history'])) {
                    // Handle array format
                    hotel['price_history']
                        .filter(record => Array.isArray(record) && record.length >= 3 && record[0] && record[2])
                        .forEach(record => {
                            const date = new Date(record[0]);
                            const dayOfWeek = date.getDay();
                            const price = Number(record[2]);
                            if (!isNaN(price)) {
                                weekdayPrices[dayOfWeek].push(price);
                            }
                        });
                } else if (typeof hotel['price_history'] === 'object') {
                    // Handle object format
                    Object.entries(hotel['price_history']).forEach(([dateKey, values]) => {
                        if (Array.isArray(values) && values.length >= 3) {
                            const date = new Date(dateKey.split('|')[0]);
                            const dayOfWeek = date.getDay();
                            const price = Number(values[1]);
                            if (!isNaN(price)) {
                                weekdayPrices[dayOfWeek].push(price);
                            }
                        }
                    });
                }

                const data = Object.entries(weekdayPrices).map(([dayNum, prices]) => {
                    const avg = prices.length > 0
                        ? prices.reduce((sum, price) => sum + price, 0) / prices.length
                        : null;
                    return {
                        x: parseInt(dayNum),
                        y: avg ? Math.round(avg) : null
                    };
                }).filter(point => point.y !== null);

                return {
                    name: `${hotel.hotel_name} (${hotel.price_history_by})`,
                    data: data
                };
            }).filter(Boolean);

            const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            return {
                chart: {
                    type: 'line'
                },
                title: {
                    text: 'Average Hotel Prices by Day of Week'
                },
                xAxis: {
                    categories: weekdays,
                    title: {
                        text: 'Day of Week'
                    },
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: 'Average Price (INR)'
                    },
                    labels: {
                        formatter: function () {
                            return this.value.toLocaleString();
                        }
                    }
                },
                tooltip: {
                    shared: false,
                    crosshairs: false,
                    headerFormat: '<b>{point.key}</b><br/>',
                    pointFormat: '<span style="color: {series.color}">\u25CF</span> {series.name}: {point.y:,.0f} INR<br/>'
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        },
                        lineWidth: 2
                    }
                },
                series: seriesData,
                legend: {
                    enabled: true,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                },
                credits: {
                    enabled: false
                }
            };
        } catch (error) {
            console.error('Error creating chart options:', error);
            return {
                title: { text: 'Error loading chart data' },
                series: []
            };
        }
    }, [hotels, selectedComps]);

    if (!hotels?.length || !selectedComps?.length) {
        return <div>No data available for visualization</div>;
    }

    return (
        <div style={{ minHeight: '400px', marginTop: '20px' }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                containerProps={{ style: { height: '400px' } }}
            />
        </div>
    );
};

export default PriceHistoryChart;