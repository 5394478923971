import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
    persist(
        (set) => ({
            name: "",
            email: "",
            role: "",
            dashboardId: null,
            setName: (name) => set({ name }),
            setEmail: (email) => set({ email }),
            setRole: (role) => set({ role }),

            setUserData: (userData) => set(userData),

            clearUserData: () => set({ name: "", email: "", role: "" }),
            userSignOut: () => {
                set({ name: "", email: "", role: "" });
                localStorage.removeItem('user-storage');
            },

            // Dashboard Action 
            setDashboardId: (dashboardId) => set({ dashboardId }),
            fetchDashboardId: () => {
              const id = Math.floor(Math.random() * 100); 
              set({ dashboardId: id });
            },

        }),
        {
            name: "user-storage", // unique name for the storage
            getStorage: () => localStorage, // or sessionStorage
        }
    )
);

export default useUserStore;