import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import axios from "axios";
import { fetchToken } from "../../Auth";
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import { MultiSelect } from "react-multi-select-component";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ProgressIcon from "@mui/icons-material/PendingOutlined";
import Rating from "@mui/material/Rating";
import EditIcon from "@mui/icons-material/Edit";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationDialog from "./ConfirmationDialog"

const ClientRegionsV2 = ({
    properties,
    setProperties,
    international,
    preferredOTA,
    country,
    setCountry,
    pmsOptions,
    cmsOptions,
}) => {
    const navigate = useNavigate();

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "#fff",
            borderColor: "#9e9e9e",
            minHeight: "38px",
            height: "auto",
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            maxHeight: "30px", // Set the maximum height for the value container
            overflowY: "auto", // Enable vertical scrolling
            padding: "0 6px",
        }),
        input: (provided, state) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "30px",
        }),
    };

    const columns = [
        { field: "hotel_name", headerName: "Hotel Name", flex: 1 },
        { field: "catagory", headerName: "Hotel Type", flex: 1 },
        {
            field: "star",
            headerName: "Rating",
            flex: 1,
            renderCell: (params) => <Rating value={params.value} readOnly />,
        },
        { field: "pms", headerName: "PMS", flex: 1, valueGetter: (params) => params.row.pmsDetails?.pms || "N/A", },
        { field: "cms", headerName: "CMS", flex: 1, valueGetter: (params) => params.row.cmsDetails?.cms || "N/A", },
        {
            field: "addData",
            type: "actions",
            headerName: "Add Integration Data",
            flex: 1,
            getActions: (params) => [
                <Button
                    startIcon={<EditIcon />}
                    variant="contained"
                    className="bg-orange"
                    sx={{
                        borderRadius: "30px",
                        padding: "4px 15px",
                        fontSize: "0.875rem",
                        minHeight: "32px",
                        maxWidth: "160px"    // Added max-width constraint
                    }}
                    onClick={() => {
                        openIntegrationDetails(params.row)
                    }}
                >
                    {params.row.pmsDetails ? 'Edit' : 'Add'} Details  {/* Shortened text */}
                </Button>
            ],
        },
        {
            field: "progress",
            type: "actions",
            headerName: "Progress",
            flex: 1,
            getActions: (params) => [
                <div
                    className="text-white"
                    style={{
                        backgroundColor: "black",
                        borderRadius: "15px",
                        padding: "2px 8px",
                        display: "inline-block",
                        fontSize: "1rem",
                        fontWeight: "500"
                    }}
                >
                    {params.row.status}
                </div>
            ],
        },
        {
            field: "delete",
            type: "actions",
            headerName: "Delete",
            flex: 1,
            getActions: (params) => [
                <IconButton
                    key="delete"
                    color="error"
                    onClick={() => {
                        deleteHotel(params.row);
                    }}
                >
                    <DeleteIcon />
                </IconButton>,
            ],
        },
    ];
    const [rows, setRows] = useState([]);

    const [clientCities, setClientCities] = useState(
        properties && Object.keys(properties).length
            ? Object.keys(properties).map((item) => ({ label: item, value: item }))
            : []
    );
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [hotels, setHotels] = useState([]);
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [integrationDetailsOpen, setIntegrationDetailsOpen] = useState(false);
    const [pmsUsed, setPMSUsed] = useState(null)
    const [pmsHotelID, setPmsHotelID] = useState(null)
    const [pmsIntegrationKey, setPmsIntegrationKey] = useState(null)

    const [cmsUsed, setCMSUsed] = useState(null)
    const [cmsHotelID, setCmsHotelID] = useState(null)
    const [cmsIntegrationKey, setCmsIntegrationKey] = useState(null)

    const [errorMessage, setErrorMessage] = useState("");

    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        title: '',
        message: '',
        onConfirm: null
    });



    const showToast = (message, type = "error") => {
        toast(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE[type.toUpperCase()] || toast.TYPE.ERROR,
        });
    };


    const handleConfirmation = (title, message) => {
        return new Promise((resolve) => {
            setConfirmDialog({
                open: true,
                title,
                message,
                onConfirm: () => {
                    setConfirmDialog(prev => ({ ...prev, open: false }));
                    resolve(true);
                }
            });
        });
    };

    // Handle dialog closes
    const handleConfirmDialogClose = () => {
        setConfirmDialog(prev => ({ ...prev, open: false }));
    };

    const getHotels = async () => {
        try {
            const response = await axios.get(
                // `/get_scrape_hotels/${selectedCity.value}/${preferredOTA.value}`,
                `/get_scrape_hotels_v2/${selectedCity.value}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                    },
                }
            );
            if (response.status === 200 && response.data !== undefined) {
                setHotels(response.data);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate("/login");
            }
        }
    };

    const addHotels = () => {
        try {
            if (!selectedCountry) {
                showToast("Please select a country!");
                return;
            }
            if (!selectedCity) {
                showToast("Please select a city!");
                return;
            }
            if (!selectedHotels.length) {
                showToast("Please select at least one hotel!");
                return;
            }

            setProperties((prevData) => {
                const temp = { ...prevData };
                const cityLabel = selectedCity.label;

                // If city exists, merge new hotels with existing ones
                if (temp[cityLabel]) {
                    const existingHotelIds = new Set(
                        temp[cityLabel].properties.map(hotel => hotel._id)
                    );

                    // Filter out hotels that already exist
                    const newHotels = selectedHotels
                        .filter(hotel => !existingHotelIds.has(hotel._id))
                        .map(hotel => ({ ...hotel, status: "Not Started" }));

                    // Append new hotels to existing ones
                    temp[cityLabel].properties = [
                        ...temp[cityLabel].properties,
                        ...newHotels
                    ];
                } else {
                    // Create new city entry if it doesn't exist
                    let state = State.getStateByCodeAndCountry(
                        selectedCity.state_code,
                        selectedCountry.country_code
                    );

                    temp[cityLabel] = {
                        state: state?.name || "",
                        country: selectedCountry.label,
                        active: true,
                        properties: selectedHotels.map(hotel => ({
                            ...hotel,
                            status: "Not Started"
                        }))
                    };
                }

                return temp;
            });

            // Reset selection states
            setSelectedHotels([]);
            setSelectedCity(null);
            setSelectedCountry(null);
        } catch (error) {
            console.error(error);
            showToast("Error adding hotels!");
        }
    };

    const deleteHotel = async (rowData) => {
        // const shouldDelete = await showConfirmation("Confirm if you want to delete property details")
        const shouldDelete = await handleConfirmation(
            "Delete Property",
            "Are you sure you want to delete this property?"
        );
        if (shouldDelete === true) {
            try {
                setProperties((prevData) => {
                    const updatedData = { ...prevData };
                    const cityName = rowData.city;

                    if (updatedData[cityName]) {
                        updatedData[cityName].properties = updatedData[
                            cityName
                        ].properties.filter((property) => property._id !== rowData._id);
                        if (updatedData[cityName].properties.length === 0) {
                            delete updatedData[cityName];
                        }
                    }
                    return updatedData;
                });

                // showToast('Hotel deleted successfully', 'success');
            } catch (error) {
                console.error("Error deleting entry!", error);
                // showToast('Failed to delete hotel');
            }
        } else {
            return
        }

    };

    const deleteCityLevelHotels = async (evt, cityName) => {
        evt.stopPropagation()
        // const shouldDelete = await showConfirmation("Confirm if you want to delete city details")
        const shouldDelete = await handleConfirmation("Delete Property", "Confirm if you want to delete city details")


        if (shouldDelete === true) {

            try {
                evt.stopPropagation()
                setProperties((prevData) => {
                    const updatedData = { ...prevData };
                    delete updatedData[cityName];
                    return updatedData;
                });

                // showToast('Hotel deleted successfully', 'success');
            } catch (error) {
                console.error("Error deleting entry!", error);
                // showToast('Failed to delete hotel');
            }
        } else {
            return
        }
    }

    const openIntegrationDetails = (rowData) => {
        setSelectedProperty(rowData._id);
        setSelectedPropertyName(rowData.label)
        if (rowData.pmsDetails) {
            setPMSUsed(pmsOptions.find(opt => opt.value === rowData.pmsDetails.pms));
            setPmsHotelID(rowData.pmsDetails.hotelId);
            setPmsIntegrationKey(rowData.pmsDetails.integrationKey);
        }

        if (rowData.cmsDetails) {
            setCMSUsed(cmsOptions.find(opt => opt.value === rowData.cmsDetails.cms));
            setCmsHotelID(rowData.cmsDetails.hotelId);
            setCmsIntegrationKey(rowData.cmsDetails.integrationKey);
        }

        setIntegrationDetailsOpen(true);
    };



    const saveIntegrationDetails = () => {
        // Validation checks
        if (!pmsUsed) {
            setErrorMessage("Please select a PMS!");
            return;
        }

        if (!pmsHotelID) {
            setErrorMessage("Please provide a PMS Hotel ID!");
            return;
        }

        if (!pmsIntegrationKey) {
            setErrorMessage("Please provide a PMS Integration Key!");
            return;
        }

        if (!cmsUsed) {
            setErrorMessage("Please select a CMS!");
            return;
        }

        if (!cmsHotelID) {
            setErrorMessage("Please provide a CMS Hotel ID!");
            return;
        }

        if (!cmsIntegrationKey) {
            setErrorMessage("Please provide a CMS Integration Key!");
            return;
        }

        setProperties(prevProperties => {
            const newProperties = {};

            Object.keys(prevProperties).forEach(city => {
                const updatedProperties = prevProperties[city].properties.map(property => {
                    if (property._id === selectedProperty) {
                        return {
                            ...property,
                            pmsDetails: {
                                pms: pmsUsed.value,
                                hotelId: pmsHotelID,
                                integrationKey: pmsIntegrationKey
                            },
                            cmsDetails: {
                                cms: cmsUsed.value,
                                hotelId: cmsHotelID,
                                integrationKey: cmsIntegrationKey
                            }
                        };
                    }
                    return property;
                });

                newProperties[city] = {
                    ...prevProperties[city],
                    properties: updatedProperties
                };
            });

            return newProperties;
        });

        handleIntegrationModalClose()

        // showToast('Integration details saved successfully', 'success');
    };

    const handleIntegrationModalClose = () => {
        setPMSUsed(null);
        setPmsHotelID(null);
        setPmsIntegrationKey(null);
        setCMSUsed(null);
        setCmsHotelID(null);
        setCmsIntegrationKey(null);
        setSelectedProperty(null);
        setIntegrationDetailsOpen(false);
    }

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (
            selectedCountry &&
            selectedCountry.value &&
            typeof selectedCountry.value !== "undefined"
        ) {
            const cities = City.getCitiesOfCountry(selectedCountry.country_code);
            setCities(cities);
        } else {
            setCities([]);
        }

        setSelectedCity(null);
        setHotels([]);
        setSelectedHotels([]);
    }, [selectedCountry]);

    // useEffect(() => {
    //     if (
    //         selectedCity &&
    //         selectedCity.value &&
    //         typeof selectedCity.value !== "undefined"
    //     ) {
    //         setHotels([]);
    //         getHotels();
    //     }
    //     setSelectedHotels([]);
    // }, [selectedCity]);

    useEffect(() => {
        if (selectedCity && selectedCity.value) {
            setHotels([]);
            getHotels();

            // Pre-fill selected hotels if city exists in properties
            if (properties[selectedCity.label]) {
                const existingHotels = properties[selectedCity.label].properties.map(hotel => ({
                    label: hotel.hotel_name,
                    value: hotel._id,
                    ...hotel
                }));
                setSelectedHotels(existingHotels);
            } else {
                setSelectedHotels([]);
            }
        }
    }, [selectedCity]);

    useEffect(() => {
        let timeoutId;
        if (errorMessage) {
            timeoutId = setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [errorMessage]);

    return (
        <>
            <div className="mt-4 mb-4">
                <div className="mx-3 w-100 row mb-4 mt-2">
                    <span className="text-orange bold">Property Details</span>
                </div>
                <div className="mx-3 w-100 row mb-4 mt-2 d-flex">
                    <Select
                        placeholder="Select Country"
                        value={selectedCountry}
                        onChange={(opt) => {
                            setSelectedCountry(opt);
                        }}
                        options={countries.map((item) => ({
                            label: item.name,
                            value: item.name,
                            country_code: item.isoCode,
                        }))}
                        className="w-25"
                    />

                    <Select
                        placeholder="Select City"
                        value={selectedCity}
                        onChange={(opt) => {
                            setSelectedCity(opt);
                        }}
                        options={cities.map((item) => ({
                            label: item.name,
                            value: item.name,
                            state_code: item.stateCode,
                        }))}
                        className="w-25"
                    />

                    <MultiSelect
                        labelledBy="Select your preferred hotels"
                        placeholder="Select hotels"
                        value={selectedHotels}
                        onChange={(opt) => {
                            if (opt.length <= 20) {
                                setSelectedHotels(opt);
                            } else {
                                showToast(`You can only select up to ${20} hotels.`);
                            }
                        }}
                        hasSelectAll={false}
                        isMulti
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        options={hotels.map((item) => ({
                            label: item.hotel_name,
                            value: item.hotel_id,
                            ...item,
                        }))}
                        className="multi-select w-25"
                        overrideStrings={{
                            selectSomeItems: "Select Hotel's",
                        }}
                    />
                    <Button
                        className="w-10 bg-orange"
                        variant="contained"
                        onClick={addHotels}
                    >
                        Add Hotel
                    </Button>
                </div>

                <div className="mx-3 w-100 row mb-4 mt-2">
                    <span className="text-orange bold">Hotels Table</span>
                </div>
                <div className="mx-3 w-100 row mb-4 mt-2 flex justify-content-center">
                    {Object.keys(properties).length > 0 ? (
                        Object.entries(properties).map(([city, hotelData]) => (
                            <Accordion
                                key={city}
                                style={{ background: "black", borderRadius: "10px" }}
                                className="mb-3 p-0"
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className="text-white" />}
                                    aria-controls={`${city}-content`}
                                    id={`${city}-header`}
                                    className="flex items-center"
                                >
                                    <div className="col-8">
                                        <span className="font-bold text-white">{city}</span>
                                        <Chip
                                            label={hotelData.properties.length}
                                            size="small"
                                            className="mx-3 text-white bg-orange"
                                        />
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <DeleteIcon sx={{ color: "white" }} onClick={(evt) => { deleteCityLevelHotels(evt, city) }} />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ background: "white" }}>
                                    <DataGrid
                                        rows={hotelData.properties}
                                        columns={columns}
                                        pageSizeOptions={[5]}
                                        disableSelectionOnClick
                                        autoHeight
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        getRowId={(row) => row._id}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0
                                                ? "even-row"
                                                : "odd-row"
                                        }
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Alert severity="warning">No regions setup</Alert>
                    )}
                </div>
            </div>
            <Dialog
                open={integrationDetailsOpen}
                onClose={handleIntegrationModalClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">Add Integration Details</Typography>
                        <IconButton
                            onClick={handleIntegrationModalClose}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <p className="text-orange">Hotel Name: <span className="text-black">{selectedPropertyName}</span></p>
                    </div>
                    <div className="row">
                        <p className="text-orange bold">Add PMS Details</p>
                    </div>
                    <div className="row">
                        <div className="d-flex flex-column col-4">
                            <label>PMS Used<span style={{ color: 'red' }}>*</span></label>
                            <Select
                                value={pmsUsed}
                                onChange={(opt) => setPMSUsed(opt)}
                                className="w-100 mt-1"
                                options={pmsOptions}
                                placeholder="Select PMS"
                            />
                        </div>
                        <div className="d-flex flex-column col-4">
                            <label>Hotel ID<span style={{ color: 'red' }}>*</span></label>
                            <input
                                value={pmsHotelID}
                                onChange={(evt) => setPmsHotelID(evt.target.value)}
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel ID"
                                aria-label="hotel id"
                            />
                        </div>
                        <div className="d-flex flex-column col-4">
                            <label>Integration Key<span style={{ color: 'red' }}>*</span></label>
                            <input
                                value={pmsIntegrationKey}
                                onChange={(evt) => setPmsIntegrationKey(evt.target.value)}
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Integration Key"
                                aria-label="integration key"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <p className="text-orange bold">Add CMS Details</p>
                    </div>
                    <div className="row">
                        <div className="d-flex flex-column col-4">
                            <label>CMS Used<span style={{ color: 'red' }}>*</span></label>
                            <Select
                                value={cmsUsed}
                                onChange={(opt) => setCMSUsed(opt)}
                                className="w-100 mt-1"
                                options={cmsOptions}
                                placeholder="Select CMS"
                            />
                        </div>
                        <div className="d-flex flex-column col-4">
                            <label>Hotel ID<span style={{ color: 'red' }}>*</span></label>
                            <input
                                value={cmsHotelID}
                                onChange={(evt) => setCmsHotelID(evt.target.value)}
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel ID"
                                aria-label="hotel id"
                            />
                        </div>
                        <div className="d-flex flex-column col-4">
                            <label>Integration Key<span style={{ color: 'red' }}>*</span></label>
                            <input
                                value={cmsIntegrationKey}
                                onChange={(evt) => setCmsIntegrationKey(evt.target.value)}
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Integration Key"
                                aria-label="integration key"
                            />
                        </div>
                    </div>
                    {
                        errorMessage &&
                        <div className="mt-3">
                            <Alert severity="error">{errorMessage}</Alert>
                        </div>
                    }
                    <div className="row mt-3 d-flex justify-content-center">
                        <Button className="bg-orange text-white col-2" onClick={saveIntegrationDetails}>Save</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <ConfirmationDialog
                open={confirmDialog.open}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onConfirm={confirmDialog.onConfirm}
                onCancel={handleConfirmDialogClose}
            />
        </>
    );
};

export default ClientRegionsV2;
