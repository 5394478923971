import React, { useRef, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../../../services/helper"
import Alert from '@mui/material/Alert';
const Bookings = ({ tenantId, propertyId, ota, date, masterData, bookings }) => {

    const formatDateString = (dateStr) => {
        try {
            if (!dateStr || dateStr === "-") return "-";
            const [year, month, day] = dateStr.split("-");
            return `${day}-${month}-${year}`;
        } catch (error) {

        }
        return "-"
    };
    const columns = [
        {
            field: "booking_datetime",
            headerName: "Booking Datetime",
            flex: 1.5,
            renderCell: (params) => {
                const [date, time_tag] = formatDate(params.row.booking_datetime)?.split("@") || ["-", ""]
                return (

                    <div title={`${date}at${time_tag}`}>
                        <p className='mb-0'>{date}</p>
                        <small className='text-muted small'>at {time_tag}</small>
                    </div>
                );
            },
        },
        {
            field: "booking_id",
            headerName: "Booking ID",
            flex: 1
        },
        {
            field: "booking_source",
            headerName: "Booking Source",
            flex: 1
        },
        {
            field: "check_in",
            headerName: "Check In",
            flex: 1,
            renderCell: (params) => formatDateString(params.value)
        },
        {
            field: "check_out",
            headerName: "Check Out",
            flex: 1,
            renderCell: (params) => formatDateString(params.value)
        },
        {
            field: "length_of_stay",
            headerName: "Length Of Stay",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <p className="m-0">{parseInt(params.row.length_of_stay) > 1 ? `${params.row.length_of_stay} Days` : `${params.row.length_of_stay} Day`}</p>
                    </div>
                );
            },
            sortComparator: (v1, v2) => {
                if (v1 === "-" && v2 === "-") return 0;
                if (v1 === "-") return -1;
                if (v2 === "-") return 1;
                return parseInt(v1) - parseInt(v2);
            },
        },
        {
            field: "total_guests",
            headerName: "No. of Guests",
            flex: 1,
        },
        {
            field: "total_rooms",
            headerName: "No. of Rooms",
            flex: 1,
        },

        {
            field: "room_type",
            headerName: "Room Type",
            flex: 1
        },
        {
            field: "meal_plan",
            headerName: "Meal Plan",
            flex: 1
        },
        {
            field: "total_room_tariff",
            headerName: "Room Tariff",
            flex: 1,
            renderCell: (params) =>
                params.row.total_room_tariff === "-"
                    ? "-"
                    : `₹ ${parseInt(params.row.total_room_tariff).toLocaleString("en-IN")}`,
            sortComparator: (v1, v2) => {
                if (v1 === "-" && v2 === "-") return 0;
                if (v1 === "-") return -1;
                if (v2 === "-") return 1;
                return parseInt(v1) - parseInt(v2);
            },
        },
        {
            field: "total_tariff",
            headerName: "Total Tariff",
            flex: 1,
            renderCell: (params) =>
                params.row.total_tariff === "-"
                    ? "-"
                    : `₹ ${parseInt(params.row.total_tariff).toLocaleString("en-IN")}`,
            sortComparator: (v1, v2) => {
                if (v1 === "-" && v2 === "-") return 0;
                if (v1 === "-") return -1;
                if (v2 === "-") return 1;
                return parseInt(v1) - parseInt(v2);
            },
        },

        {
            field: "last_updtm",
            headerName: "Last Update Time",
            flex: 1.2,
            renderCell: (params) => {
                const [date, time_tag] = formatDate(params.row.last_updtm)?.split("@") || ["-", ""]
                return (

                    <div title={`${date}at${time_tag}`}>
                        <p className='mb-0'>{date}</p>
                        <small className='text-muted small'>at {time_tag}</small>
                    </div>
                );
            },
        },
    ]



    // return (
    //     <div className="w-100 d-flex flex-column">
    //         <h4 className="text-orange bold mb-3">
    //             Booking History
    //         </h4>

    //         {
    //             bookings?.active?.length > 0 ? <DataGrid
    //                 rows={"active" in bookings ? bookings.active : []}
    //                 columns={columns}
    //                 initialState={{
    //                     pagination: {
    //                         paginationModel: {
    //                             pageSize: 5,
    //                         },
    //                     },
    //                 }}
    //                 pageSizeOptions={[5]}
    //                 // checkboxSelection
    //                 disableColumnMenu
    //                 disableRowSelectionOnClick
    //                 getRowClassName={(params) =>
    //                     params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
    //                 }

    //             /> : <Alert severity="warning">No Active Bookings Found</Alert>
    //         }


    //         <h4 className="text-orange bold mt-3 mb-3">
    //             Booking Cancellation History
    //         </h4>
    //         {
    //             bookings?.cancelled?.length > 0 ? <DataGrid
    //                 rows={"cancelled" in bookings ? bookings.cancelled : []}
    //                 columns={columns}
    //                 initialState={{
    //                     pagination: {
    //                         paginationModel: {
    //                             pageSize: 5,
    //                         },
    //                     },
    //                 }}
    //                 pageSizeOptions={[5]}
    //                 // checkboxSelection
    //                 disableColumnMenu
    //                 disableRowSelectionOnClick
    //                 getRowClassName={(params) =>
    //                     params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
    //                 }

    //             /> : <Alert severity="warning">No Cancelled Bookings Found</Alert>
    //         }
    //     </div>
    // )
    return (
        <div className="w-100 d-flex flex-column">
            <div className={`rounded mb-4 ${bookings?.active?.length ? "border" : ""}`}>
                {/* <h4 className="text-orange bold mb-3">Booking History</h4> */}
                <div className="bg-gray-100 p-2 mb-2 rounded">
                    <div className="flex justify-between items-center">
                        <h5 className="m-0">Booking History</h5>
                        {/* <div className="text-sm">
                                    <span className="font-bold">Total Active Bookings: </span>
                                    {bookings.active.length}
                                </div> */}
                    </div>
                </div>
                {bookings?.active?.length > 0 ? (
                    <div>

                        <DataGrid
                            rows={bookings.active}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableColumnMenu
                            disableRowSelectionOnClick
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
                            }
                        />
                    </div>
                ) : (
                    <Alert severity="warning">No Active Bookings Found</Alert>
                )}
            </div>

            <div className={`rounded mb-4 ${bookings?.cancelled?.length ? "border" : ""}`}>
                {/* <h4 className="text-orange bold mb-3">Booking Cancellation History</h4> */}
                <div className="bg-gray-100 p-2 mb-2 rounded">
                    <div className="flex justify-between items-center">
                        <h5 className="m-0">Booking Cancellation History</h5>
                        {/* <div className="text-sm">
                                    <span className="font-bold">Total Cancelled Bookings: </span>
                                    {bookings.cancelled.length}
                                </div> */}
                    </div>
                </div>
                {bookings?.cancelled?.length > 0 ? (
                    <div>

                        <DataGrid
                            rows={bookings.cancelled}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableColumnMenu
                            disableRowSelectionOnClick
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
                            }
                        />
                    </div>
                ) : (
                    <Alert severity="warning">No Cancelled Bookings Found</Alert>
                )}
            </div>
        </div>
    );
}

export default Bookings