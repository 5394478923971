import React, { createContext, useContext, useRef } from "react";

// Create the context
const SessionTrackerContext = createContext();

// Create a custom hook to use the context
export const useSessionTracker = () => useContext(SessionTrackerContext);

// Provider component
export const SessionTrackerProvider = ({ children }) => {
  const trackerRefs = useRef([]);

  // Register a tracker
  const registerTracker = (tracker) => {
    if (tracker && !trackerRefs.current.includes(tracker)) {
      trackerRefs.current.push(tracker);
    }
  };

  // Unregister a tracker
  const unregisterTracker = (tracker) => {
    trackerRefs.current = trackerRefs.current.filter((t) => t !== tracker);
  };

  // Trigger all registered trackers to send usage data
  const sendAllUsageData = async () => {
    for (const tracker of trackerRefs.current) {
      if (tracker) {
        await tracker(); // Call each registered tracker's function
      }
    }
  };

  return (
    <SessionTrackerContext.Provider
      value={{ registerTracker, unregisterTracker, sendAllUsageData }}
    >
      {children}
    </SessionTrackerContext.Provider>
  );
};
