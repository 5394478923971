import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { fetchToken } from "../../../Auth";
import { toast } from "react-toastify";
import { deslugify } from "../../../services/helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { openUrlInIncognito, getRoomURL, formatDate } from "../../../services/helper"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from "@mui/material/Tooltip";

const RateParity = ({
  tenantId,
  propertyId,
  roomType,
  mealPlan,
  ota,
  date,
  masterData,
  hotelDetails,
  clientDataSources,
  userRole
}) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  // const fetchRateParity = async () => {
  //   try {
  //     const response = await axios.post(
  //       `/fetch_rate_parity`,
  //       {
  //         tenant_id: tenantId,
  //         property_id: propertyId,
  //         date: date,
  //         room_type: roomType,
  //         meal_plan: mealPlan,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       const formattedRows = response.data.map((row, index) => ({
  //         id: index + 1,
  //         ota: sites[row.ota],
  //         roomType: deslugify(row.roomType),
  //         mealPlan: row.mealPlan,
  //         fare: `₹ ${parseInt(row.fare).toLocaleString("en-IN")}`,
  //         discount: row.discount ? `₹ ${parseInt(row.discount).toLocaleString("en-IN")}` : '-',
  //         lastScraped: row.lastScraped,
  //         link: row.link,
  //       }));
  //       setRows(formattedRows);
  //     }
  //   } catch (err) {
  //     console.error("Error", err);
  //     if (err.response && err.response.status === 401) {
  //       navigate("/login");
  //     }
  //   }
  // };

  const generateParityRows = (showSelfRoomType = false, showCompetition = false) => {
    let rowData = []
    let pmsRowData = []
    let rowId = 1;

    if (masterData?.['price_info']) {
      Object.entries(masterData['price_info']).forEach(([key, value]) => {
        let isSoldOut = masterData?.['is_sold_out'] && key in masterData['is_sold_out'] ? masterData['is_sold_out'][key] : null;
        let dataSource = value
        let isEqu = false
        let masterReference = masterData
        if (isSoldOut === true && masterData?.['equivalent_room']?.[key]?.['price_info']) {
          dataSource = masterData['equivalent_room'][key]['price_info']
          masterReference = masterData['equivalent_room'][key]
          isEqu = true
          isSoldOut = false
        }

        let dataDict = {
          "id": rowId++,
          "ota": key,
          "propertyName": hotelDetails?.[propertyId]?.['property_name'] ? hotelDetails[propertyId]['property_name'] : "",
          "roomType": masterReference?.['room_type'] ? masterReference['room_type'] : "",
          "mealPlan": masterReference?.['meal_plan'] ? masterReference['meal_plan'] : "",
          "tariff": isSoldOut === true ? "-" : (dataSource && 1 in dataSource ? Math.round(dataSource[1]) : "-"),
          "totalTariff": isSoldOut === true ? "-" : (dataSource && dataSource.length ? Math.round(dataSource[1] + dataSource[2]) : "-"),
          "lastScraped": masterData?.['last_updtm']?.[key] ? masterData['last_updtm'][key] : "-",
          "link": getRoomURL(key, propertyId, date, hotelDetails),
          "isAnchor": true,
          "isSelf": true,
          "isSoldOut": isSoldOut,
          "isPMS": false,
          "is_equ": isEqu
        }
        if (clientDataSources?.[key]?.['is_pms']) {
          dataDict['isPMS'] = true
          pmsRowData.push(dataDict)
        } else {
          rowData.push(dataDict)
        }
      })
    }

    //! Do not use this code until the logic for equivalent room type of additional self is defined 
    //! do not remove the code
    // if (showSelfRoomType && masterData?.['self']) {
    //   Object.entries(masterData['self']).forEach(([key, value]) => {
    //     Object.entries(value).forEach(([rt_mp, roomData]) => {
    //       const isSoldOut = "is_sold_out" in roomData ? roomData['is_sold_out'] : null;
    //       let dataDict = {
    //         "id": rowId++,
    //         "ota": key,
    //         "propertyName": hotelDetails?.[propertyId]?.['property_name'] ? hotelDetails[propertyId]['property_name'] : "",
    //         "roomType": roomData?.['room_type'] ? roomData['room_type'] : "",
    //         "mealPlan": roomData?.['meal_plan'] ? roomData['meal_plan'] : "",
    //         "tariff": isSoldOut === true ? "-" : (roomData?.['price_info'] && 1 in roomData['price_info'] ? Math.round(roomData['price_info'][1]) : "-"),
    //         "totalTariff": isSoldOut === true ? "-" : (roomData?.["price_info"] && roomData["price_info"].length ? Math.round(parseFloat(Math.round(roomData['price_info'][1])) + parseFloat(Math.round(roomData['price_info'][2]))) : "-"),
    //         "lastScraped": roomData?.['last_updtm'] ? roomData['last_updtm'] : "-",
    //         "link": getRoomURL(key, propertyId, date, hotelDetails),
    //         "isAnchor": false,
    //         "isSelf": true,
    //         "isSoldOut": isSoldOut,
    //         "isPMS": false
    //       }
    //       if (clientDataSources?.[key]?.['is_pms']) {
    //         dataDict['isPMS'] = true
    //         pmsRowData.push(dataDict)
    //       } else {
    //         rowData.push(dataDict)
    //       }
    //     })
    //   })
    // }

    //! this is done but this needs to verified not necessary for now
    //! do not remove the code
    // if (showCompetition && masterData?.['comp']) {
    //   Object.entries(masterData['comp']).forEach(([key, value]) => {
    //     Object.entries(value).forEach(([comp_id, roomData]) => {
    //       let isSoldOut = "is_sold_out" in roomData ? roomData['is_sold_out'] : null;
    //       let dataSource = roomData
    //       let isEqu = false
    //       if (isSoldOut === true && roomData?.['equivalent_room'] && Object.keys(roomData['equivalent_room']).length) {
    //         dataSource = roomData['equivalent_room']
    //         isEqu = true
    //         isSoldOut = false
    //       }

    //       let dataDict = {
    //         "id": rowId++,
    //         "ota": key,
    //         "propertyName": hotelDetails?.[comp_id]?.['property_name'] ? hotelDetails[comp_id]['property_name'] : "",
    //         "roomType": dataSource?.['room_type'] ? dataSource['room_type'] : "",
    //         "mealPlan": dataSource?.['meal_plan'] ? dataSource['meal_plan'] : "",
    //         "tariff": isSoldOut === true ? "-" : (dataSource?.['price_info'] && 1 in dataSource['price_info'] ? Math.round(dataSource['price_info'][1]) : "-"),
    //         "totalTariff": isSoldOut === true ? "-" : (dataSource?.['price_info'] && dataSource['price_info'].length ? Math.round(parseFloat(Math.round(dataSource['price_info'][1])) + parseFloat(Math.round(dataSource['price_info'][2]))) : "-"),
    //         "lastScraped": dataSource?.['last_updtm'] ? dataSource['last_updtm'] : "-",
    //         "link": getRoomURL(key, comp_id, date, hotelDetails),
    //         "isAnchor": false,
    //         "isSelf": false,
    //         "isSoldOut": isSoldOut,
    //         "isPMS": false,
    //         "is_equ": isEqu
    //       }
    //       rowData.push(dataDict)
    //     })
    //   })
    // }

    setRows([...pmsRowData, ...rowData])
  }
  useEffect(() => {
    generateParityRows();
  }, [date, masterData]);

  const columns = [
    {
      sortable: false,
      headerName: "",
      width: 15,
      renderCell: (params) => {
        return (
          <>
            {params.row.is_equ ? (
              <Tooltip title="Lowest available room">
                <AutoFixHighIcon
                  style={{ color: "orange", fontSize: "1.5em" }}
                />
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: "ota",
      headerName: "OTA",
      flex: 1,
      renderCell: (params) => {
        const style = params.row.isPMS ? { fontWeight: 'bold' } : {};
        if (clientDataSources?.[params.row.ota]?.['link']) {
          return (
            <div className={`d-flex align-items-center ${params.row.isPMS ? "bold" : ""}`}>
              <img src={clientDataSources[params.row.ota]['link']} alt="" style={{ width: "20px", height: "20px", marginRight: "10px", borderRadius: '50%' }} />
              <p className="m-0">{clientDataSources?.[params.row.ota] ? clientDataSources[params.row.ota]['name'] : params.row.ota}</p>
            </div>
          );
        }
        return (
          <div className={`d-flex align-items-center ${params.row.isPMS ? "bold" : ""}`}>
            <p className="m-0">{clientDataSources?.[params.row.ota] ? clientDataSources[params.row.ota]['name'] : params.row.ota}</p>
          </div>
        )
      },
    },
    {
      field: "roomType",
      headerName: "Room Type",
      flex: 1,
      renderCell: (params) => (
        <div className={`${params.row.isPMS ? "bold" : ""}`}>
          {params.value}
        </div>
      )
    },
    {
      field: "mealPlan",
      headerName: "Meal Plan",
      flex: 1,
      renderCell: (params) => (
        <div className={`${params.row.isPMS ? "bold" : ""}`}>
          {params.value}
        </div>
      )
    },
    {
      field: "tariff",
      headerName: "Tariff",
      flex: 1,
      renderCell: (params) => {
        const displayValue = params.row.tariff === "-"
          ? "-"
          : `₹ ${parseInt(params.row.tariff).toLocaleString("en-IN")}`;
        return (
          <div className={`${params.row.isPMS ? "bold" : ""}`}>
            {displayValue}
          </div>
        );
      },
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
    },
    // {
    //   field: "discount", headerName: "Discount", flex: 1,

    //   renderCell: (params) =>
    //     params.row.discount === "-"
    //       ? "-"
    //       : `₹ ${parseInt(params.row.discount).toLocaleString("en-IN")}`,

    //   sortComparator: (v1, v2) => {
    //     if (v1 === "-" && v2 === "-") return 0;
    //     if (v1 === "-") return -1;
    //     if (v2 === "-") return 1;
    //     return parseInt(v1) - parseInt(v2);
    //   },
    // },
    {
      field: "totalTariff",
      headerName: "Total Tariff",
      flex: 1,
      renderCell: (params) => {
        const displayValue = params.row.totalTariff === "-"
          ? "-"
          : `₹ ${parseInt(params.row.totalTariff).toLocaleString("en-IN")}`;
        return (
          <div className={`${params.row.isPMS ? "bold" : ""}`}>
            {displayValue}
          </div>
        );
      },
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
    },
    {
      field: "isSoldOut",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const displayValue = params.row.isSoldOut === null
          ? "-"
          : params.row.isSoldOut ? "Sold Out" : "Available";
        return (
          <div className={`${params.row.isPMS ? "bold" : ""}`}>
            {displayValue}
          </div>
        );
      }
    },
    {
      field: "lastScraped",
      headerName: "Refresh Date",
      flex: 1,
      renderCell: (params) => {
        const [date, time_tag] = formatDate(params.row.lastScraped)?.split("@") || ["-", ""]
        return (
          <div
            title={`${date}at${time_tag}`}
            className={`${params.row.isPMS ? "bold" : ""}`}
          >
            <p className='mb-0'>{date}</p>
            <small className='text-muted small'>at {time_tag}</small>
          </div>
        );
      },
    },
    {
      field: "link",
      headerName: "View on OTA",
      type: "actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value ? (
          <OpenInNewIcon
            onClick={() => { openUrlInIncognito(params.value) }}
            sx={{
              color: "#0d6efd",
              cursor: "pointer",
              fontWeight: params.row.isPMS ? 'bold' : 'normal'
            }}
          />
        ) : (
          <OpenInNewIcon sx={{ color: "grey" }} />
        ),
    },
  ];
  return (
    <>
      <div className="rate-parity-table-container">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5]}
          disableSelectionOnClick
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      </div>
    </>
  );
};

export default RateParity;
